import React from 'react';

import { Authenticated } from './authenticated';
import { Anonymous } from './anonymous';
import { Error } from 'app/pages';
import { ProgressBar } from 'app/components';
import { useViewerData } from 'app/viewer';

export const Bootstrap: React.FC = () => {
  const [{ data, fetching, error }] = useViewerData();
  if (fetching) {
    return <ProgressBar show />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (data?.viewer) {
    return <Authenticated viewer={data.viewer} />;
  } else {
    return <Anonymous />;
  }
};
