import React from 'react';

export function createValueContext<Value>() {
  const Context = React.createContext<Value>(undefined!);
  const Provider: React.FC<{ value: Value }> = ({ children, value }) => {
    return <Context.Provider children={children} value={value} />;
  };
  const useContext = () => React.useContext(Context);
  return [Provider, useContext] as const;
}
