import React from 'react';
import { useMeasure } from 'react-use';

type Props = React.ComponentProps<'div'>;

export const ResizableContainer: React.FC<Props> = ({ children, ...other }) => {
  const [ref, box] = useMeasure<HTMLDivElement>();
  const { width, height } = box;
  return (
    <div {...other} ref={ref}>
      {width && height
        ? typeof children === 'function'
          ? children(box)
          : children
        : null}
    </div>
  );
};
