import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { App } from 'ux/app';
import { LoadingBar } from 'ux/content';
import { ProgressBar } from 'app/components';

import { ErrorFallback } from 'app/pages';
import { useAppReducer } from 'app';

type Props = Omit<React.ComponentProps<typeof App>, 'ref'>;

export const Application: React.FC<Props> = ({ children, ...other }) => {
  const [{ loading }] = useAppReducer();
  return (
    <App {...other}>
      {loading ? <LoadingBar /> : null}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.Suspense fallback={<ProgressBar show />}>
          {children}
        </React.Suspense>
      </ErrorBoundary>
    </App>
  );
};
