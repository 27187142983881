import React from 'react';
import { IonModal } from '@ionic/react';
import { Mask, LoadingBar } from 'ux/content';

type Props = {
  loading?: boolean;
  mask?: boolean;
} & React.ComponentProps<typeof IonModal>;

export const Modal: React.FC<Props> = ({
  children,
  loading,
  mask,
  ...other
}) => {
  return (
    <IonModal {...other}>
      {loading ? <LoadingBar /> : null}
      {mask ? <Mask /> : null}
      {children}
    </IonModal>
  );
};
