import { createContext, MouseEvent, useState } from 'react';

type InternalState = {
  isOpen: boolean;
  event?: Event;
  action?: Function;
};

type Actions = {
  open: (event: MouseEvent) => void;
  close: (action?: Function) => void;
  reset: () => void;
};

export type State = InternalState & Actions;

export const Popover = createContext<State>(undefined!);

export const usePopover = (): State => {
  const [state, set] = useState<InternalState>({ isOpen: false });

  const open = (event: MouseEvent) =>
    set({
      isOpen: true,
      event: event.nativeEvent,
      action: undefined,
    });

  const close = (action?: Function) =>
    set({
      isOpen: false,
      event: undefined,
      action,
    });

  const reset = () =>
    set({
      isOpen: false,
      event: undefined,
      action: undefined,
    });

  return { ...state, open, close, reset };
};
