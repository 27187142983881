import React from 'react';
import { IonButton } from '@ionic/react';

type Props = React.ComponentProps<typeof IonButton>;

export const SubmitButton: React.FC<Props> = (props) => {
  return (
    <IonButton color="primary" fill="solid" type="submit" {...props}>
      Naprej
    </IonButton>
  );
};
