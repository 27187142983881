import React, { useCallback } from 'react';
import { UseQueryResponse } from 'urql';
import { ViewerFragment, ViewerQuery, useViewerQuery } from 'types';

/**
 * Viewer query data context
 * Viewer can be null here
 *
 * @returns ViewerData
 */
type ViewerData = UseQueryResponse<ViewerQuery>;
const ViewerData = React.createContext<ViewerData>(undefined!);
export const useViewerData = () => React.useContext(ViewerData);
export const ViewerDataProvider: React.FC = ({ children }) => {
  const value = useViewerQuery();
  return <ViewerData.Provider value={value} children={children} />;
};

/**
 * Viewer context
 * Viewer is available
 *
 * @returns Viewer
 */
export type Viewer = ViewerFragment;
const Viewer = React.createContext<Viewer>(undefined!);
export const useViewer = () => React.useContext(Viewer);
export const ViewerProvider: React.FC<{ viewer: Viewer }> = ({
  viewer,
  children,
}) => {
  return <Viewer.Provider value={viewer} children={children} />;
};

/**
 * Logout viewer hook
 */
export const useLogout = () => {
  const [, refetch] = useViewerData();

  return useCallback(() => {
    localStorage.removeItem('token');
    refetch({ requestPolicy: 'network-only' });
  }, [refetch]);
};
