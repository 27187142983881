const getProcessEnv = (VAR: string) => {
  const value = process.env[`REACT_APP_${VAR}`] || '';
  if (!value) {
    console.error(`[config] Error: missing ${VAR} .env variable!`);
  }
  return value;
};

export const API_PUBLIC_URL = getProcessEnv('API_PUBLIC_URL');
export const API_PRIVATE_URL = getProcessEnv('API_PRIVATE_URL');
export const API_UPLOAD_URL = getProcessEnv('API_UPLOAD_URL');
export const API_LOGIN_URL = getProcessEnv('API_LOGIN_URL');
export const GITHUB_LOGIN_URL = getProcessEnv('GITHUB_LOGIN_URL');
