import React from 'react';

export const createReducerContext = <State, Action>(
  reducer: React.Reducer<State, Action>,
  defaultState: State
) => {
  type Value = [State, React.Dispatch<Action>];
  type Reducer = React.Reducer<State, Action>;

  const ReducerContext = React.createContext<Value>(undefined!);

  const useReducer = () => {
    const context = React.useContext(ReducerContext);
    if (context === undefined) {
      throw new Error(
        'useReducer must be used within a ReducerContext provider!'
      );
    }
    return context;
  };

  const useContext = (initialState?: State) => {
    return React.useReducer<Reducer>(reducer, initialState || defaultState);
  };

  const CustomProvider: React.FC<{ value: Value }> = ({ children, value }) => {
    return <ReducerContext.Provider value={value} children={children} />;
  };

  const DefaultProvider: React.FC<{ initialState?: State }> = ({
    children,
    initialState,
  }) => {
    const value = useContext(initialState);
    return <CustomProvider children={children} value={value} />;
  };

  const Provider: React.FC<{ value?: Value }> = ({ children, value }) => {
    return value ? (
      <CustomProvider children={children} value={value} />
    ) : (
      <DefaultProvider children={children} />
    );
  };

  return [Provider, useReducer, useContext] as const;
};
