import React from 'react';

const styles = {
  position: 'absolute',
  background: 'rgba(255,255,255,0.5)',
  width: '100%',
  height: '100%',
  zIndex: 11,
} as const;

export const Mask: React.FC = () => <div style={styles} />;
