import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
  DateTime: any;
  Hex: any;
  /** Raw JSON value */
  Json: any;
  /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  /** Slate-compatible RichText AST */
  RichTextAST: any;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple boulders */
  boulders: Array<Boulder>;
  /** Retrieve a single boulder */
  boulder?: Maybe<Boulder>;
  /** Retrieve multiple boulders using the Relay connection interface */
  bouldersConnection: BoulderConnection;
  /** Retrieve document version */
  boulderVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple boulderColors */
  boulderColors: Array<BoulderColor>;
  /** Retrieve a single boulderColor */
  boulderColor?: Maybe<BoulderColor>;
  /** Retrieve multiple boulderColors using the Relay connection interface */
  boulderColorsConnection: BoulderColorConnection;
  /** Retrieve document version */
  boulderColorVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple challenges */
  challenges: Array<Challenge>;
  /** Retrieve a single challenge */
  challenge?: Maybe<Challenge>;
  /** Retrieve multiple challenges using the Relay connection interface */
  challengesConnection: ChallengeConnection;
  /** Retrieve document version */
  challengeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple challengeGoals */
  challengeGoals: Array<ChallengeGoal>;
  /** Retrieve a single challengeGoal */
  challengeGoal?: Maybe<ChallengeGoal>;
  /** Retrieve multiple challengeGoals using the Relay connection interface */
  challengeGoalsConnection: ChallengeGoalConnection;
  /** Retrieve document version */
  challengeGoalVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple challengeProgresses */
  challengeProgresses: Array<ChallengeProgress>;
  /** Retrieve a single challengeProgress */
  challengeProgress?: Maybe<ChallengeProgress>;
  /** Retrieve multiple challengeProgresses using the Relay connection interface */
  challengeProgressesConnection: ChallengeProgressConnection;
  /** Retrieve document version */
  challengeProgressVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple climbers */
  climbers: Array<Climber>;
  /** Retrieve a single climber */
  climber?: Maybe<Climber>;
  /** Retrieve multiple climbers using the Relay connection interface */
  climbersConnection: ClimberConnection;
  /** Retrieve document version */
  climberVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple courses */
  courses: Array<Course>;
  /** Retrieve a single course */
  course?: Maybe<Course>;
  /** Retrieve multiple courses using the Relay connection interface */
  coursesConnection: CourseConnection;
  /** Retrieve document version */
  courseVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple days */
  days: Array<Day>;
  /** Retrieve a single day */
  day?: Maybe<Day>;
  /** Retrieve multiple days using the Relay connection interface */
  daysConnection: DayConnection;
  /** Retrieve document version */
  dayVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple folders */
  folders: Array<Folder>;
  /** Retrieve a single folder */
  folder?: Maybe<Folder>;
  /** Retrieve multiple folders using the Relay connection interface */
  foldersConnection: FolderConnection;
  /** Retrieve document version */
  folderVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple gyms */
  gyms: Array<Gym>;
  /** Retrieve a single gym */
  gym?: Maybe<Gym>;
  /** Retrieve multiple gyms using the Relay connection interface */
  gymsConnection: GymConnection;
  /** Retrieve document version */
  gymVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple seasons */
  seasons: Array<Season>;
  /** Retrieve a single season */
  season?: Maybe<Season>;
  /** Retrieve multiple seasons using the Relay connection interface */
  seasonsConnection: SeasonConnection;
  /** Retrieve document version */
  seasonVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple students */
  students: Array<Student>;
  /** Retrieve a single student */
  student?: Maybe<Student>;
  /** Retrieve multiple students using the Relay connection interface */
  studentsConnection: StudentConnection;
  /** Retrieve document version */
  studentVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple phrases */
  phrases: Array<Phrase>;
  /** Retrieve a single phrase */
  phrase?: Maybe<Phrase>;
  /** Retrieve multiple phraseComments */
  phraseComments: Array<PhraseComment>;
  /** Retrieve a single phraseComment */
  phraseComment?: Maybe<PhraseComment>;
  /** Retrieve multiple phraseImages */
  phraseImages: Array<PhraseImage>;
  /** Retrieve a single phraseImage */
  phraseImage?: Maybe<PhraseImage>;
  /** Retrieve multiple phraseLinks */
  phraseLinks: Array<PhraseLink>;
  /** Retrieve a single phraseLink */
  phraseLink?: Maybe<PhraseLink>;
  /** Retrieve multiple phraseVideos */
  phraseVideos: Array<PhraseVideo>;
  /** Retrieve a single phraseVideo */
  phraseVideo?: Maybe<PhraseVideo>;
  news: Array<News>;
  newsItem?: Maybe<News>;
  viewer?: Maybe<User>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryAssetsArgs = {
  where?: Maybe<AssetWhereInput>;
  orderBy?: Maybe<AssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryAssetArgs = {
  where: AssetWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryAssetsConnectionArgs = {
  where?: Maybe<AssetWhereInput>;
  orderBy?: Maybe<AssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBoulderArgs = {
  where: BoulderWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBouldersConnectionArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBoulderVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBoulderColorsArgs = {
  where?: Maybe<BoulderColorWhereInput>;
  orderBy?: Maybe<BoulderColorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBoulderColorsConnectionArgs = {
  where?: Maybe<BoulderColorWhereInput>;
  orderBy?: Maybe<BoulderColorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryBoulderColorVersionArgs = {
  where: VersionWhereInput;
};


export type QueryChallengesArgs = {
  where?: Maybe<ChallengeWhereInput>;
  orderBy?: Maybe<ChallengeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeArgs = {
  where: ChallengeWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengesConnectionArgs = {
  where?: Maybe<ChallengeWhereInput>;
  orderBy?: Maybe<ChallengeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalWhereInput>;
  orderBy?: Maybe<ChallengeGoalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeGoalsConnectionArgs = {
  where?: Maybe<ChallengeGoalWhereInput>;
  orderBy?: Maybe<ChallengeGoalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeGoalVersionArgs = {
  where: VersionWhereInput;
};


export type QueryChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressWhereInput>;
  orderBy?: Maybe<ChallengeProgressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeProgressesConnectionArgs = {
  where?: Maybe<ChallengeProgressWhereInput>;
  orderBy?: Maybe<ChallengeProgressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryChallengeProgressVersionArgs = {
  where: VersionWhereInput;
};


export type QueryClimbersArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryClimberArgs = {
  where: ClimberWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryClimbersConnectionArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryClimberVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCoursesArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryCourseArgs = {
  where: CourseWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryCoursesConnectionArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryCourseVersionArgs = {
  where: VersionWhereInput;
};


export type QueryDaysArgs = {
  where?: Maybe<DayWhereInput>;
  orderBy?: Maybe<DayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryDayArgs = {
  where: DayWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryDaysConnectionArgs = {
  where?: Maybe<DayWhereInput>;
  orderBy?: Maybe<DayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryDayVersionArgs = {
  where: VersionWhereInput;
};


export type QueryFoldersArgs = {
  where?: Maybe<FolderWhereInput>;
  orderBy?: Maybe<FolderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryFolderArgs = {
  where: FolderWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryFoldersConnectionArgs = {
  where?: Maybe<FolderWhereInput>;
  orderBy?: Maybe<FolderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryFolderVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGymsArgs = {
  where?: Maybe<GymWhereInput>;
  orderBy?: Maybe<GymOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryGymArgs = {
  where: GymWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryGymsConnectionArgs = {
  where?: Maybe<GymWhereInput>;
  orderBy?: Maybe<GymOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryGymVersionArgs = {
  where: VersionWhereInput;
};


export type QuerySeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QuerySeasonArgs = {
  where: SeasonWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QuerySeasonsConnectionArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QuerySeasonVersionArgs = {
  where: VersionWhereInput;
};


export type QueryStudentsArgs = {
  where?: Maybe<StudentWhereInput>;
  orderBy?: Maybe<StudentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryStudentArgs = {
  where: StudentWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryStudentsConnectionArgs = {
  where?: Maybe<StudentWhereInput>;
  orderBy?: Maybe<StudentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryStudentVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPhrasesArgs = {
  where?: Maybe<PhraseWhereInput>;
  orderBy?: Maybe<PhraseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseArgs = {
  where: PhraseWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseCommentsArgs = {
  where?: Maybe<PhraseCommentWhereInput>;
  orderBy?: Maybe<PhraseCommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseCommentArgs = {
  where: PhraseCommentWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseImagesArgs = {
  where?: Maybe<PhraseImageWhereInput>;
  orderBy?: Maybe<PhraseImageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseImageArgs = {
  where: PhraseImageWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseLinksArgs = {
  where?: Maybe<PhraseLinkWhereInput>;
  orderBy?: Maybe<PhraseLinkOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseLinkArgs = {
  where: PhraseLinkWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseVideosArgs = {
  where?: Maybe<PhraseVideoWhereInput>;
  orderBy?: Maybe<PhraseVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryPhraseVideoArgs = {
  where: PhraseVideoWhereUniqueInput;
  stage?: Stage;
  locales?: Array<Locale>;
};


export type QueryNewsItemArgs = {
  id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Create one boulder */
  createBoulder?: Maybe<Boulder>;
  /** Update one boulder */
  updateBoulder?: Maybe<Boulder>;
  /** Delete one boulder from _all_ existing stages. Returns deleted document. */
  deleteBoulder?: Maybe<Boulder>;
  /** Upsert one boulder */
  upsertBoulder?: Maybe<Boulder>;
  /** Publish one boulder */
  publishBoulder?: Maybe<Boulder>;
  /** Unpublish one boulder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBoulder?: Maybe<Boulder>;
  /** Update many Boulder documents */
  updateManyBouldersConnection: BoulderConnection;
  /** Delete many Boulder documents, return deleted documents */
  deleteManyBouldersConnection: BoulderConnection;
  /** Publish many Boulder documents */
  publishManyBouldersConnection: BoulderConnection;
  /** Find many Boulder documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBouldersConnection: BoulderConnection;
  /**
   * Update many boulders
   * @deprecated Please use the new paginated many mutation (updateManyBouldersConnection)
   */
  updateManyBoulders: BatchPayload;
  /**
   * Delete many Boulder documents
   * @deprecated Please use the new paginated many mutation (deleteManyBouldersConnection)
   */
  deleteManyBoulders: BatchPayload;
  /**
   * Publish many Boulder documents
   * @deprecated Please use the new paginated many mutation (publishManyBouldersConnection)
   */
  publishManyBoulders: BatchPayload;
  /**
   * Unpublish many Boulder documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBouldersConnection)
   */
  unpublishManyBoulders: BatchPayload;
  /** Create one boulderColor */
  createBoulderColor?: Maybe<BoulderColor>;
  /** Update one boulderColor */
  updateBoulderColor?: Maybe<BoulderColor>;
  /** Delete one boulderColor from _all_ existing stages. Returns deleted document. */
  deleteBoulderColor?: Maybe<BoulderColor>;
  /** Upsert one boulderColor */
  upsertBoulderColor?: Maybe<BoulderColor>;
  /** Publish one boulderColor */
  publishBoulderColor?: Maybe<BoulderColor>;
  /** Unpublish one boulderColor from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBoulderColor?: Maybe<BoulderColor>;
  /** Update many BoulderColor documents */
  updateManyBoulderColorsConnection: BoulderColorConnection;
  /** Delete many BoulderColor documents, return deleted documents */
  deleteManyBoulderColorsConnection: BoulderColorConnection;
  /** Publish many BoulderColor documents */
  publishManyBoulderColorsConnection: BoulderColorConnection;
  /** Find many BoulderColor documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBoulderColorsConnection: BoulderColorConnection;
  /**
   * Update many boulderColors
   * @deprecated Please use the new paginated many mutation (updateManyBoulderColorsConnection)
   */
  updateManyBoulderColors: BatchPayload;
  /**
   * Delete many BoulderColor documents
   * @deprecated Please use the new paginated many mutation (deleteManyBoulderColorsConnection)
   */
  deleteManyBoulderColors: BatchPayload;
  /**
   * Publish many BoulderColor documents
   * @deprecated Please use the new paginated many mutation (publishManyBoulderColorsConnection)
   */
  publishManyBoulderColors: BatchPayload;
  /**
   * Unpublish many BoulderColor documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBoulderColorsConnection)
   */
  unpublishManyBoulderColors: BatchPayload;
  /** Create one challenge */
  createChallenge?: Maybe<Challenge>;
  /** Update one challenge */
  updateChallenge?: Maybe<Challenge>;
  /** Delete one challenge from _all_ existing stages. Returns deleted document. */
  deleteChallenge?: Maybe<Challenge>;
  /** Upsert one challenge */
  upsertChallenge?: Maybe<Challenge>;
  /** Publish one challenge */
  publishChallenge?: Maybe<Challenge>;
  /** Unpublish one challenge from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishChallenge?: Maybe<Challenge>;
  /** Update many Challenge documents */
  updateManyChallengesConnection: ChallengeConnection;
  /** Delete many Challenge documents, return deleted documents */
  deleteManyChallengesConnection: ChallengeConnection;
  /** Publish many Challenge documents */
  publishManyChallengesConnection: ChallengeConnection;
  /** Find many Challenge documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyChallengesConnection: ChallengeConnection;
  /**
   * Update many challenges
   * @deprecated Please use the new paginated many mutation (updateManyChallengesConnection)
   */
  updateManyChallenges: BatchPayload;
  /**
   * Delete many Challenge documents
   * @deprecated Please use the new paginated many mutation (deleteManyChallengesConnection)
   */
  deleteManyChallenges: BatchPayload;
  /**
   * Publish many Challenge documents
   * @deprecated Please use the new paginated many mutation (publishManyChallengesConnection)
   */
  publishManyChallenges: BatchPayload;
  /**
   * Unpublish many Challenge documents
   * @deprecated Please use the new paginated many mutation (unpublishManyChallengesConnection)
   */
  unpublishManyChallenges: BatchPayload;
  /** Create one challengeGoal */
  createChallengeGoal?: Maybe<ChallengeGoal>;
  /** Update one challengeGoal */
  updateChallengeGoal?: Maybe<ChallengeGoal>;
  /** Delete one challengeGoal from _all_ existing stages. Returns deleted document. */
  deleteChallengeGoal?: Maybe<ChallengeGoal>;
  /** Upsert one challengeGoal */
  upsertChallengeGoal?: Maybe<ChallengeGoal>;
  /** Publish one challengeGoal */
  publishChallengeGoal?: Maybe<ChallengeGoal>;
  /** Unpublish one challengeGoal from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishChallengeGoal?: Maybe<ChallengeGoal>;
  /** Update many ChallengeGoal documents */
  updateManyChallengeGoalsConnection: ChallengeGoalConnection;
  /** Delete many ChallengeGoal documents, return deleted documents */
  deleteManyChallengeGoalsConnection: ChallengeGoalConnection;
  /** Publish many ChallengeGoal documents */
  publishManyChallengeGoalsConnection: ChallengeGoalConnection;
  /** Find many ChallengeGoal documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyChallengeGoalsConnection: ChallengeGoalConnection;
  /**
   * Update many challengeGoals
   * @deprecated Please use the new paginated many mutation (updateManyChallengeGoalsConnection)
   */
  updateManyChallengeGoals: BatchPayload;
  /**
   * Delete many ChallengeGoal documents
   * @deprecated Please use the new paginated many mutation (deleteManyChallengeGoalsConnection)
   */
  deleteManyChallengeGoals: BatchPayload;
  /**
   * Publish many ChallengeGoal documents
   * @deprecated Please use the new paginated many mutation (publishManyChallengeGoalsConnection)
   */
  publishManyChallengeGoals: BatchPayload;
  /**
   * Unpublish many ChallengeGoal documents
   * @deprecated Please use the new paginated many mutation (unpublishManyChallengeGoalsConnection)
   */
  unpublishManyChallengeGoals: BatchPayload;
  /** Create one challengeProgress */
  createChallengeProgress?: Maybe<ChallengeProgress>;
  /** Update one challengeProgress */
  updateChallengeProgress?: Maybe<ChallengeProgress>;
  /** Delete one challengeProgress from _all_ existing stages. Returns deleted document. */
  deleteChallengeProgress?: Maybe<ChallengeProgress>;
  /** Upsert one challengeProgress */
  upsertChallengeProgress?: Maybe<ChallengeProgress>;
  /** Publish one challengeProgress */
  publishChallengeProgress?: Maybe<ChallengeProgress>;
  /** Unpublish one challengeProgress from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishChallengeProgress?: Maybe<ChallengeProgress>;
  /** Update many ChallengeProgress documents */
  updateManyChallengeProgressesConnection: ChallengeProgressConnection;
  /** Delete many ChallengeProgress documents, return deleted documents */
  deleteManyChallengeProgressesConnection: ChallengeProgressConnection;
  /** Publish many ChallengeProgress documents */
  publishManyChallengeProgressesConnection: ChallengeProgressConnection;
  /** Find many ChallengeProgress documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyChallengeProgressesConnection: ChallengeProgressConnection;
  /**
   * Update many challengeProgresses
   * @deprecated Please use the new paginated many mutation (updateManyChallengeProgressesConnection)
   */
  updateManyChallengeProgresses: BatchPayload;
  /**
   * Delete many ChallengeProgress documents
   * @deprecated Please use the new paginated many mutation (deleteManyChallengeProgressesConnection)
   */
  deleteManyChallengeProgresses: BatchPayload;
  /**
   * Publish many ChallengeProgress documents
   * @deprecated Please use the new paginated many mutation (publishManyChallengeProgressesConnection)
   */
  publishManyChallengeProgresses: BatchPayload;
  /**
   * Unpublish many ChallengeProgress documents
   * @deprecated Please use the new paginated many mutation (unpublishManyChallengeProgressesConnection)
   */
  unpublishManyChallengeProgresses: BatchPayload;
  /** Create one climber */
  createClimber?: Maybe<Climber>;
  /** Update one climber */
  updateClimber?: Maybe<Climber>;
  /** Delete one climber from _all_ existing stages. Returns deleted document. */
  deleteClimber?: Maybe<Climber>;
  /** Upsert one climber */
  upsertClimber?: Maybe<Climber>;
  /** Publish one climber */
  publishClimber?: Maybe<Climber>;
  /** Unpublish one climber from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishClimber?: Maybe<Climber>;
  /** Update many Climber documents */
  updateManyClimbersConnection: ClimberConnection;
  /** Delete many Climber documents, return deleted documents */
  deleteManyClimbersConnection: ClimberConnection;
  /** Publish many Climber documents */
  publishManyClimbersConnection: ClimberConnection;
  /** Find many Climber documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyClimbersConnection: ClimberConnection;
  /**
   * Update many climbers
   * @deprecated Please use the new paginated many mutation (updateManyClimbersConnection)
   */
  updateManyClimbers: BatchPayload;
  /**
   * Delete many Climber documents
   * @deprecated Please use the new paginated many mutation (deleteManyClimbersConnection)
   */
  deleteManyClimbers: BatchPayload;
  /**
   * Publish many Climber documents
   * @deprecated Please use the new paginated many mutation (publishManyClimbersConnection)
   */
  publishManyClimbers: BatchPayload;
  /**
   * Unpublish many Climber documents
   * @deprecated Please use the new paginated many mutation (unpublishManyClimbersConnection)
   */
  unpublishManyClimbers: BatchPayload;
  /** Create one course */
  createCourse?: Maybe<Course>;
  /** Update one course */
  updateCourse?: Maybe<Course>;
  /** Delete one course from _all_ existing stages. Returns deleted document. */
  deleteCourse?: Maybe<Course>;
  /** Upsert one course */
  upsertCourse?: Maybe<Course>;
  /** Publish one course */
  publishCourse?: Maybe<Course>;
  /** Unpublish one course from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCourse?: Maybe<Course>;
  /** Update many Course documents */
  updateManyCoursesConnection: CourseConnection;
  /** Delete many Course documents, return deleted documents */
  deleteManyCoursesConnection: CourseConnection;
  /** Publish many Course documents */
  publishManyCoursesConnection: CourseConnection;
  /** Find many Course documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCoursesConnection: CourseConnection;
  /**
   * Update many courses
   * @deprecated Please use the new paginated many mutation (updateManyCoursesConnection)
   */
  updateManyCourses: BatchPayload;
  /**
   * Delete many Course documents
   * @deprecated Please use the new paginated many mutation (deleteManyCoursesConnection)
   */
  deleteManyCourses: BatchPayload;
  /**
   * Publish many Course documents
   * @deprecated Please use the new paginated many mutation (publishManyCoursesConnection)
   */
  publishManyCourses: BatchPayload;
  /**
   * Unpublish many Course documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCoursesConnection)
   */
  unpublishManyCourses: BatchPayload;
  /** Create one day */
  createDay?: Maybe<Day>;
  /** Update one day */
  updateDay?: Maybe<Day>;
  /** Delete one day from _all_ existing stages. Returns deleted document. */
  deleteDay?: Maybe<Day>;
  /** Upsert one day */
  upsertDay?: Maybe<Day>;
  /** Publish one day */
  publishDay?: Maybe<Day>;
  /** Unpublish one day from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDay?: Maybe<Day>;
  /** Update many Day documents */
  updateManyDaysConnection: DayConnection;
  /** Delete many Day documents, return deleted documents */
  deleteManyDaysConnection: DayConnection;
  /** Publish many Day documents */
  publishManyDaysConnection: DayConnection;
  /** Find many Day documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDaysConnection: DayConnection;
  /**
   * Update many days
   * @deprecated Please use the new paginated many mutation (updateManyDaysConnection)
   */
  updateManyDays: BatchPayload;
  /**
   * Delete many Day documents
   * @deprecated Please use the new paginated many mutation (deleteManyDaysConnection)
   */
  deleteManyDays: BatchPayload;
  /**
   * Publish many Day documents
   * @deprecated Please use the new paginated many mutation (publishManyDaysConnection)
   */
  publishManyDays: BatchPayload;
  /**
   * Unpublish many Day documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDaysConnection)
   */
  unpublishManyDays: BatchPayload;
  /** Create one folder */
  createFolder?: Maybe<Folder>;
  /** Update one folder */
  updateFolder?: Maybe<Folder>;
  /** Delete one folder from _all_ existing stages. Returns deleted document. */
  deleteFolder?: Maybe<Folder>;
  /** Upsert one folder */
  upsertFolder?: Maybe<Folder>;
  /** Publish one folder */
  publishFolder?: Maybe<Folder>;
  /** Unpublish one folder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFolder?: Maybe<Folder>;
  /** Update many Folder documents */
  updateManyFoldersConnection: FolderConnection;
  /** Delete many Folder documents, return deleted documents */
  deleteManyFoldersConnection: FolderConnection;
  /** Publish many Folder documents */
  publishManyFoldersConnection: FolderConnection;
  /** Find many Folder documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFoldersConnection: FolderConnection;
  /**
   * Update many folders
   * @deprecated Please use the new paginated many mutation (updateManyFoldersConnection)
   */
  updateManyFolders: BatchPayload;
  /**
   * Delete many Folder documents
   * @deprecated Please use the new paginated many mutation (deleteManyFoldersConnection)
   */
  deleteManyFolders: BatchPayload;
  /**
   * Publish many Folder documents
   * @deprecated Please use the new paginated many mutation (publishManyFoldersConnection)
   */
  publishManyFolders: BatchPayload;
  /**
   * Unpublish many Folder documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFoldersConnection)
   */
  unpublishManyFolders: BatchPayload;
  /** Create one gym */
  createGym?: Maybe<Gym>;
  /** Update one gym */
  updateGym?: Maybe<Gym>;
  /** Delete one gym from _all_ existing stages. Returns deleted document. */
  deleteGym?: Maybe<Gym>;
  /** Upsert one gym */
  upsertGym?: Maybe<Gym>;
  /** Publish one gym */
  publishGym?: Maybe<Gym>;
  /** Unpublish one gym from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGym?: Maybe<Gym>;
  /** Update many Gym documents */
  updateManyGymsConnection: GymConnection;
  /** Delete many Gym documents, return deleted documents */
  deleteManyGymsConnection: GymConnection;
  /** Publish many Gym documents */
  publishManyGymsConnection: GymConnection;
  /** Find many Gym documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGymsConnection: GymConnection;
  /**
   * Update many gyms
   * @deprecated Please use the new paginated many mutation (updateManyGymsConnection)
   */
  updateManyGyms: BatchPayload;
  /**
   * Delete many Gym documents
   * @deprecated Please use the new paginated many mutation (deleteManyGymsConnection)
   */
  deleteManyGyms: BatchPayload;
  /**
   * Publish many Gym documents
   * @deprecated Please use the new paginated many mutation (publishManyGymsConnection)
   */
  publishManyGyms: BatchPayload;
  /**
   * Unpublish many Gym documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGymsConnection)
   */
  unpublishManyGyms: BatchPayload;
  /** Create one season */
  createSeason?: Maybe<Season>;
  /** Update one season */
  updateSeason?: Maybe<Season>;
  /** Delete one season from _all_ existing stages. Returns deleted document. */
  deleteSeason?: Maybe<Season>;
  /** Upsert one season */
  upsertSeason?: Maybe<Season>;
  /** Publish one season */
  publishSeason?: Maybe<Season>;
  /** Unpublish one season from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSeason?: Maybe<Season>;
  /** Update many Season documents */
  updateManySeasonsConnection: SeasonConnection;
  /** Delete many Season documents, return deleted documents */
  deleteManySeasonsConnection: SeasonConnection;
  /** Publish many Season documents */
  publishManySeasonsConnection: SeasonConnection;
  /** Find many Season documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySeasonsConnection: SeasonConnection;
  /**
   * Update many seasons
   * @deprecated Please use the new paginated many mutation (updateManySeasonsConnection)
   */
  updateManySeasons: BatchPayload;
  /**
   * Delete many Season documents
   * @deprecated Please use the new paginated many mutation (deleteManySeasonsConnection)
   */
  deleteManySeasons: BatchPayload;
  /**
   * Publish many Season documents
   * @deprecated Please use the new paginated many mutation (publishManySeasonsConnection)
   */
  publishManySeasons: BatchPayload;
  /**
   * Unpublish many Season documents
   * @deprecated Please use the new paginated many mutation (unpublishManySeasonsConnection)
   */
  unpublishManySeasons: BatchPayload;
  /** Create one student */
  createStudent?: Maybe<Student>;
  /** Update one student */
  updateStudent?: Maybe<Student>;
  /** Delete one student from _all_ existing stages. Returns deleted document. */
  deleteStudent?: Maybe<Student>;
  /** Upsert one student */
  upsertStudent?: Maybe<Student>;
  /** Publish one student */
  publishStudent?: Maybe<Student>;
  /** Unpublish one student from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishStudent?: Maybe<Student>;
  /** Update many Student documents */
  updateManyStudentsConnection: StudentConnection;
  /** Delete many Student documents, return deleted documents */
  deleteManyStudentsConnection: StudentConnection;
  /** Publish many Student documents */
  publishManyStudentsConnection: StudentConnection;
  /** Find many Student documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyStudentsConnection: StudentConnection;
  /**
   * Update many students
   * @deprecated Please use the new paginated many mutation (updateManyStudentsConnection)
   */
  updateManyStudents: BatchPayload;
  /**
   * Delete many Student documents
   * @deprecated Please use the new paginated many mutation (deleteManyStudentsConnection)
   */
  deleteManyStudents: BatchPayload;
  /**
   * Publish many Student documents
   * @deprecated Please use the new paginated many mutation (publishManyStudentsConnection)
   */
  publishManyStudents: BatchPayload;
  /**
   * Unpublish many Student documents
   * @deprecated Please use the new paginated many mutation (unpublishManyStudentsConnection)
   */
  unpublishManyStudents: BatchPayload;
  /** Create one phrase */
  createPhrase?: Maybe<Phrase>;
  /** Update one phrase */
  updatePhrase?: Maybe<Phrase>;
  /** Delete one phrase from _all_ existing stages. Returns deleted document. */
  deletePhrase?: Maybe<Phrase>;
  /** Publish one phrase */
  publishPhrase?: Maybe<Phrase>;
  /** Unpublish one phrase from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhrase?: Maybe<Phrase>;
  /** Create one phraseComment */
  createPhraseComment?: Maybe<PhraseComment>;
  /** Update one phraseComment */
  updatePhraseComment?: Maybe<PhraseComment>;
  /** Delete one phraseComment from _all_ existing stages. Returns deleted document. */
  deletePhraseComment?: Maybe<PhraseComment>;
  /** Publish one phraseComment */
  publishPhraseComment?: Maybe<PhraseComment>;
  /** Unpublish one phraseComment from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhraseComment?: Maybe<PhraseComment>;
  /** Create one phraseImage */
  createPhraseImage?: Maybe<PhraseImage>;
  /** Update one phraseImage */
  updatePhraseImage?: Maybe<PhraseImage>;
  /** Delete one phraseImage from _all_ existing stages. Returns deleted document. */
  deletePhraseImage?: Maybe<PhraseImage>;
  /** Publish one phraseImage */
  publishPhraseImage?: Maybe<PhraseImage>;
  /** Unpublish one phraseImage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhraseImage?: Maybe<PhraseImage>;
  /** Create one phraseLink */
  createPhraseLink?: Maybe<PhraseLink>;
  /** Update one phraseLink */
  updatePhraseLink?: Maybe<PhraseLink>;
  /** Delete one phraseLink from _all_ existing stages. Returns deleted document. */
  deletePhraseLink?: Maybe<PhraseLink>;
  /** Publish one phraseLink */
  publishPhraseLink?: Maybe<PhraseLink>;
  /** Unpublish one phraseLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhraseLink?: Maybe<PhraseLink>;
  /** Create one phraseVideo */
  createPhraseVideo?: Maybe<PhraseVideo>;
  /** Update one phraseVideo */
  updatePhraseVideo?: Maybe<PhraseVideo>;
  /** Delete one phraseVideo from _all_ existing stages. Returns deleted document. */
  deletePhraseVideo?: Maybe<PhraseVideo>;
  /** Publish one phraseVideo */
  publishPhraseVideo?: Maybe<PhraseVideo>;
  /** Unpublish one phraseVideo from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhraseVideo?: Maybe<PhraseVideo>;
  login?: Maybe<User>;
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};


export type MutationUpdateAssetArgs = {
  where: AssetWhereUniqueInput;
  data: AssetUpdateInput;
};


export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};


export type MutationUpsertAssetArgs = {
  where: AssetWhereUniqueInput;
  upsert: AssetUpsertInput;
};


export type MutationPublishAssetArgs = {
  where: AssetWhereUniqueInput;
  locales?: Maybe<Array<Locale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<Stage>;
};


export type MutationUnpublishAssetArgs = {
  where: AssetWhereUniqueInput;
  from?: Array<Stage>;
  locales?: Maybe<Array<Locale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>;
  data: AssetUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<Locale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};


export type MutationUnpublishManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<Locale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>;
  data: AssetUpdateManyInput;
};


export type MutationDeleteManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>;
};


export type MutationPublishManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>;
  to?: Array<Stage>;
  locales?: Maybe<Array<Locale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};


export type MutationUnpublishManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>;
  from?: Array<Stage>;
  locales?: Maybe<Array<Locale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateBoulderArgs = {
  data: BoulderCreateInput;
};


export type MutationUpdateBoulderArgs = {
  where: BoulderWhereUniqueInput;
  data: BoulderUpdateInput;
};


export type MutationDeleteBoulderArgs = {
  where: BoulderWhereUniqueInput;
};


export type MutationUpsertBoulderArgs = {
  where: BoulderWhereUniqueInput;
  upsert: BoulderUpsertInput;
};


export type MutationPublishBoulderArgs = {
  where: BoulderWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishBoulderArgs = {
  where: BoulderWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyBouldersConnectionArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  data: BoulderUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyBouldersConnectionArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyBouldersConnectionArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyBouldersConnectionArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyBouldersArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  data: BoulderUpdateManyInput;
};


export type MutationDeleteManyBouldersArgs = {
  where?: Maybe<BoulderManyWhereInput>;
};


export type MutationPublishManyBouldersArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyBouldersArgs = {
  where?: Maybe<BoulderManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateBoulderColorArgs = {
  data: BoulderColorCreateInput;
};


export type MutationUpdateBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
  data: BoulderColorUpdateInput;
};


export type MutationDeleteBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
};


export type MutationUpsertBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
  upsert: BoulderColorUpsertInput;
};


export type MutationPublishBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishBoulderColorArgs = {
  where: BoulderColorWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyBoulderColorsConnectionArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  data: BoulderColorUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyBoulderColorsConnectionArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyBoulderColorsConnectionArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyBoulderColorsConnectionArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyBoulderColorsArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  data: BoulderColorUpdateManyInput;
};


export type MutationDeleteManyBoulderColorsArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
};


export type MutationPublishManyBoulderColorsArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyBoulderColorsArgs = {
  where?: Maybe<BoulderColorManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateChallengeArgs = {
  data: ChallengeCreateInput;
};


export type MutationUpdateChallengeArgs = {
  where: ChallengeWhereUniqueInput;
  data: ChallengeUpdateInput;
};


export type MutationDeleteChallengeArgs = {
  where: ChallengeWhereUniqueInput;
};


export type MutationUpsertChallengeArgs = {
  where: ChallengeWhereUniqueInput;
  upsert: ChallengeUpsertInput;
};


export type MutationPublishChallengeArgs = {
  where: ChallengeWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishChallengeArgs = {
  where: ChallengeWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyChallengesConnectionArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  data: ChallengeUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyChallengesConnectionArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyChallengesConnectionArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyChallengesConnectionArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyChallengesArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  data: ChallengeUpdateManyInput;
};


export type MutationDeleteManyChallengesArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
};


export type MutationPublishManyChallengesArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyChallengesArgs = {
  where?: Maybe<ChallengeManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateChallengeGoalArgs = {
  data: ChallengeGoalCreateInput;
};


export type MutationUpdateChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
  data: ChallengeGoalUpdateInput;
};


export type MutationDeleteChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
};


export type MutationUpsertChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
  upsert: ChallengeGoalUpsertInput;
};


export type MutationPublishChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishChallengeGoalArgs = {
  where: ChallengeGoalWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyChallengeGoalsConnectionArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  data: ChallengeGoalUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyChallengeGoalsConnectionArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyChallengeGoalsConnectionArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyChallengeGoalsConnectionArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  data: ChallengeGoalUpdateManyInput;
};


export type MutationDeleteManyChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
};


export type MutationPublishManyChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateChallengeProgressArgs = {
  data: ChallengeProgressCreateInput;
};


export type MutationUpdateChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
  data: ChallengeProgressUpdateInput;
};


export type MutationDeleteChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
};


export type MutationUpsertChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
  upsert: ChallengeProgressUpsertInput;
};


export type MutationPublishChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishChallengeProgressArgs = {
  where: ChallengeProgressWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyChallengeProgressesConnectionArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  data: ChallengeProgressUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyChallengeProgressesConnectionArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyChallengeProgressesConnectionArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyChallengeProgressesConnectionArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  data: ChallengeProgressUpdateManyInput;
};


export type MutationDeleteManyChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
};


export type MutationPublishManyChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateClimberArgs = {
  data: ClimberCreateInput;
};


export type MutationUpdateClimberArgs = {
  where: ClimberWhereUniqueInput;
  data: ClimberUpdateInput;
};


export type MutationDeleteClimberArgs = {
  where: ClimberWhereUniqueInput;
};


export type MutationUpsertClimberArgs = {
  where: ClimberWhereUniqueInput;
  upsert: ClimberUpsertInput;
};


export type MutationPublishClimberArgs = {
  where: ClimberWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishClimberArgs = {
  where: ClimberWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyClimbersConnectionArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  data: ClimberUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyClimbersConnectionArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyClimbersConnectionArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyClimbersConnectionArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyClimbersArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  data: ClimberUpdateManyInput;
};


export type MutationDeleteManyClimbersArgs = {
  where?: Maybe<ClimberManyWhereInput>;
};


export type MutationPublishManyClimbersArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyClimbersArgs = {
  where?: Maybe<ClimberManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateCourseArgs = {
  data: CourseCreateInput;
};


export type MutationUpdateCourseArgs = {
  where: CourseWhereUniqueInput;
  data: CourseUpdateInput;
};


export type MutationDeleteCourseArgs = {
  where: CourseWhereUniqueInput;
};


export type MutationUpsertCourseArgs = {
  where: CourseWhereUniqueInput;
  upsert: CourseUpsertInput;
};


export type MutationPublishCourseArgs = {
  where: CourseWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishCourseArgs = {
  where: CourseWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyCoursesConnectionArgs = {
  where?: Maybe<CourseManyWhereInput>;
  data: CourseUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyCoursesConnectionArgs = {
  where?: Maybe<CourseManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyCoursesConnectionArgs = {
  where?: Maybe<CourseManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyCoursesConnectionArgs = {
  where?: Maybe<CourseManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyCoursesArgs = {
  where?: Maybe<CourseManyWhereInput>;
  data: CourseUpdateManyInput;
};


export type MutationDeleteManyCoursesArgs = {
  where?: Maybe<CourseManyWhereInput>;
};


export type MutationPublishManyCoursesArgs = {
  where?: Maybe<CourseManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyCoursesArgs = {
  where?: Maybe<CourseManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateDayArgs = {
  data: DayCreateInput;
};


export type MutationUpdateDayArgs = {
  where: DayWhereUniqueInput;
  data: DayUpdateInput;
};


export type MutationDeleteDayArgs = {
  where: DayWhereUniqueInput;
};


export type MutationUpsertDayArgs = {
  where: DayWhereUniqueInput;
  upsert: DayUpsertInput;
};


export type MutationPublishDayArgs = {
  where: DayWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishDayArgs = {
  where: DayWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyDaysConnectionArgs = {
  where?: Maybe<DayManyWhereInput>;
  data: DayUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyDaysConnectionArgs = {
  where?: Maybe<DayManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyDaysConnectionArgs = {
  where?: Maybe<DayManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyDaysConnectionArgs = {
  where?: Maybe<DayManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyDaysArgs = {
  where?: Maybe<DayManyWhereInput>;
  data: DayUpdateManyInput;
};


export type MutationDeleteManyDaysArgs = {
  where?: Maybe<DayManyWhereInput>;
};


export type MutationPublishManyDaysArgs = {
  where?: Maybe<DayManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyDaysArgs = {
  where?: Maybe<DayManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateFolderArgs = {
  data: FolderCreateInput;
};


export type MutationUpdateFolderArgs = {
  where: FolderWhereUniqueInput;
  data: FolderUpdateInput;
};


export type MutationDeleteFolderArgs = {
  where: FolderWhereUniqueInput;
};


export type MutationUpsertFolderArgs = {
  where: FolderWhereUniqueInput;
  upsert: FolderUpsertInput;
};


export type MutationPublishFolderArgs = {
  where: FolderWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishFolderArgs = {
  where: FolderWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyFoldersConnectionArgs = {
  where?: Maybe<FolderManyWhereInput>;
  data: FolderUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyFoldersConnectionArgs = {
  where?: Maybe<FolderManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyFoldersConnectionArgs = {
  where?: Maybe<FolderManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyFoldersConnectionArgs = {
  where?: Maybe<FolderManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyFoldersArgs = {
  where?: Maybe<FolderManyWhereInput>;
  data: FolderUpdateManyInput;
};


export type MutationDeleteManyFoldersArgs = {
  where?: Maybe<FolderManyWhereInput>;
};


export type MutationPublishManyFoldersArgs = {
  where?: Maybe<FolderManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyFoldersArgs = {
  where?: Maybe<FolderManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateGymArgs = {
  data: GymCreateInput;
};


export type MutationUpdateGymArgs = {
  where: GymWhereUniqueInput;
  data: GymUpdateInput;
};


export type MutationDeleteGymArgs = {
  where: GymWhereUniqueInput;
};


export type MutationUpsertGymArgs = {
  where: GymWhereUniqueInput;
  upsert: GymUpsertInput;
};


export type MutationPublishGymArgs = {
  where: GymWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishGymArgs = {
  where: GymWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyGymsConnectionArgs = {
  where?: Maybe<GymManyWhereInput>;
  data: GymUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyGymsConnectionArgs = {
  where?: Maybe<GymManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyGymsConnectionArgs = {
  where?: Maybe<GymManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyGymsConnectionArgs = {
  where?: Maybe<GymManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyGymsArgs = {
  where?: Maybe<GymManyWhereInput>;
  data: GymUpdateManyInput;
};


export type MutationDeleteManyGymsArgs = {
  where?: Maybe<GymManyWhereInput>;
};


export type MutationPublishManyGymsArgs = {
  where?: Maybe<GymManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyGymsArgs = {
  where?: Maybe<GymManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateSeasonArgs = {
  data: SeasonCreateInput;
};


export type MutationUpdateSeasonArgs = {
  where: SeasonWhereUniqueInput;
  data: SeasonUpdateInput;
};


export type MutationDeleteSeasonArgs = {
  where: SeasonWhereUniqueInput;
};


export type MutationUpsertSeasonArgs = {
  where: SeasonWhereUniqueInput;
  upsert: SeasonUpsertInput;
};


export type MutationPublishSeasonArgs = {
  where: SeasonWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishSeasonArgs = {
  where: SeasonWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManySeasonsConnectionArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  data: SeasonUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManySeasonsConnectionArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManySeasonsConnectionArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManySeasonsConnectionArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManySeasonsArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  data: SeasonUpdateManyInput;
};


export type MutationDeleteManySeasonsArgs = {
  where?: Maybe<SeasonManyWhereInput>;
};


export type MutationPublishManySeasonsArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManySeasonsArgs = {
  where?: Maybe<SeasonManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreateStudentArgs = {
  data: StudentCreateInput;
};


export type MutationUpdateStudentArgs = {
  where: StudentWhereUniqueInput;
  data: StudentUpdateInput;
};


export type MutationDeleteStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type MutationUpsertStudentArgs = {
  where: StudentWhereUniqueInput;
  upsert: StudentUpsertInput;
};


export type MutationPublishStudentArgs = {
  where: StudentWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishStudentArgs = {
  where: StudentWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationUpdateManyStudentsConnectionArgs = {
  where?: Maybe<StudentManyWhereInput>;
  data: StudentUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationDeleteManyStudentsConnectionArgs = {
  where?: Maybe<StudentManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationPublishManyStudentsConnectionArgs = {
  where?: Maybe<StudentManyWhereInput>;
  from?: Maybe<Stage>;
  to?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUnpublishManyStudentsConnectionArgs = {
  where?: Maybe<StudentManyWhereInput>;
  stage?: Maybe<Stage>;
  from?: Array<Stage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};


export type MutationUpdateManyStudentsArgs = {
  where?: Maybe<StudentManyWhereInput>;
  data: StudentUpdateManyInput;
};


export type MutationDeleteManyStudentsArgs = {
  where?: Maybe<StudentManyWhereInput>;
};


export type MutationPublishManyStudentsArgs = {
  where?: Maybe<StudentManyWhereInput>;
  to?: Array<Stage>;
};


export type MutationUnpublishManyStudentsArgs = {
  where?: Maybe<StudentManyWhereInput>;
  from?: Array<Stage>;
};


export type MutationCreatePhraseArgs = {
  data: PhraseCreateInput;
};


export type MutationUpdatePhraseArgs = {
  where: PhraseWhereUniqueInput;
  data: PhraseUpdateInput;
};


export type MutationDeletePhraseArgs = {
  where: PhraseWhereUniqueInput;
};


export type MutationPublishPhraseArgs = {
  where: PhraseWhereUniqueInput;
  locales?: Maybe<Array<Locale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<Stage>;
};


export type MutationUnpublishPhraseArgs = {
  where: PhraseWhereUniqueInput;
  from?: Array<Stage>;
  locales?: Maybe<Array<Locale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};


export type MutationCreatePhraseCommentArgs = {
  data: PhraseCommentCreateInput;
};


export type MutationUpdatePhraseCommentArgs = {
  where: PhraseCommentWhereUniqueInput;
  data: PhraseCommentUpdateInput;
};


export type MutationDeletePhraseCommentArgs = {
  where: PhraseCommentWhereUniqueInput;
};


export type MutationPublishPhraseCommentArgs = {
  where: PhraseCommentWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishPhraseCommentArgs = {
  where: PhraseCommentWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationCreatePhraseImageArgs = {
  data: PhraseImageCreateInput;
};


export type MutationUpdatePhraseImageArgs = {
  where: PhraseImageWhereUniqueInput;
  data: PhraseImageUpdateInput;
};


export type MutationDeletePhraseImageArgs = {
  where: PhraseImageWhereUniqueInput;
};


export type MutationPublishPhraseImageArgs = {
  where: PhraseImageWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishPhraseImageArgs = {
  where: PhraseImageWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationCreatePhraseLinkArgs = {
  data: PhraseLinkCreateInput;
};


export type MutationUpdatePhraseLinkArgs = {
  where: PhraseLinkWhereUniqueInput;
  data: PhraseLinkUpdateInput;
};


export type MutationDeletePhraseLinkArgs = {
  where: PhraseLinkWhereUniqueInput;
};


export type MutationPublishPhraseLinkArgs = {
  where: PhraseLinkWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishPhraseLinkArgs = {
  where: PhraseLinkWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationCreatePhraseVideoArgs = {
  data: PhraseVideoCreateInput;
};


export type MutationUpdatePhraseVideoArgs = {
  where: PhraseVideoWhereUniqueInput;
  data: PhraseVideoUpdateInput;
};


export type MutationDeletePhraseVideoArgs = {
  where: PhraseVideoWhereUniqueInput;
};


export type MutationPublishPhraseVideoArgs = {
  where: PhraseVideoWhereUniqueInput;
  to?: Array<Stage>;
};


export type MutationUnpublishPhraseVideoArgs = {
  where: PhraseVideoWhereUniqueInput;
  from?: Array<Stage>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Aggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int'];
};

/** Asset system model */
export type Asset = Node & {
  __typename?: 'Asset';
  /** System stage field */
  stage: Stage;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Asset>;
  /** Get the document in other stages */
  documentInStages: Array<Asset>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** The file handle */
  handle: Scalars['String'];
  /** The file name */
  fileName: Scalars['String'];
  /** The height of the file */
  height?: Maybe<Scalars['Float']>;
  /** The file width */
  width?: Maybe<Scalars['Float']>;
  /** The file size */
  size?: Maybe<Scalars['Float']>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']>;
  boulders: Array<Boulder>;
  gym: Array<Gym>;
  avatarClimber: Array<Climber>;
  /** List of Asset versions */
  history: Array<Version>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'];
};


/** Asset system model */
export type AssetLocalizationsArgs = {
  locales?: Array<Locale>;
  includeCurrent?: Scalars['Boolean'];
};


/** Asset system model */
export type AssetDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


/** Asset system model */
export type AssetGymArgs = {
  where?: Maybe<GymWhereInput>;
  orderBy?: Maybe<GymOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


/** Asset system model */
export type AssetAvatarClimberArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};


/** Asset system model */
export type AssetUrlArgs = {
  transformation?: Maybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
  /** Document to connect */
  where: AssetWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  aggregate: Aggregate;
};

export type AssetCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  handle: Scalars['String'];
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
  gym?: Maybe<GymCreateManyInlineInput>;
  avatarClimber?: Maybe<ClimberCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<AssetCreateLocalizationsInput>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  handle: Scalars['String'];
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Create and connect multiple existing Asset documents */
  create?: Maybe<Array<AssetCreateInput>>;
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<AssetWhereUniqueInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<AssetCreateInput>;
  /** Connect one existing Asset document */
  connect?: Maybe<AssetWhereUniqueInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** The item at the end of the edge. */
  node: Asset;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  gym_every?: Maybe<GymWhereInput>;
  gym_some?: Maybe<GymWhereInput>;
  gym_none?: Maybe<GymWhereInput>;
  avatarClimber_every?: Maybe<ClimberWhereInput>;
  avatarClimber_some?: Maybe<ClimberWhereInput>;
  avatarClimber_none?: Maybe<ClimberWhereInput>;
};

export enum AssetOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC'
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  image?: Maybe<ImageTransformationInput>;
  document?: Maybe<DocumentTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: Maybe<Scalars['Boolean']>;
};

export type AssetUpdateInput = {
  handle?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
  gym?: Maybe<GymUpdateManyInlineInput>;
  avatarClimber?: Maybe<ClimberUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: Maybe<AssetUpdateLocalizationsInput>;
};

export type AssetUpdateLocalizationDataInput = {
  handle?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to update */
  update?: Maybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: Maybe<Array<AssetUpsertLocalizationInput>>;
  /** Localizations to delete */
  delete?: Maybe<Array<Locale>>;
};

export type AssetUpdateManyInlineInput = {
  /** Create and connect multiple Asset documents */
  create?: Maybe<Array<AssetCreateInput>>;
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<AssetConnectInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: Maybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: Maybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: Maybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: Maybe<Array<AssetWhereUniqueInput>>;
  /** Delete multiple Asset documents */
  delete?: Maybe<Array<AssetWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: Maybe<AssetUpdateManyLocalizationsInput>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: AssetWhereInput;
  /** Update many input */
  data: AssetUpdateManyInput;
};

export type AssetUpdateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<AssetCreateInput>;
  /** Update single Asset document */
  update?: Maybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: Maybe<AssetUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Asset document */
  connect?: Maybe<AssetWhereUniqueInput>;
  /** Disconnect currently connected Asset document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Asset document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AssetWhereUniqueInput;
  /** Document to update */
  data: AssetUpdateInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  update: AssetUpdateLocalizationDataInput;
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AssetWhereUniqueInput;
  /** Upsert data */
  data: AssetUpsertInput;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  handle?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  handle_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  handle_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  handle_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fileName_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  fileName_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  fileName_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  height_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  height_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  height_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  width_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  width_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  width_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  size_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  size_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  size_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  mimeType_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  mimeType_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: Maybe<Scalars['String']>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  gym_every?: Maybe<GymWhereInput>;
  gym_some?: Maybe<GymWhereInput>;
  gym_none?: Maybe<GymWhereInput>;
  avatarClimber_every?: Maybe<ClimberWhereInput>;
  avatarClimber_some?: Maybe<ClimberWhereInput>;
  avatarClimber_none?: Maybe<ClimberWhereInput>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

export type Boulder = Node & {
  __typename?: 'Boulder';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Boulder>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  image: Asset;
  data?: Maybe<Scalars['Json']>;
  gym?: Maybe<Gym>;
  climber?: Maybe<Climber>;
  folder?: Maybe<Folder>;
  color?: Maybe<BoulderColor>;
  challenges: Array<Challenge>;
  /** List of Boulder versions */
  history: Array<Version>;
};


export type BoulderDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type BoulderImageArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderClimberArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderFolderArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderColorArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderChallengesArgs = {
  where?: Maybe<ChallengeWhereInput>;
  orderBy?: Maybe<ChallengeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type BoulderHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type BoulderColor = Node & {
  __typename?: 'BoulderColor';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<BoulderColor>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  color: Color;
  contrast: Color;
  description?: Maybe<Scalars['String']>;
  gym?: Maybe<Gym>;
  boulders: Array<Boulder>;
  /** List of BoulderColor versions */
  history: Array<Version>;
};


export type BoulderColorDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type BoulderColorGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type BoulderColorBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type BoulderColorHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type BoulderColorConnectInput = {
  /** Document to connect */
  where: BoulderColorWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type BoulderColorConnection = {
  __typename?: 'BoulderColorConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<BoulderColorEdge>;
  aggregate: Aggregate;
};

export type BoulderColorCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  color: ColorInput;
  contrast: ColorInput;
  description?: Maybe<Scalars['String']>;
  gym?: Maybe<GymCreateOneInlineInput>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
};

export type BoulderColorCreateManyInlineInput = {
  /** Create and connect multiple existing BoulderColor documents */
  create?: Maybe<Array<BoulderColorCreateInput>>;
  /** Connect multiple existing BoulderColor documents */
  connect?: Maybe<Array<BoulderColorWhereUniqueInput>>;
};

export type BoulderColorCreateOneInlineInput = {
  /** Create and connect one BoulderColor document */
  create?: Maybe<BoulderColorCreateInput>;
  /** Connect one existing BoulderColor document */
  connect?: Maybe<BoulderColorWhereUniqueInput>;
};

/** An edge in a connection. */
export type BoulderColorEdge = {
  __typename?: 'BoulderColorEdge';
  /** The item at the end of the edge. */
  node: BoulderColor;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type BoulderColorManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BoulderColorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BoulderColorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BoulderColorWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  gym?: Maybe<GymWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
};

export enum BoulderColorOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export type BoulderColorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<ColorInput>;
  contrast?: Maybe<ColorInput>;
  description?: Maybe<Scalars['String']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
};

export type BoulderColorUpdateManyInlineInput = {
  /** Create and connect multiple BoulderColor documents */
  create?: Maybe<Array<BoulderColorCreateInput>>;
  /** Connect multiple existing BoulderColor documents */
  connect?: Maybe<Array<BoulderColorConnectInput>>;
  /** Override currently-connected documents with multiple existing BoulderColor documents */
  set?: Maybe<Array<BoulderColorWhereUniqueInput>>;
  /** Update multiple BoulderColor documents */
  update?: Maybe<Array<BoulderColorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BoulderColor documents */
  upsert?: Maybe<Array<BoulderColorUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple BoulderColor documents */
  disconnect?: Maybe<Array<BoulderColorWhereUniqueInput>>;
  /** Delete multiple BoulderColor documents */
  delete?: Maybe<Array<BoulderColorWhereUniqueInput>>;
};

export type BoulderColorUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<ColorInput>;
  contrast?: Maybe<ColorInput>;
  description?: Maybe<Scalars['String']>;
};

export type BoulderColorUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: BoulderColorWhereInput;
  /** Update many input */
  data: BoulderColorUpdateManyInput;
};

export type BoulderColorUpdateOneInlineInput = {
  /** Create and connect one BoulderColor document */
  create?: Maybe<BoulderColorCreateInput>;
  /** Update single BoulderColor document */
  update?: Maybe<BoulderColorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BoulderColor document */
  upsert?: Maybe<BoulderColorUpsertWithNestedWhereUniqueInput>;
  /** Connect existing BoulderColor document */
  connect?: Maybe<BoulderColorWhereUniqueInput>;
  /** Disconnect currently connected BoulderColor document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected BoulderColor document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type BoulderColorUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: BoulderColorWhereUniqueInput;
  /** Document to update */
  data: BoulderColorUpdateInput;
};

export type BoulderColorUpsertInput = {
  /** Create document if it didn't exist */
  create: BoulderColorCreateInput;
  /** Update document if it exists */
  update: BoulderColorUpdateInput;
};

export type BoulderColorUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: BoulderColorWhereUniqueInput;
  /** Upsert data */
  data: BoulderColorUpsertInput;
};

/** Identifies documents */
export type BoulderColorWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BoulderColorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BoulderColorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BoulderColorWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  gym?: Maybe<GymWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
};

/** References BoulderColor record uniquely */
export type BoulderColorWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type BoulderConnectInput = {
  /** Document to connect */
  where: BoulderWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type BoulderConnection = {
  __typename?: 'BoulderConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<BoulderEdge>;
  aggregate: Aggregate;
};

export type BoulderCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  image: AssetCreateOneInlineInput;
  data?: Maybe<Scalars['Json']>;
  gym?: Maybe<GymCreateOneInlineInput>;
  climber?: Maybe<ClimberCreateOneInlineInput>;
  folder?: Maybe<FolderCreateOneInlineInput>;
  color?: Maybe<BoulderColorCreateOneInlineInput>;
  challenges?: Maybe<ChallengeCreateManyInlineInput>;
};

export type BoulderCreateManyInlineInput = {
  /** Create and connect multiple existing Boulder documents */
  create?: Maybe<Array<BoulderCreateInput>>;
  /** Connect multiple existing Boulder documents */
  connect?: Maybe<Array<BoulderWhereUniqueInput>>;
};

export type BoulderCreateOneInlineInput = {
  /** Create and connect one Boulder document */
  create?: Maybe<BoulderCreateInput>;
  /** Connect one existing Boulder document */
  connect?: Maybe<BoulderWhereUniqueInput>;
};

/** An edge in a connection. */
export type BoulderEdge = {
  __typename?: 'BoulderEdge';
  /** The item at the end of the edge. */
  node: Boulder;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type BoulderManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BoulderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BoulderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BoulderWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<AssetWhereInput>;
  gym?: Maybe<GymWhereInput>;
  climber?: Maybe<ClimberWhereInput>;
  folder?: Maybe<FolderWhereInput>;
  color?: Maybe<BoulderColorWhereInput>;
  challenges_every?: Maybe<ChallengeWhereInput>;
  challenges_some?: Maybe<ChallengeWhereInput>;
  challenges_none?: Maybe<ChallengeWhereInput>;
};

export enum BoulderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type BoulderUpdateInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<AssetUpdateOneInlineInput>;
  data?: Maybe<Scalars['Json']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  climber?: Maybe<ClimberUpdateOneInlineInput>;
  folder?: Maybe<FolderUpdateOneInlineInput>;
  color?: Maybe<BoulderColorUpdateOneInlineInput>;
  challenges?: Maybe<ChallengeUpdateManyInlineInput>;
};

export type BoulderUpdateManyInlineInput = {
  /** Create and connect multiple Boulder documents */
  create?: Maybe<Array<BoulderCreateInput>>;
  /** Connect multiple existing Boulder documents */
  connect?: Maybe<Array<BoulderConnectInput>>;
  /** Override currently-connected documents with multiple existing Boulder documents */
  set?: Maybe<Array<BoulderWhereUniqueInput>>;
  /** Update multiple Boulder documents */
  update?: Maybe<Array<BoulderUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Boulder documents */
  upsert?: Maybe<Array<BoulderUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Boulder documents */
  disconnect?: Maybe<Array<BoulderWhereUniqueInput>>;
  /** Delete multiple Boulder documents */
  delete?: Maybe<Array<BoulderWhereUniqueInput>>;
};

export type BoulderUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Json']>;
};

export type BoulderUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: BoulderWhereInput;
  /** Update many input */
  data: BoulderUpdateManyInput;
};

export type BoulderUpdateOneInlineInput = {
  /** Create and connect one Boulder document */
  create?: Maybe<BoulderCreateInput>;
  /** Update single Boulder document */
  update?: Maybe<BoulderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Boulder document */
  upsert?: Maybe<BoulderUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Boulder document */
  connect?: Maybe<BoulderWhereUniqueInput>;
  /** Disconnect currently connected Boulder document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Boulder document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type BoulderUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: BoulderWhereUniqueInput;
  /** Document to update */
  data: BoulderUpdateInput;
};

export type BoulderUpsertInput = {
  /** Create document if it didn't exist */
  create: BoulderCreateInput;
  /** Update document if it exists */
  update: BoulderUpdateInput;
};

export type BoulderUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: BoulderWhereUniqueInput;
  /** Upsert data */
  data: BoulderUpsertInput;
};

/** Identifies documents */
export type BoulderWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BoulderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BoulderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BoulderWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<AssetWhereInput>;
  gym?: Maybe<GymWhereInput>;
  climber?: Maybe<ClimberWhereInput>;
  folder?: Maybe<FolderWhereInput>;
  color?: Maybe<BoulderColorWhereInput>;
  challenges_every?: Maybe<ChallengeWhereInput>;
  challenges_some?: Maybe<ChallengeWhereInput>;
  challenges_none?: Maybe<ChallengeWhereInput>;
};

/** References Boulder record uniquely */
export type BoulderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Challenge = Node & {
  __typename?: 'Challenge';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Challenge>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  climber?: Maybe<Climber>;
  climbers: Array<Climber>;
  goals: Array<ChallengeGoal>;
  boulders: Array<Boulder>;
  progresses: Array<ChallengeProgress>;
  /** List of Challenge versions */
  history: Array<Version>;
};


export type ChallengeDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type ChallengeClimberArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeClimbersArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeGoalsArgs = {
  where?: Maybe<ChallengeGoalWhereInput>;
  orderBy?: Maybe<ChallengeGoalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressWhereInput>;
  orderBy?: Maybe<ChallengeProgressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type ChallengeConnectInput = {
  /** Document to connect */
  where: ChallengeWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type ChallengeConnection = {
  __typename?: 'ChallengeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<ChallengeEdge>;
  aggregate: Aggregate;
};

export type ChallengeCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  climber?: Maybe<ClimberCreateOneInlineInput>;
  climbers?: Maybe<ClimberCreateManyInlineInput>;
  goals?: Maybe<ChallengeGoalCreateManyInlineInput>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
  progresses?: Maybe<ChallengeProgressCreateManyInlineInput>;
};

export type ChallengeCreateManyInlineInput = {
  /** Create and connect multiple existing Challenge documents */
  create?: Maybe<Array<ChallengeCreateInput>>;
  /** Connect multiple existing Challenge documents */
  connect?: Maybe<Array<ChallengeWhereUniqueInput>>;
};

export type ChallengeCreateOneInlineInput = {
  /** Create and connect one Challenge document */
  create?: Maybe<ChallengeCreateInput>;
  /** Connect one existing Challenge document */
  connect?: Maybe<ChallengeWhereUniqueInput>;
};

/** An edge in a connection. */
export type ChallengeEdge = {
  __typename?: 'ChallengeEdge';
  /** The item at the end of the edge. */
  node: Challenge;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type ChallengeGoal = Node & {
  __typename?: 'ChallengeGoal';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<ChallengeGoal>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  goal: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  challenge?: Maybe<Challenge>;
  progresses: Array<ChallengeProgress>;
  /** List of ChallengeGoal versions */
  history: Array<Version>;
};


export type ChallengeGoalDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type ChallengeGoalChallengeArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeGoalProgressesArgs = {
  where?: Maybe<ChallengeProgressWhereInput>;
  orderBy?: Maybe<ChallengeProgressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeGoalHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type ChallengeGoalConnectInput = {
  /** Document to connect */
  where: ChallengeGoalWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type ChallengeGoalConnection = {
  __typename?: 'ChallengeGoalConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<ChallengeGoalEdge>;
  aggregate: Aggregate;
};

export type ChallengeGoalCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  goal: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  challenge?: Maybe<ChallengeCreateOneInlineInput>;
  progresses?: Maybe<ChallengeProgressCreateManyInlineInput>;
};

export type ChallengeGoalCreateManyInlineInput = {
  /** Create and connect multiple existing ChallengeGoal documents */
  create?: Maybe<Array<ChallengeGoalCreateInput>>;
  /** Connect multiple existing ChallengeGoal documents */
  connect?: Maybe<Array<ChallengeGoalWhereUniqueInput>>;
};

export type ChallengeGoalCreateOneInlineInput = {
  /** Create and connect one ChallengeGoal document */
  create?: Maybe<ChallengeGoalCreateInput>;
  /** Connect one existing ChallengeGoal document */
  connect?: Maybe<ChallengeGoalWhereUniqueInput>;
};

/** An edge in a connection. */
export type ChallengeGoalEdge = {
  __typename?: 'ChallengeGoalEdge';
  /** The item at the end of the edge. */
  node: ChallengeGoal;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type ChallengeGoalManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeGoalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeGoalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeGoalWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  goal_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  goal_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  goal_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  goal_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  goal_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  goal_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  goal_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  deadline_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  deadline_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  deadline_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  deadline_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  deadline_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  deadline_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  deadline_gte?: Maybe<Scalars['DateTime']>;
  challenge?: Maybe<ChallengeWhereInput>;
  progresses_every?: Maybe<ChallengeProgressWhereInput>;
  progresses_some?: Maybe<ChallengeProgressWhereInput>;
  progresses_none?: Maybe<ChallengeProgressWhereInput>;
};

export enum ChallengeGoalOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  GoalAsc = 'goal_ASC',
  GoalDesc = 'goal_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  DeadlineAsc = 'deadline_ASC',
  DeadlineDesc = 'deadline_DESC'
}

export type ChallengeGoalUpdateInput = {
  goal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  challenge?: Maybe<ChallengeUpdateOneInlineInput>;
  progresses?: Maybe<ChallengeProgressUpdateManyInlineInput>;
};

export type ChallengeGoalUpdateManyInlineInput = {
  /** Create and connect multiple ChallengeGoal documents */
  create?: Maybe<Array<ChallengeGoalCreateInput>>;
  /** Connect multiple existing ChallengeGoal documents */
  connect?: Maybe<Array<ChallengeGoalConnectInput>>;
  /** Override currently-connected documents with multiple existing ChallengeGoal documents */
  set?: Maybe<Array<ChallengeGoalWhereUniqueInput>>;
  /** Update multiple ChallengeGoal documents */
  update?: Maybe<Array<ChallengeGoalUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ChallengeGoal documents */
  upsert?: Maybe<Array<ChallengeGoalUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple ChallengeGoal documents */
  disconnect?: Maybe<Array<ChallengeGoalWhereUniqueInput>>;
  /** Delete multiple ChallengeGoal documents */
  delete?: Maybe<Array<ChallengeGoalWhereUniqueInput>>;
};

export type ChallengeGoalUpdateManyInput = {
  goal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
};

export type ChallengeGoalUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ChallengeGoalWhereInput;
  /** Update many input */
  data: ChallengeGoalUpdateManyInput;
};

export type ChallengeGoalUpdateOneInlineInput = {
  /** Create and connect one ChallengeGoal document */
  create?: Maybe<ChallengeGoalCreateInput>;
  /** Update single ChallengeGoal document */
  update?: Maybe<ChallengeGoalUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ChallengeGoal document */
  upsert?: Maybe<ChallengeGoalUpsertWithNestedWhereUniqueInput>;
  /** Connect existing ChallengeGoal document */
  connect?: Maybe<ChallengeGoalWhereUniqueInput>;
  /** Disconnect currently connected ChallengeGoal document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected ChallengeGoal document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type ChallengeGoalUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeGoalWhereUniqueInput;
  /** Document to update */
  data: ChallengeGoalUpdateInput;
};

export type ChallengeGoalUpsertInput = {
  /** Create document if it didn't exist */
  create: ChallengeGoalCreateInput;
  /** Update document if it exists */
  update: ChallengeGoalUpdateInput;
};

export type ChallengeGoalUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeGoalWhereUniqueInput;
  /** Upsert data */
  data: ChallengeGoalUpsertInput;
};

/** Identifies documents */
export type ChallengeGoalWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeGoalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeGoalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeGoalWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  goal_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  goal_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  goal_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  goal_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  goal_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  goal_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  goal_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  deadline_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  deadline_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  deadline_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  deadline_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  deadline_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  deadline_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  deadline_gte?: Maybe<Scalars['DateTime']>;
  challenge?: Maybe<ChallengeWhereInput>;
  progresses_every?: Maybe<ChallengeProgressWhereInput>;
  progresses_some?: Maybe<ChallengeProgressWhereInput>;
  progresses_none?: Maybe<ChallengeProgressWhereInput>;
};

/** References ChallengeGoal record uniquely */
export type ChallengeGoalWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Identifies documents */
export type ChallengeManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  deadline_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  deadline_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  deadline_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  deadline_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  deadline_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  deadline_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  deadline_gte?: Maybe<Scalars['DateTime']>;
  climber?: Maybe<ClimberWhereInput>;
  climbers_every?: Maybe<ClimberWhereInput>;
  climbers_some?: Maybe<ClimberWhereInput>;
  climbers_none?: Maybe<ClimberWhereInput>;
  goals_every?: Maybe<ChallengeGoalWhereInput>;
  goals_some?: Maybe<ChallengeGoalWhereInput>;
  goals_none?: Maybe<ChallengeGoalWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  progresses_every?: Maybe<ChallengeProgressWhereInput>;
  progresses_some?: Maybe<ChallengeProgressWhereInput>;
  progresses_none?: Maybe<ChallengeProgressWhereInput>;
};

export enum ChallengeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  DeadlineAsc = 'deadline_ASC',
  DeadlineDesc = 'deadline_DESC'
}

export type ChallengeProgress = Node & {
  __typename?: 'ChallengeProgress';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<ChallengeProgress>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  progress: Scalars['Int'];
  challenge?: Maybe<Challenge>;
  climber?: Maybe<Climber>;
  goal?: Maybe<ChallengeGoal>;
  /** List of ChallengeProgress versions */
  history: Array<Version>;
};


export type ChallengeProgressDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type ChallengeProgressChallengeArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeProgressClimberArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeProgressGoalArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ChallengeProgressHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type ChallengeProgressConnectInput = {
  /** Document to connect */
  where: ChallengeProgressWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type ChallengeProgressConnection = {
  __typename?: 'ChallengeProgressConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<ChallengeProgressEdge>;
  aggregate: Aggregate;
};

export type ChallengeProgressCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  progress: Scalars['Int'];
  challenge?: Maybe<ChallengeCreateOneInlineInput>;
  climber?: Maybe<ClimberCreateOneInlineInput>;
  goal?: Maybe<ChallengeGoalCreateOneInlineInput>;
};

export type ChallengeProgressCreateManyInlineInput = {
  /** Create and connect multiple existing ChallengeProgress documents */
  create?: Maybe<Array<ChallengeProgressCreateInput>>;
  /** Connect multiple existing ChallengeProgress documents */
  connect?: Maybe<Array<ChallengeProgressWhereUniqueInput>>;
};

export type ChallengeProgressCreateOneInlineInput = {
  /** Create and connect one ChallengeProgress document */
  create?: Maybe<ChallengeProgressCreateInput>;
  /** Connect one existing ChallengeProgress document */
  connect?: Maybe<ChallengeProgressWhereUniqueInput>;
};

/** An edge in a connection. */
export type ChallengeProgressEdge = {
  __typename?: 'ChallengeProgressEdge';
  /** The item at the end of the edge. */
  node: ChallengeProgress;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type ChallengeProgressManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeProgressWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeProgressWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeProgressWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  progress?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  progress_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  progress_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  progress_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  progress_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  progress_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  progress_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  progress_gte?: Maybe<Scalars['Int']>;
  challenge?: Maybe<ChallengeWhereInput>;
  climber?: Maybe<ClimberWhereInput>;
  goal?: Maybe<ChallengeGoalWhereInput>;
};

export enum ChallengeProgressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ProgressAsc = 'progress_ASC',
  ProgressDesc = 'progress_DESC'
}

export type ChallengeProgressUpdateInput = {
  progress?: Maybe<Scalars['Int']>;
  challenge?: Maybe<ChallengeUpdateOneInlineInput>;
  climber?: Maybe<ClimberUpdateOneInlineInput>;
  goal?: Maybe<ChallengeGoalUpdateOneInlineInput>;
};

export type ChallengeProgressUpdateManyInlineInput = {
  /** Create and connect multiple ChallengeProgress documents */
  create?: Maybe<Array<ChallengeProgressCreateInput>>;
  /** Connect multiple existing ChallengeProgress documents */
  connect?: Maybe<Array<ChallengeProgressConnectInput>>;
  /** Override currently-connected documents with multiple existing ChallengeProgress documents */
  set?: Maybe<Array<ChallengeProgressWhereUniqueInput>>;
  /** Update multiple ChallengeProgress documents */
  update?: Maybe<Array<ChallengeProgressUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ChallengeProgress documents */
  upsert?: Maybe<Array<ChallengeProgressUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple ChallengeProgress documents */
  disconnect?: Maybe<Array<ChallengeProgressWhereUniqueInput>>;
  /** Delete multiple ChallengeProgress documents */
  delete?: Maybe<Array<ChallengeProgressWhereUniqueInput>>;
};

export type ChallengeProgressUpdateManyInput = {
  progress?: Maybe<Scalars['Int']>;
};

export type ChallengeProgressUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ChallengeProgressWhereInput;
  /** Update many input */
  data: ChallengeProgressUpdateManyInput;
};

export type ChallengeProgressUpdateOneInlineInput = {
  /** Create and connect one ChallengeProgress document */
  create?: Maybe<ChallengeProgressCreateInput>;
  /** Update single ChallengeProgress document */
  update?: Maybe<ChallengeProgressUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ChallengeProgress document */
  upsert?: Maybe<ChallengeProgressUpsertWithNestedWhereUniqueInput>;
  /** Connect existing ChallengeProgress document */
  connect?: Maybe<ChallengeProgressWhereUniqueInput>;
  /** Disconnect currently connected ChallengeProgress document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected ChallengeProgress document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type ChallengeProgressUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeProgressWhereUniqueInput;
  /** Document to update */
  data: ChallengeProgressUpdateInput;
};

export type ChallengeProgressUpsertInput = {
  /** Create document if it didn't exist */
  create: ChallengeProgressCreateInput;
  /** Update document if it exists */
  update: ChallengeProgressUpdateInput;
};

export type ChallengeProgressUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeProgressWhereUniqueInput;
  /** Upsert data */
  data: ChallengeProgressUpsertInput;
};

/** Identifies documents */
export type ChallengeProgressWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeProgressWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeProgressWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeProgressWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  progress?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  progress_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  progress_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  progress_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  progress_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  progress_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  progress_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  progress_gte?: Maybe<Scalars['Int']>;
  challenge?: Maybe<ChallengeWhereInput>;
  climber?: Maybe<ClimberWhereInput>;
  goal?: Maybe<ChallengeGoalWhereInput>;
};

/** References ChallengeProgress record uniquely */
export type ChallengeProgressWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ChallengeUpdateInput = {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  climber?: Maybe<ClimberUpdateOneInlineInput>;
  climbers?: Maybe<ClimberUpdateManyInlineInput>;
  goals?: Maybe<ChallengeGoalUpdateManyInlineInput>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
  progresses?: Maybe<ChallengeProgressUpdateManyInlineInput>;
};

export type ChallengeUpdateManyInlineInput = {
  /** Create and connect multiple Challenge documents */
  create?: Maybe<Array<ChallengeCreateInput>>;
  /** Connect multiple existing Challenge documents */
  connect?: Maybe<Array<ChallengeConnectInput>>;
  /** Override currently-connected documents with multiple existing Challenge documents */
  set?: Maybe<Array<ChallengeWhereUniqueInput>>;
  /** Update multiple Challenge documents */
  update?: Maybe<Array<ChallengeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Challenge documents */
  upsert?: Maybe<Array<ChallengeUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Challenge documents */
  disconnect?: Maybe<Array<ChallengeWhereUniqueInput>>;
  /** Delete multiple Challenge documents */
  delete?: Maybe<Array<ChallengeWhereUniqueInput>>;
};

export type ChallengeUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
};

export type ChallengeUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ChallengeWhereInput;
  /** Update many input */
  data: ChallengeUpdateManyInput;
};

export type ChallengeUpdateOneInlineInput = {
  /** Create and connect one Challenge document */
  create?: Maybe<ChallengeCreateInput>;
  /** Update single Challenge document */
  update?: Maybe<ChallengeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Challenge document */
  upsert?: Maybe<ChallengeUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Challenge document */
  connect?: Maybe<ChallengeWhereUniqueInput>;
  /** Disconnect currently connected Challenge document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Challenge document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type ChallengeUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeWhereUniqueInput;
  /** Document to update */
  data: ChallengeUpdateInput;
};

export type ChallengeUpsertInput = {
  /** Create document if it didn't exist */
  create: ChallengeCreateInput;
  /** Update document if it exists */
  update: ChallengeUpdateInput;
};

export type ChallengeUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ChallengeWhereUniqueInput;
  /** Upsert data */
  data: ChallengeUpsertInput;
};

/** Identifies documents */
export type ChallengeWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChallengeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChallengeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChallengeWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  deadline_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  deadline_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  deadline_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  deadline_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  deadline_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  deadline_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  deadline_gte?: Maybe<Scalars['DateTime']>;
  climber?: Maybe<ClimberWhereInput>;
  climbers_every?: Maybe<ClimberWhereInput>;
  climbers_some?: Maybe<ClimberWhereInput>;
  climbers_none?: Maybe<ClimberWhereInput>;
  goals_every?: Maybe<ChallengeGoalWhereInput>;
  goals_some?: Maybe<ChallengeGoalWhereInput>;
  goals_none?: Maybe<ChallengeGoalWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  progresses_every?: Maybe<ChallengeProgressWhereInput>;
  progresses_some?: Maybe<ChallengeProgressWhereInput>;
  progresses_none?: Maybe<ChallengeProgressWhereInput>;
};

/** References Challenge record uniquely */
export type ChallengeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Climber = Node & {
  __typename?: 'Climber';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Climber>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: ClimberRole;
  type: ClimberType;
  avatar?: Maybe<Asset>;
  boulders: Array<Boulder>;
  courses: Array<Course>;
  folders: Array<Folder>;
  challengesCreator: Array<Challenge>;
  challengesParticipant: Array<Challenge>;
  challengeProgresses: Array<ChallengeProgress>;
  gymsInstructor: Array<Gym>;
  /** List of Climber versions */
  history: Array<Version>;
};


export type ClimberDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type ClimberAvatarArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type ClimberBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberCoursesArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberFoldersArgs = {
  where?: Maybe<FolderWhereInput>;
  orderBy?: Maybe<FolderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberChallengesCreatorArgs = {
  where?: Maybe<ChallengeWhereInput>;
  orderBy?: Maybe<ChallengeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberChallengesParticipantArgs = {
  where?: Maybe<ChallengeWhereInput>;
  orderBy?: Maybe<ChallengeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberChallengeProgressesArgs = {
  where?: Maybe<ChallengeProgressWhereInput>;
  orderBy?: Maybe<ChallengeProgressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberGymsInstructorArgs = {
  where?: Maybe<GymWhereInput>;
  orderBy?: Maybe<GymOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type ClimberHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type ClimberConnectInput = {
  /** Document to connect */
  where: ClimberWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type ClimberConnection = {
  __typename?: 'ClimberConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<ClimberEdge>;
  aggregate: Aggregate;
};

export type ClimberCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: ClimberRole;
  type: ClimberType;
  avatar?: Maybe<AssetCreateOneInlineInput>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
  courses?: Maybe<CourseCreateManyInlineInput>;
  folders?: Maybe<FolderCreateManyInlineInput>;
  challengesCreator?: Maybe<ChallengeCreateManyInlineInput>;
  challengesParticipant?: Maybe<ChallengeCreateManyInlineInput>;
  challengeProgresses?: Maybe<ChallengeProgressCreateManyInlineInput>;
  gymsInstructor?: Maybe<GymCreateManyInlineInput>;
};

export type ClimberCreateManyInlineInput = {
  /** Create and connect multiple existing Climber documents */
  create?: Maybe<Array<ClimberCreateInput>>;
  /** Connect multiple existing Climber documents */
  connect?: Maybe<Array<ClimberWhereUniqueInput>>;
};

export type ClimberCreateOneInlineInput = {
  /** Create and connect one Climber document */
  create?: Maybe<ClimberCreateInput>;
  /** Connect one existing Climber document */
  connect?: Maybe<ClimberWhereUniqueInput>;
};

/** An edge in a connection. */
export type ClimberEdge = {
  __typename?: 'ClimberEdge';
  /** The item at the end of the edge. */
  node: Climber;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type ClimberManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ClimberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ClimberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ClimberWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  email_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  password_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  password_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  password_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  password_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  password_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  password_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  password_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  password_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  password_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<ClimberRole>;
  /** All values that are not equal to given value. */
  role_not?: Maybe<ClimberRole>;
  /** All values that are contained in given list. */
  role_in?: Maybe<Array<ClimberRole>>;
  /** All values that are not contained in given list. */
  role_not_in?: Maybe<Array<ClimberRole>>;
  type?: Maybe<ClimberType>;
  /** All values that are not equal to given value. */
  type_not?: Maybe<ClimberType>;
  /** All values that are contained in given list. */
  type_in?: Maybe<Array<ClimberType>>;
  /** All values that are not contained in given list. */
  type_not_in?: Maybe<Array<ClimberType>>;
  avatar?: Maybe<AssetWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  folders_every?: Maybe<FolderWhereInput>;
  folders_some?: Maybe<FolderWhereInput>;
  folders_none?: Maybe<FolderWhereInput>;
  challengesCreator_every?: Maybe<ChallengeWhereInput>;
  challengesCreator_some?: Maybe<ChallengeWhereInput>;
  challengesCreator_none?: Maybe<ChallengeWhereInput>;
  challengesParticipant_every?: Maybe<ChallengeWhereInput>;
  challengesParticipant_some?: Maybe<ChallengeWhereInput>;
  challengesParticipant_none?: Maybe<ChallengeWhereInput>;
  challengeProgresses_every?: Maybe<ChallengeProgressWhereInput>;
  challengeProgresses_some?: Maybe<ChallengeProgressWhereInput>;
  challengeProgresses_none?: Maybe<ChallengeProgressWhereInput>;
  gymsInstructor_every?: Maybe<GymWhereInput>;
  gymsInstructor_some?: Maybe<GymWhereInput>;
  gymsInstructor_none?: Maybe<GymWhereInput>;
};

export enum ClimberOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

export enum ClimberRole {
  Admin = 'admin',
  User = 'user'
}

export enum ClimberType {
  Climber = 'climber',
  Coach = 'coach',
  Instructor = 'instructor',
  Student = 'student'
}

export type ClimberUpdateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<ClimberRole>;
  type?: Maybe<ClimberType>;
  avatar?: Maybe<AssetUpdateOneInlineInput>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
  courses?: Maybe<CourseUpdateManyInlineInput>;
  folders?: Maybe<FolderUpdateManyInlineInput>;
  challengesCreator?: Maybe<ChallengeUpdateManyInlineInput>;
  challengesParticipant?: Maybe<ChallengeUpdateManyInlineInput>;
  challengeProgresses?: Maybe<ChallengeProgressUpdateManyInlineInput>;
  gymsInstructor?: Maybe<GymUpdateManyInlineInput>;
};

export type ClimberUpdateManyInlineInput = {
  /** Create and connect multiple Climber documents */
  create?: Maybe<Array<ClimberCreateInput>>;
  /** Connect multiple existing Climber documents */
  connect?: Maybe<Array<ClimberConnectInput>>;
  /** Override currently-connected documents with multiple existing Climber documents */
  set?: Maybe<Array<ClimberWhereUniqueInput>>;
  /** Update multiple Climber documents */
  update?: Maybe<Array<ClimberUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Climber documents */
  upsert?: Maybe<Array<ClimberUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Climber documents */
  disconnect?: Maybe<Array<ClimberWhereUniqueInput>>;
  /** Delete multiple Climber documents */
  delete?: Maybe<Array<ClimberWhereUniqueInput>>;
};

export type ClimberUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<ClimberRole>;
  type?: Maybe<ClimberType>;
};

export type ClimberUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ClimberWhereInput;
  /** Update many input */
  data: ClimberUpdateManyInput;
};

export type ClimberUpdateOneInlineInput = {
  /** Create and connect one Climber document */
  create?: Maybe<ClimberCreateInput>;
  /** Update single Climber document */
  update?: Maybe<ClimberUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Climber document */
  upsert?: Maybe<ClimberUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Climber document */
  connect?: Maybe<ClimberWhereUniqueInput>;
  /** Disconnect currently connected Climber document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Climber document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type ClimberUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ClimberWhereUniqueInput;
  /** Document to update */
  data: ClimberUpdateInput;
};

export type ClimberUpsertInput = {
  /** Create document if it didn't exist */
  create: ClimberCreateInput;
  /** Update document if it exists */
  update: ClimberUpdateInput;
};

export type ClimberUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ClimberWhereUniqueInput;
  /** Upsert data */
  data: ClimberUpsertInput;
};

/** Identifies documents */
export type ClimberWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ClimberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ClimberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ClimberWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  email_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  password_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  password_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  password_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  password_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  password_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  password_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  password_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  password_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  password_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<ClimberRole>;
  /** All values that are not equal to given value. */
  role_not?: Maybe<ClimberRole>;
  /** All values that are contained in given list. */
  role_in?: Maybe<Array<ClimberRole>>;
  /** All values that are not contained in given list. */
  role_not_in?: Maybe<Array<ClimberRole>>;
  type?: Maybe<ClimberType>;
  /** All values that are not equal to given value. */
  type_not?: Maybe<ClimberType>;
  /** All values that are contained in given list. */
  type_in?: Maybe<Array<ClimberType>>;
  /** All values that are not contained in given list. */
  type_not_in?: Maybe<Array<ClimberType>>;
  avatar?: Maybe<AssetWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  folders_every?: Maybe<FolderWhereInput>;
  folders_some?: Maybe<FolderWhereInput>;
  folders_none?: Maybe<FolderWhereInput>;
  challengesCreator_every?: Maybe<ChallengeWhereInput>;
  challengesCreator_some?: Maybe<ChallengeWhereInput>;
  challengesCreator_none?: Maybe<ChallengeWhereInput>;
  challengesParticipant_every?: Maybe<ChallengeWhereInput>;
  challengesParticipant_some?: Maybe<ChallengeWhereInput>;
  challengesParticipant_none?: Maybe<ChallengeWhereInput>;
  challengeProgresses_every?: Maybe<ChallengeProgressWhereInput>;
  challengeProgresses_some?: Maybe<ChallengeProgressWhereInput>;
  challengeProgresses_none?: Maybe<ChallengeProgressWhereInput>;
  gymsInstructor_every?: Maybe<GymWhereInput>;
  gymsInstructor_some?: Maybe<GymWhereInput>;
  gymsInstructor_none?: Maybe<GymWhereInput>;
};

/** References Climber record uniquely */
export type ClimberWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color';
  hex: Scalars['Hex'];
  rgba: Rgba;
  css: Scalars['String'];
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: Maybe<Scalars['Hex']>;
  rgba?: Maybe<RgbaInput>;
};

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: Maybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: Maybe<Scalars['ID']>;
  /** Connect document at first position */
  start?: Maybe<Scalars['Boolean']>;
  /** Connect document at last position */
  end?: Maybe<Scalars['Boolean']>;
};

export type Course = Node & {
  __typename?: 'Course';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Course>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  color: Color;
  /** Only time part is important. */
  startTime: Scalars['DateTime'];
  /** Only time part is important */
  endTime: Scalars['DateTime'];
  gym?: Maybe<Gym>;
  day?: Maybe<Day>;
  season?: Maybe<Season>;
  instructors: Array<Climber>;
  students: Array<Student>;
  /** List of Course versions */
  history: Array<Version>;
};


export type CourseDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type CourseGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type CourseDayArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type CourseSeasonArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type CourseInstructorsArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type CourseStudentsArgs = {
  where?: Maybe<StudentWhereInput>;
  orderBy?: Maybe<StudentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type CourseHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type CourseConnectInput = {
  /** Document to connect */
  where: CourseWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type CourseConnection = {
  __typename?: 'CourseConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<CourseEdge>;
  aggregate: Aggregate;
};

export type CourseCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  color: ColorInput;
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  gym?: Maybe<GymCreateOneInlineInput>;
  day?: Maybe<DayCreateOneInlineInput>;
  season?: Maybe<SeasonCreateOneInlineInput>;
  instructors?: Maybe<ClimberCreateManyInlineInput>;
  students?: Maybe<StudentCreateManyInlineInput>;
};

export type CourseCreateManyInlineInput = {
  /** Create and connect multiple existing Course documents */
  create?: Maybe<Array<CourseCreateInput>>;
  /** Connect multiple existing Course documents */
  connect?: Maybe<Array<CourseWhereUniqueInput>>;
};

export type CourseCreateOneInlineInput = {
  /** Create and connect one Course document */
  create?: Maybe<CourseCreateInput>;
  /** Connect one existing Course document */
  connect?: Maybe<CourseWhereUniqueInput>;
};

/** An edge in a connection. */
export type CourseEdge = {
  __typename?: 'CourseEdge';
  /** The item at the end of the edge. */
  node: Course;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type CourseManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CourseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CourseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CourseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  startTime_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  startTime_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  startTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  startTime_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  startTime_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  startTime_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  startTime_gte?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  endTime_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  endTime_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  endTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  endTime_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  endTime_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  endTime_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  endTime_gte?: Maybe<Scalars['DateTime']>;
  gym?: Maybe<GymWhereInput>;
  day?: Maybe<DayWhereInput>;
  season?: Maybe<SeasonWhereInput>;
  instructors_every?: Maybe<ClimberWhereInput>;
  instructors_some?: Maybe<ClimberWhereInput>;
  instructors_none?: Maybe<ClimberWhereInput>;
  students_every?: Maybe<StudentWhereInput>;
  students_some?: Maybe<StudentWhereInput>;
  students_none?: Maybe<StudentWhereInput>;
};

export enum CourseOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC'
}

export type CourseUpdateInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<ColorInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  day?: Maybe<DayUpdateOneInlineInput>;
  season?: Maybe<SeasonUpdateOneInlineInput>;
  instructors?: Maybe<ClimberUpdateManyInlineInput>;
  students?: Maybe<StudentUpdateManyInlineInput>;
};

export type CourseUpdateManyInlineInput = {
  /** Create and connect multiple Course documents */
  create?: Maybe<Array<CourseCreateInput>>;
  /** Connect multiple existing Course documents */
  connect?: Maybe<Array<CourseConnectInput>>;
  /** Override currently-connected documents with multiple existing Course documents */
  set?: Maybe<Array<CourseWhereUniqueInput>>;
  /** Update multiple Course documents */
  update?: Maybe<Array<CourseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Course documents */
  upsert?: Maybe<Array<CourseUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Course documents */
  disconnect?: Maybe<Array<CourseWhereUniqueInput>>;
  /** Delete multiple Course documents */
  delete?: Maybe<Array<CourseWhereUniqueInput>>;
};

export type CourseUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<ColorInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type CourseUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: CourseWhereInput;
  /** Update many input */
  data: CourseUpdateManyInput;
};

export type CourseUpdateOneInlineInput = {
  /** Create and connect one Course document */
  create?: Maybe<CourseCreateInput>;
  /** Update single Course document */
  update?: Maybe<CourseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Course document */
  upsert?: Maybe<CourseUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Course document */
  connect?: Maybe<CourseWhereUniqueInput>;
  /** Disconnect currently connected Course document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Course document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type CourseUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: CourseWhereUniqueInput;
  /** Document to update */
  data: CourseUpdateInput;
};

export type CourseUpsertInput = {
  /** Create document if it didn't exist */
  create: CourseCreateInput;
  /** Update document if it exists */
  update: CourseUpdateInput;
};

export type CourseUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: CourseWhereUniqueInput;
  /** Upsert data */
  data: CourseUpsertInput;
};

/** Identifies documents */
export type CourseWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CourseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CourseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CourseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  startTime_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  startTime_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  startTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  startTime_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  startTime_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  startTime_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  startTime_gte?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  endTime_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  endTime_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  endTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  endTime_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  endTime_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  endTime_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  endTime_gte?: Maybe<Scalars['DateTime']>;
  gym?: Maybe<GymWhereInput>;
  day?: Maybe<DayWhereInput>;
  season?: Maybe<SeasonWhereInput>;
  instructors_every?: Maybe<ClimberWhereInput>;
  instructors_some?: Maybe<ClimberWhereInput>;
  instructors_none?: Maybe<ClimberWhereInput>;
  students_every?: Maybe<StudentWhereInput>;
  students_some?: Maybe<StudentWhereInput>;
  students_none?: Maybe<StudentWhereInput>;
};

/** References Course record uniquely */
export type CourseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};



export type Day = Node & {
  __typename?: 'Day';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Day>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  gym?: Maybe<Gym>;
  season?: Maybe<Season>;
  courses: Array<Course>;
  /** List of Day versions */
  history: Array<Version>;
};


export type DayDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type DayGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type DaySeasonArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type DayCoursesArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type DayHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type DayConnectInput = {
  /** Document to connect */
  where: DayWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type DayConnection = {
  __typename?: 'DayConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<DayEdge>;
  aggregate: Aggregate;
};

export type DayCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  gym?: Maybe<GymCreateOneInlineInput>;
  season?: Maybe<SeasonCreateOneInlineInput>;
  courses?: Maybe<CourseCreateManyInlineInput>;
};

export type DayCreateManyInlineInput = {
  /** Create and connect multiple existing Day documents */
  create?: Maybe<Array<DayCreateInput>>;
  /** Connect multiple existing Day documents */
  connect?: Maybe<Array<DayWhereUniqueInput>>;
};

export type DayCreateOneInlineInput = {
  /** Create and connect one Day document */
  create?: Maybe<DayCreateInput>;
  /** Connect one existing Day document */
  connect?: Maybe<DayWhereUniqueInput>;
};

/** An edge in a connection. */
export type DayEdge = {
  __typename?: 'DayEdge';
  /** The item at the end of the edge. */
  node: Day;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type DayManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<DayWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<DayWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<DayWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  order_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymWhereInput>;
  season?: Maybe<SeasonWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
};

export enum DayOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC'
}

export type DayUpdateInput = {
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  season?: Maybe<SeasonUpdateOneInlineInput>;
  courses?: Maybe<CourseUpdateManyInlineInput>;
};

export type DayUpdateManyInlineInput = {
  /** Create and connect multiple Day documents */
  create?: Maybe<Array<DayCreateInput>>;
  /** Connect multiple existing Day documents */
  connect?: Maybe<Array<DayConnectInput>>;
  /** Override currently-connected documents with multiple existing Day documents */
  set?: Maybe<Array<DayWhereUniqueInput>>;
  /** Update multiple Day documents */
  update?: Maybe<Array<DayUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Day documents */
  upsert?: Maybe<Array<DayUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Day documents */
  disconnect?: Maybe<Array<DayWhereUniqueInput>>;
  /** Delete multiple Day documents */
  delete?: Maybe<Array<DayWhereUniqueInput>>;
};

export type DayUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type DayUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: DayWhereInput;
  /** Update many input */
  data: DayUpdateManyInput;
};

export type DayUpdateOneInlineInput = {
  /** Create and connect one Day document */
  create?: Maybe<DayCreateInput>;
  /** Update single Day document */
  update?: Maybe<DayUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Day document */
  upsert?: Maybe<DayUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Day document */
  connect?: Maybe<DayWhereUniqueInput>;
  /** Disconnect currently connected Day document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Day document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type DayUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: DayWhereUniqueInput;
  /** Document to update */
  data: DayUpdateInput;
};

export type DayUpsertInput = {
  /** Create document if it didn't exist */
  create: DayCreateInput;
  /** Update document if it exists */
  update: DayUpdateInput;
};

export type DayUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: DayWhereUniqueInput;
  /** Upsert data */
  data: DayUpsertInput;
};

/** Identifies documents */
export type DayWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<DayWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<DayWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<DayWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  order_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymWhereInput>;
  season?: Maybe<SeasonWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
};

/** References Day record uniquely */
export type DayWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum Days {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export enum DocumentFileTypes {
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Png = 'png',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Docx = 'docx',
  Pdf = 'pdf',
  Html = 'html',
  Doc = 'doc',
  Xlsx = 'xlsx',
  Xls = 'xls',
  Pptx = 'pptx',
  Ppt = 'ppt'
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   * 
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: Maybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: Maybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  id: Scalars['ID'];
  stage: Stage;
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['Json']>;
};

export type Folder = Node & {
  __typename?: 'Folder';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Folder>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  root: Scalars['Boolean'];
  climber?: Maybe<Climber>;
  parent?: Maybe<Folder>;
  folders: Array<Folder>;
  boulders: Array<Boulder>;
  /** List of Folder versions */
  history: Array<Version>;
};


export type FolderDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type FolderClimberArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type FolderParentArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type FolderFoldersArgs = {
  where?: Maybe<FolderWhereInput>;
  orderBy?: Maybe<FolderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type FolderBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type FolderHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type FolderConnectInput = {
  /** Document to connect */
  where: FolderWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type FolderConnection = {
  __typename?: 'FolderConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<FolderEdge>;
  aggregate: Aggregate;
};

export type FolderCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  root: Scalars['Boolean'];
  climber?: Maybe<ClimberCreateOneInlineInput>;
  parent?: Maybe<FolderCreateOneInlineInput>;
  folders?: Maybe<FolderCreateManyInlineInput>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
};

export type FolderCreateManyInlineInput = {
  /** Create and connect multiple existing Folder documents */
  create?: Maybe<Array<FolderCreateInput>>;
  /** Connect multiple existing Folder documents */
  connect?: Maybe<Array<FolderWhereUniqueInput>>;
};

export type FolderCreateOneInlineInput = {
  /** Create and connect one Folder document */
  create?: Maybe<FolderCreateInput>;
  /** Connect one existing Folder document */
  connect?: Maybe<FolderWhereUniqueInput>;
};

/** An edge in a connection. */
export type FolderEdge = {
  __typename?: 'FolderEdge';
  /** The item at the end of the edge. */
  node: Folder;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type FolderManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<FolderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<FolderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<FolderWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  root_not?: Maybe<Scalars['Boolean']>;
  climber?: Maybe<ClimberWhereInput>;
  parent?: Maybe<FolderWhereInput>;
  folders_every?: Maybe<FolderWhereInput>;
  folders_some?: Maybe<FolderWhereInput>;
  folders_none?: Maybe<FolderWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
};

export enum FolderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RootAsc = 'root_ASC',
  RootDesc = 'root_DESC'
}

export type FolderUpdateInput = {
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
  climber?: Maybe<ClimberUpdateOneInlineInput>;
  parent?: Maybe<FolderUpdateOneInlineInput>;
  folders?: Maybe<FolderUpdateManyInlineInput>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
};

export type FolderUpdateManyInlineInput = {
  /** Create and connect multiple Folder documents */
  create?: Maybe<Array<FolderCreateInput>>;
  /** Connect multiple existing Folder documents */
  connect?: Maybe<Array<FolderConnectInput>>;
  /** Override currently-connected documents with multiple existing Folder documents */
  set?: Maybe<Array<FolderWhereUniqueInput>>;
  /** Update multiple Folder documents */
  update?: Maybe<Array<FolderUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Folder documents */
  upsert?: Maybe<Array<FolderUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Folder documents */
  disconnect?: Maybe<Array<FolderWhereUniqueInput>>;
  /** Delete multiple Folder documents */
  delete?: Maybe<Array<FolderWhereUniqueInput>>;
};

export type FolderUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
};

export type FolderUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: FolderWhereInput;
  /** Update many input */
  data: FolderUpdateManyInput;
};

export type FolderUpdateOneInlineInput = {
  /** Create and connect one Folder document */
  create?: Maybe<FolderCreateInput>;
  /** Update single Folder document */
  update?: Maybe<FolderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Folder document */
  upsert?: Maybe<FolderUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Folder document */
  connect?: Maybe<FolderWhereUniqueInput>;
  /** Disconnect currently connected Folder document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Folder document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type FolderUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: FolderWhereUniqueInput;
  /** Document to update */
  data: FolderUpdateInput;
};

export type FolderUpsertInput = {
  /** Create document if it didn't exist */
  create: FolderCreateInput;
  /** Update document if it exists */
  update: FolderUpdateInput;
};

export type FolderUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: FolderWhereUniqueInput;
  /** Upsert data */
  data: FolderUpsertInput;
};

/** Identifies documents */
export type FolderWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<FolderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<FolderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<FolderWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  root_not?: Maybe<Scalars['Boolean']>;
  climber?: Maybe<ClimberWhereInput>;
  parent?: Maybe<FolderWhereInput>;
  folders_every?: Maybe<FolderWhereInput>;
  folders_some?: Maybe<FolderWhereInput>;
  folders_none?: Maybe<FolderWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
};

/** References Folder record uniquely */
export type FolderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Gym = Node & {
  __typename?: 'Gym';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Gym>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  instructors: Array<Climber>;
  boulders: Array<Boulder>;
  courses: Array<Course>;
  boulderColors: Array<BoulderColor>;
  days: Array<Day>;
  seasons: Array<Season>;
  students: Array<Student>;
  /** List of Gym versions */
  history: Array<Version>;
};


export type GymDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type GymImageArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type GymInstructorsArgs = {
  where?: Maybe<ClimberWhereInput>;
  orderBy?: Maybe<ClimberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymBouldersArgs = {
  where?: Maybe<BoulderWhereInput>;
  orderBy?: Maybe<BoulderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymCoursesArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymBoulderColorsArgs = {
  where?: Maybe<BoulderColorWhereInput>;
  orderBy?: Maybe<BoulderColorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymDaysArgs = {
  where?: Maybe<DayWhereInput>;
  orderBy?: Maybe<DayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymSeasonsArgs = {
  where?: Maybe<SeasonWhereInput>;
  orderBy?: Maybe<SeasonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymStudentsArgs = {
  where?: Maybe<StudentWhereInput>;
  orderBy?: Maybe<StudentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type GymHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type GymConnectInput = {
  /** Document to connect */
  where: GymWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type GymConnection = {
  __typename?: 'GymConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<GymEdge>;
  aggregate: Aggregate;
};

export type GymCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<AssetCreateOneInlineInput>;
  instructors?: Maybe<ClimberCreateManyInlineInput>;
  boulders?: Maybe<BoulderCreateManyInlineInput>;
  courses?: Maybe<CourseCreateManyInlineInput>;
  boulderColors?: Maybe<BoulderColorCreateManyInlineInput>;
  days?: Maybe<DayCreateManyInlineInput>;
  seasons?: Maybe<SeasonCreateManyInlineInput>;
  students?: Maybe<StudentCreateManyInlineInput>;
};

export type GymCreateManyInlineInput = {
  /** Create and connect multiple existing Gym documents */
  create?: Maybe<Array<GymCreateInput>>;
  /** Connect multiple existing Gym documents */
  connect?: Maybe<Array<GymWhereUniqueInput>>;
};

export type GymCreateOneInlineInput = {
  /** Create and connect one Gym document */
  create?: Maybe<GymCreateInput>;
  /** Connect one existing Gym document */
  connect?: Maybe<GymWhereUniqueInput>;
};

/** An edge in a connection. */
export type GymEdge = {
  __typename?: 'GymEdge';
  /** The item at the end of the edge. */
  node: Gym;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GymManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GymWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GymWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GymWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<AssetWhereInput>;
  instructors_every?: Maybe<ClimberWhereInput>;
  instructors_some?: Maybe<ClimberWhereInput>;
  instructors_none?: Maybe<ClimberWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  boulderColors_every?: Maybe<BoulderColorWhereInput>;
  boulderColors_some?: Maybe<BoulderColorWhereInput>;
  boulderColors_none?: Maybe<BoulderColorWhereInput>;
  days_every?: Maybe<DayWhereInput>;
  days_some?: Maybe<DayWhereInput>;
  days_none?: Maybe<DayWhereInput>;
  seasons_every?: Maybe<SeasonWhereInput>;
  seasons_some?: Maybe<SeasonWhereInput>;
  seasons_none?: Maybe<SeasonWhereInput>;
  students_every?: Maybe<StudentWhereInput>;
  students_some?: Maybe<StudentWhereInput>;
  students_none?: Maybe<StudentWhereInput>;
};

export enum GymOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export type GymUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<AssetUpdateOneInlineInput>;
  instructors?: Maybe<ClimberUpdateManyInlineInput>;
  boulders?: Maybe<BoulderUpdateManyInlineInput>;
  courses?: Maybe<CourseUpdateManyInlineInput>;
  boulderColors?: Maybe<BoulderColorUpdateManyInlineInput>;
  days?: Maybe<DayUpdateManyInlineInput>;
  seasons?: Maybe<SeasonUpdateManyInlineInput>;
  students?: Maybe<StudentUpdateManyInlineInput>;
};

export type GymUpdateManyInlineInput = {
  /** Create and connect multiple Gym documents */
  create?: Maybe<Array<GymCreateInput>>;
  /** Connect multiple existing Gym documents */
  connect?: Maybe<Array<GymConnectInput>>;
  /** Override currently-connected documents with multiple existing Gym documents */
  set?: Maybe<Array<GymWhereUniqueInput>>;
  /** Update multiple Gym documents */
  update?: Maybe<Array<GymUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Gym documents */
  upsert?: Maybe<Array<GymUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Gym documents */
  disconnect?: Maybe<Array<GymWhereUniqueInput>>;
  /** Delete multiple Gym documents */
  delete?: Maybe<Array<GymWhereUniqueInput>>;
};

export type GymUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type GymUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GymWhereInput;
  /** Update many input */
  data: GymUpdateManyInput;
};

export type GymUpdateOneInlineInput = {
  /** Create and connect one Gym document */
  create?: Maybe<GymCreateInput>;
  /** Update single Gym document */
  update?: Maybe<GymUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Gym document */
  upsert?: Maybe<GymUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Gym document */
  connect?: Maybe<GymWhereUniqueInput>;
  /** Disconnect currently connected Gym document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Gym document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GymUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GymWhereUniqueInput;
  /** Document to update */
  data: GymUpdateInput;
};

export type GymUpsertInput = {
  /** Create document if it didn't exist */
  create: GymCreateInput;
  /** Update document if it exists */
  update: GymUpdateInput;
};

export type GymUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GymWhereUniqueInput;
  /** Upsert data */
  data: GymUpsertInput;
};

/** Identifies documents */
export type GymWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GymWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GymWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GymWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<AssetWhereInput>;
  instructors_every?: Maybe<ClimberWhereInput>;
  instructors_some?: Maybe<ClimberWhereInput>;
  instructors_none?: Maybe<ClimberWhereInput>;
  boulders_every?: Maybe<BoulderWhereInput>;
  boulders_some?: Maybe<BoulderWhereInput>;
  boulders_none?: Maybe<BoulderWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  boulderColors_every?: Maybe<BoulderColorWhereInput>;
  boulderColors_some?: Maybe<BoulderColorWhereInput>;
  boulderColors_none?: Maybe<BoulderColorWhereInput>;
  days_every?: Maybe<DayWhereInput>;
  days_some?: Maybe<DayWhereInput>;
  days_none?: Maybe<DayWhereInput>;
  seasons_every?: Maybe<SeasonWhereInput>;
  seasons_some?: Maybe<SeasonWhereInput>;
  seasons_none?: Maybe<SeasonWhereInput>;
  students_every?: Maybe<StudentWhereInput>;
  students_some?: Maybe<StudentWhereInput>;
  students_none?: Maybe<StudentWhereInput>;
};

/** References Gym record uniquely */
export type GymWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};


export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max'
}

export type ImageResizeInput = {
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: Maybe<Scalars['Int']>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: Maybe<Scalars['Int']>;
  /** The default value for the fit parameter is fit:clip. */
  fit?: Maybe<ImageFit>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: Maybe<ImageResizeInput>;
};


/** Locale system enumeration */
export enum Locale {
  /** System locale */
  En = 'en'
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  distance: Scalars['Float'];
};


/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: Stage;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA';
  r: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  b: Scalars['RGBAHue'];
  a: Scalars['RGBATransparency'];
};


/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  r: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  b: Scalars['RGBAHue'];
  a: Scalars['RGBATransparency'];
};


/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText';
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type Season = Node & {
  __typename?: 'Season';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Season>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  yearStart: Scalars['Int'];
  yearEnd: Scalars['Int'];
  gym?: Maybe<Gym>;
  courses: Array<Course>;
  days: Array<Day>;
  /** List of Season versions */
  history: Array<Version>;
};


export type SeasonDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type SeasonGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type SeasonCoursesArgs = {
  where?: Maybe<CourseWhereInput>;
  orderBy?: Maybe<CourseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type SeasonDaysArgs = {
  where?: Maybe<DayWhereInput>;
  orderBy?: Maybe<DayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type SeasonHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type SeasonConnectInput = {
  /** Document to connect */
  where: SeasonWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type SeasonConnection = {
  __typename?: 'SeasonConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<SeasonEdge>;
  aggregate: Aggregate;
};

export type SeasonCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  yearStart: Scalars['Int'];
  yearEnd: Scalars['Int'];
  gym?: Maybe<GymCreateOneInlineInput>;
  courses?: Maybe<CourseCreateManyInlineInput>;
  days?: Maybe<DayCreateManyInlineInput>;
};

export type SeasonCreateManyInlineInput = {
  /** Create and connect multiple existing Season documents */
  create?: Maybe<Array<SeasonCreateInput>>;
  /** Connect multiple existing Season documents */
  connect?: Maybe<Array<SeasonWhereUniqueInput>>;
};

export type SeasonCreateOneInlineInput = {
  /** Create and connect one Season document */
  create?: Maybe<SeasonCreateInput>;
  /** Connect one existing Season document */
  connect?: Maybe<SeasonWhereUniqueInput>;
};

/** An edge in a connection. */
export type SeasonEdge = {
  __typename?: 'SeasonEdge';
  /** The item at the end of the edge. */
  node: Season;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type SeasonManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<SeasonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<SeasonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<SeasonWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  yearStart?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  yearStart_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  yearStart_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  yearStart_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  yearStart_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  yearStart_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  yearStart_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  yearStart_gte?: Maybe<Scalars['Int']>;
  yearEnd?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  yearEnd_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  yearEnd_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  yearEnd_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  yearEnd_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  yearEnd_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  yearEnd_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  yearEnd_gte?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  days_every?: Maybe<DayWhereInput>;
  days_some?: Maybe<DayWhereInput>;
  days_none?: Maybe<DayWhereInput>;
};

export enum SeasonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  YearStartAsc = 'yearStart_ASC',
  YearStartDesc = 'yearStart_DESC',
  YearEndAsc = 'yearEnd_ASC',
  YearEndDesc = 'yearEnd_DESC'
}

export type SeasonUpdateInput = {
  name?: Maybe<Scalars['String']>;
  yearStart?: Maybe<Scalars['Int']>;
  yearEnd?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  courses?: Maybe<CourseUpdateManyInlineInput>;
  days?: Maybe<DayUpdateManyInlineInput>;
};

export type SeasonUpdateManyInlineInput = {
  /** Create and connect multiple Season documents */
  create?: Maybe<Array<SeasonCreateInput>>;
  /** Connect multiple existing Season documents */
  connect?: Maybe<Array<SeasonConnectInput>>;
  /** Override currently-connected documents with multiple existing Season documents */
  set?: Maybe<Array<SeasonWhereUniqueInput>>;
  /** Update multiple Season documents */
  update?: Maybe<Array<SeasonUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Season documents */
  upsert?: Maybe<Array<SeasonUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Season documents */
  disconnect?: Maybe<Array<SeasonWhereUniqueInput>>;
  /** Delete multiple Season documents */
  delete?: Maybe<Array<SeasonWhereUniqueInput>>;
};

export type SeasonUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  yearStart?: Maybe<Scalars['Int']>;
  yearEnd?: Maybe<Scalars['Int']>;
};

export type SeasonUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: SeasonWhereInput;
  /** Update many input */
  data: SeasonUpdateManyInput;
};

export type SeasonUpdateOneInlineInput = {
  /** Create and connect one Season document */
  create?: Maybe<SeasonCreateInput>;
  /** Update single Season document */
  update?: Maybe<SeasonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Season document */
  upsert?: Maybe<SeasonUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Season document */
  connect?: Maybe<SeasonWhereUniqueInput>;
  /** Disconnect currently connected Season document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Season document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type SeasonUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: SeasonWhereUniqueInput;
  /** Document to update */
  data: SeasonUpdateInput;
};

export type SeasonUpsertInput = {
  /** Create document if it didn't exist */
  create: SeasonCreateInput;
  /** Update document if it exists */
  update: SeasonUpdateInput;
};

export type SeasonUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: SeasonWhereUniqueInput;
  /** Upsert data */
  data: SeasonUpsertInput;
};

/** Identifies documents */
export type SeasonWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<SeasonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<SeasonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<SeasonWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  yearStart?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  yearStart_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  yearStart_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  yearStart_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  yearStart_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  yearStart_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  yearStart_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  yearStart_gte?: Maybe<Scalars['Int']>;
  yearEnd?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  yearEnd_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  yearEnd_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  yearEnd_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  yearEnd_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  yearEnd_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  yearEnd_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  yearEnd_gte?: Maybe<Scalars['Int']>;
  gym?: Maybe<GymWhereInput>;
  courses_every?: Maybe<CourseWhereInput>;
  courses_some?: Maybe<CourseWhereInput>;
  courses_none?: Maybe<CourseWhereInput>;
  days_every?: Maybe<DayWhereInput>;
  days_some?: Maybe<DayWhereInput>;
  days_none?: Maybe<DayWhereInput>;
};

/** References Season record uniquely */
export type SeasonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT'
}

export type Student = Node & {
  __typename?: 'Student';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<Student>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  gym?: Maybe<Gym>;
  course?: Maybe<Course>;
  /** List of Student versions */
  history: Array<Version>;
};


export type StudentDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type StudentGymArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type StudentCourseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type StudentHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type StudentConnectInput = {
  /** Document to connect */
  where: StudentWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type StudentConnection = {
  __typename?: 'StudentConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<StudentEdge>;
  aggregate: Aggregate;
};

export type StudentCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  gym?: Maybe<GymCreateOneInlineInput>;
  course?: Maybe<CourseCreateOneInlineInput>;
};

export type StudentCreateManyInlineInput = {
  /** Create and connect multiple existing Student documents */
  create?: Maybe<Array<StudentCreateInput>>;
  /** Connect multiple existing Student documents */
  connect?: Maybe<Array<StudentWhereUniqueInput>>;
};

export type StudentCreateOneInlineInput = {
  /** Create and connect one Student document */
  create?: Maybe<StudentCreateInput>;
  /** Connect one existing Student document */
  connect?: Maybe<StudentWhereUniqueInput>;
};

/** An edge in a connection. */
export type StudentEdge = {
  __typename?: 'StudentEdge';
  /** The item at the end of the edge. */
  node: Student;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type StudentManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StudentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StudentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StudentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  email_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  gym?: Maybe<GymWhereInput>;
  course?: Maybe<CourseWhereInput>;
};

export enum StudentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

export type StudentUpdateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  gym?: Maybe<GymUpdateOneInlineInput>;
  course?: Maybe<CourseUpdateOneInlineInput>;
};

export type StudentUpdateManyInlineInput = {
  /** Create and connect multiple Student documents */
  create?: Maybe<Array<StudentCreateInput>>;
  /** Connect multiple existing Student documents */
  connect?: Maybe<Array<StudentConnectInput>>;
  /** Override currently-connected documents with multiple existing Student documents */
  set?: Maybe<Array<StudentWhereUniqueInput>>;
  /** Update multiple Student documents */
  update?: Maybe<Array<StudentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Student documents */
  upsert?: Maybe<Array<StudentUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Student documents */
  disconnect?: Maybe<Array<StudentWhereUniqueInput>>;
  /** Delete multiple Student documents */
  delete?: Maybe<Array<StudentWhereUniqueInput>>;
};

export type StudentUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type StudentUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: StudentWhereInput;
  /** Update many input */
  data: StudentUpdateManyInput;
};

export type StudentUpdateOneInlineInput = {
  /** Create and connect one Student document */
  create?: Maybe<StudentCreateInput>;
  /** Update single Student document */
  update?: Maybe<StudentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Student document */
  upsert?: Maybe<StudentUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Student document */
  connect?: Maybe<StudentWhereUniqueInput>;
  /** Disconnect currently connected Student document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Student document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type StudentUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StudentWhereUniqueInput;
  /** Document to update */
  data: StudentUpdateInput;
};

export type StudentUpsertInput = {
  /** Create document if it didn't exist */
  create: StudentCreateInput;
  /** Update document if it exists */
  update: StudentUpdateInput;
};

export type StudentUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StudentWhereUniqueInput;
  /** Upsert data */
  data: StudentUpsertInput;
};

/** Identifies documents */
export type StudentWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StudentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StudentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StudentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  email_not_ends_with?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: Maybe<Scalars['String']>;
  gym?: Maybe<GymWhereInput>;
  course?: Maybe<CourseWhereInput>;
};

/** References Student record uniquely */
export type StudentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Localization = 'LOCALIZATION',
  Combined = 'COMBINED'
}

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK'
}

export type Version = {
  __typename?: 'Version';
  id: Scalars['ID'];
  stage: Stage;
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type VersionWhereInput = {
  id: Scalars['ID'];
  stage: Stage;
  revision: Scalars['Int'];
};

export enum _FilterKind {
  Search = 'search',
  And = 'AND',
  Or = 'OR',
  Not = 'NOT',
  Eq = 'eq',
  EqNot = 'eq_not',
  In = 'in',
  NotIn = 'not_in',
  Lt = 'lt',
  Lte = 'lte',
  Gt = 'gt',
  Gte = 'gte',
  Contains = 'contains',
  NotContains = 'not_contains',
  StartsWith = 'starts_with',
  NotStartsWith = 'not_starts_with',
  EndsWith = 'ends_with',
  NotEndsWith = 'not_ends_with',
  ContainsAll = 'contains_all',
  ContainsSome = 'contains_some',
  ContainsNone = 'contains_none',
  RelationalSingle = 'relational_single',
  RelationalEvery = 'relational_every',
  RelationalSome = 'relational_some',
  RelationalNone = 'relational_none'
}

export enum _MutationInputFieldKind {
  Scalar = 'scalar',
  RichText = 'richText',
  Enum = 'enum',
  Relation = 'relation',
  Union = 'union',
  Virtual = 'virtual'
}

export enum _MutationKind {
  Create = 'create',
  Publish = 'publish',
  Unpublish = 'unpublish',
  Update = 'update',
  Upsert = 'upsert',
  Delete = 'delete',
  UpdateMany = 'updateMany',
  PublishMany = 'publishMany',
  UnpublishMany = 'unpublishMany',
  DeleteMany = 'deleteMany'
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum _RelationInputCardinality {
  One = 'one',
  Many = 'many'
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update'
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union'
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Localization = 'localization',
  Combined = 'combined'
}

export type Phrase = Node & {
  __typename?: 'Phrase';
  /** System stage field */
  stage: Stage;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Phrase>;
  /** Get the document in other stages */
  documentInStages: Array<Phrase>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  phrase: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
  climberId: Scalars['String'];
  links: Array<PhraseLink>;
  image?: Maybe<PhraseImage>;
  images: Array<PhraseImage>;
  videos: Array<PhraseVideo>;
  comments: Array<PhraseComment>;
  /** List of Phrase versions */
  history: Array<Version>;
};


export type PhraseLocalizationsArgs = {
  locales?: Array<Locale>;
  includeCurrent?: Scalars['Boolean'];
};


export type PhraseDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type PhraseCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PhraseUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PhrasePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PhraseLinksArgs = {
  where?: Maybe<PhraseLinkWhereInput>;
  orderBy?: Maybe<PhraseLinkOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type PhraseImageArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseImagesArgs = {
  where?: Maybe<PhraseImageWhereInput>;
  orderBy?: Maybe<PhraseImageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type PhraseVideosArgs = {
  where?: Maybe<PhraseVideoWhereInput>;
  orderBy?: Maybe<PhraseVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type PhraseCommentsArgs = {
  where?: Maybe<PhraseCommentWhereInput>;
  orderBy?: Maybe<PhraseCommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<Locale>>;
};


export type PhraseHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type PhraseComment = Node & {
  __typename?: 'PhraseComment';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<PhraseComment>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  comment: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<Phrase>;
  /** List of PhraseComment versions */
  history: Array<Version>;
};


export type PhraseCommentDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type PhraseCommentPhraseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseCommentHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type PhraseCommentConnectInput = {
  /** Document to connect */
  where: PhraseCommentWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PhraseCommentConnection = {
  __typename?: 'PhraseCommentConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<PhraseCommentEdge>;
  aggregate: Aggregate;
};

export type PhraseCommentCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<PhraseCreateOneInlineInput>;
};

export type PhraseCommentCreateManyInlineInput = {
  /** Create and connect multiple existing PhraseComment documents */
  create?: Maybe<Array<PhraseCommentCreateInput>>;
  /** Connect multiple existing PhraseComment documents */
  connect?: Maybe<Array<PhraseCommentWhereUniqueInput>>;
};

export type PhraseCommentCreateOneInlineInput = {
  /** Create and connect one PhraseComment document */
  create?: Maybe<PhraseCommentCreateInput>;
  /** Connect one existing PhraseComment document */
  connect?: Maybe<PhraseCommentWhereUniqueInput>;
};

/** An edge in a connection. */
export type PhraseCommentEdge = {
  __typename?: 'PhraseCommentEdge';
  /** The item at the end of the edge. */
  node: PhraseComment;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type PhraseCommentManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseCommentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseCommentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseCommentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  comment_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  comment_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  comment_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  comment_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  comment_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  comment_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  comment_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  comment_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

export enum PhraseCommentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  ClimberIdAsc = 'climberId_ASC',
  ClimberIdDesc = 'climberId_DESC'
}

export type PhraseCommentUpdateInput = {
  comment?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseUpdateOneInlineInput>;
};

export type PhraseCommentUpdateManyInlineInput = {
  /** Create and connect multiple PhraseComment documents */
  create?: Maybe<Array<PhraseCommentCreateInput>>;
  /** Connect multiple existing PhraseComment documents */
  connect?: Maybe<Array<PhraseCommentConnectInput>>;
  /** Override currently-connected documents with multiple existing PhraseComment documents */
  set?: Maybe<Array<PhraseCommentWhereUniqueInput>>;
  /** Update multiple PhraseComment documents */
  update?: Maybe<Array<PhraseCommentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PhraseComment documents */
  upsert?: Maybe<Array<PhraseCommentUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple PhraseComment documents */
  disconnect?: Maybe<Array<PhraseCommentWhereUniqueInput>>;
  /** Delete multiple PhraseComment documents */
  delete?: Maybe<Array<PhraseCommentWhereUniqueInput>>;
};

export type PhraseCommentUpdateManyInput = {
  comment?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
};

export type PhraseCommentUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PhraseCommentWhereInput;
  /** Update many input */
  data: PhraseCommentUpdateManyInput;
};

export type PhraseCommentUpdateOneInlineInput = {
  /** Create and connect one PhraseComment document */
  create?: Maybe<PhraseCommentCreateInput>;
  /** Update single PhraseComment document */
  update?: Maybe<PhraseCommentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PhraseComment document */
  upsert?: Maybe<PhraseCommentUpsertWithNestedWhereUniqueInput>;
  /** Connect existing PhraseComment document */
  connect?: Maybe<PhraseCommentWhereUniqueInput>;
  /** Disconnect currently connected PhraseComment document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected PhraseComment document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type PhraseCommentUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseCommentWhereUniqueInput;
  /** Document to update */
  data: PhraseCommentUpdateInput;
};

export type PhraseCommentUpsertInput = {
  /** Create document if it didn't exist */
  create: PhraseCommentCreateInput;
  /** Update document if it exists */
  update: PhraseCommentUpdateInput;
};

export type PhraseCommentUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseCommentWhereUniqueInput;
  /** Upsert data */
  data: PhraseCommentUpsertInput;
};

/** Identifies documents */
export type PhraseCommentWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseCommentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseCommentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseCommentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  comment_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  comment_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  comment_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  comment_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  comment_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  comment_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  comment_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  comment_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

/** References PhraseComment record uniquely */
export type PhraseCommentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PhraseConnectInput = {
  /** Document to connect */
  where: PhraseWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PhraseConnection = {
  __typename?: 'PhraseConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<PhraseEdge>;
  aggregate: Aggregate;
};

export type PhraseCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phrase: Scalars['String'];
  /** explanation input for default locale (en) */
  explanation?: Maybe<Scalars['String']>;
  climberId: Scalars['String'];
  links?: Maybe<PhraseLinkCreateManyInlineInput>;
  image?: Maybe<PhraseImageCreateOneInlineInput>;
  images?: Maybe<PhraseImageCreateManyInlineInput>;
  videos?: Maybe<PhraseVideoCreateManyInlineInput>;
  comments?: Maybe<PhraseCommentCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<PhraseCreateLocalizationsInput>;
};

export type PhraseCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  explanation?: Maybe<Scalars['String']>;
};

export type PhraseCreateLocalizationInput = {
  /** Localization input */
  data: PhraseCreateLocalizationDataInput;
  locale: Locale;
};

export type PhraseCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<PhraseCreateLocalizationInput>>;
};

export type PhraseCreateManyInlineInput = {
  /** Create and connect multiple existing Phrase documents */
  create?: Maybe<Array<PhraseCreateInput>>;
  /** Connect multiple existing Phrase documents */
  connect?: Maybe<Array<PhraseWhereUniqueInput>>;
};

export type PhraseCreateOneInlineInput = {
  /** Create and connect one Phrase document */
  create?: Maybe<PhraseCreateInput>;
  /** Connect one existing Phrase document */
  connect?: Maybe<PhraseWhereUniqueInput>;
};

/** An edge in a connection. */
export type PhraseEdge = {
  __typename?: 'PhraseEdge';
  /** The item at the end of the edge. */
  node: Phrase;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type PhraseImage = Node & {
  __typename?: 'PhraseImage';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<PhraseImage>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  title: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<Phrase>;
  imagesPhrase?: Maybe<Phrase>;
  /** List of PhraseImage versions */
  history: Array<Version>;
};


export type PhraseImageDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type PhraseImagePhraseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseImageImagesPhraseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseImageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type PhraseImageConnectInput = {
  /** Document to connect */
  where: PhraseImageWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PhraseImageConnection = {
  __typename?: 'PhraseImageConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<PhraseImageEdge>;
  aggregate: Aggregate;
};

export type PhraseImageCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  title: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<PhraseCreateOneInlineInput>;
  imagesPhrase?: Maybe<PhraseCreateOneInlineInput>;
};

export type PhraseImageCreateManyInlineInput = {
  /** Create and connect multiple existing PhraseImage documents */
  create?: Maybe<Array<PhraseImageCreateInput>>;
  /** Connect multiple existing PhraseImage documents */
  connect?: Maybe<Array<PhraseImageWhereUniqueInput>>;
};

export type PhraseImageCreateOneInlineInput = {
  /** Create and connect one PhraseImage document */
  create?: Maybe<PhraseImageCreateInput>;
  /** Connect one existing PhraseImage document */
  connect?: Maybe<PhraseImageWhereUniqueInput>;
};

/** An edge in a connection. */
export type PhraseImageEdge = {
  __typename?: 'PhraseImageEdge';
  /** The item at the end of the edge. */
  node: PhraseImage;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type PhraseImageManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseImageWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
  imagesPhrase?: Maybe<PhraseWhereInput>;
};

export enum PhraseImageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ClimberIdAsc = 'climberId_ASC',
  ClimberIdDesc = 'climberId_DESC'
}

export type PhraseImageUpdateInput = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseUpdateOneInlineInput>;
  imagesPhrase?: Maybe<PhraseUpdateOneInlineInput>;
};

export type PhraseImageUpdateManyInlineInput = {
  /** Create and connect multiple PhraseImage documents */
  create?: Maybe<Array<PhraseImageCreateInput>>;
  /** Connect multiple existing PhraseImage documents */
  connect?: Maybe<Array<PhraseImageConnectInput>>;
  /** Override currently-connected documents with multiple existing PhraseImage documents */
  set?: Maybe<Array<PhraseImageWhereUniqueInput>>;
  /** Update multiple PhraseImage documents */
  update?: Maybe<Array<PhraseImageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PhraseImage documents */
  upsert?: Maybe<Array<PhraseImageUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple PhraseImage documents */
  disconnect?: Maybe<Array<PhraseImageWhereUniqueInput>>;
  /** Delete multiple PhraseImage documents */
  delete?: Maybe<Array<PhraseImageWhereUniqueInput>>;
};

export type PhraseImageUpdateManyInput = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
};

export type PhraseImageUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PhraseImageWhereInput;
  /** Update many input */
  data: PhraseImageUpdateManyInput;
};

export type PhraseImageUpdateOneInlineInput = {
  /** Create and connect one PhraseImage document */
  create?: Maybe<PhraseImageCreateInput>;
  /** Update single PhraseImage document */
  update?: Maybe<PhraseImageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PhraseImage document */
  upsert?: Maybe<PhraseImageUpsertWithNestedWhereUniqueInput>;
  /** Connect existing PhraseImage document */
  connect?: Maybe<PhraseImageWhereUniqueInput>;
  /** Disconnect currently connected PhraseImage document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected PhraseImage document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type PhraseImageUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseImageWhereUniqueInput;
  /** Document to update */
  data: PhraseImageUpdateInput;
};

export type PhraseImageUpsertInput = {
  /** Create document if it didn't exist */
  create: PhraseImageCreateInput;
  /** Update document if it exists */
  update: PhraseImageUpdateInput;
};

export type PhraseImageUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseImageWhereUniqueInput;
  /** Upsert data */
  data: PhraseImageUpsertInput;
};

/** Identifies documents */
export type PhraseImageWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseImageWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
  imagesPhrase?: Maybe<PhraseWhereInput>;
};

/** References PhraseImage record uniquely */
export type PhraseImageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PhraseLink = Node & {
  __typename?: 'PhraseLink';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<PhraseLink>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  title: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<Phrase>;
  /** List of PhraseLink versions */
  history: Array<Version>;
};


export type PhraseLinkDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type PhraseLinkPhraseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseLinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type PhraseLinkConnectInput = {
  /** Document to connect */
  where: PhraseLinkWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PhraseLinkConnection = {
  __typename?: 'PhraseLinkConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<PhraseLinkEdge>;
  aggregate: Aggregate;
};

export type PhraseLinkCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  title: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<PhraseCreateOneInlineInput>;
};

export type PhraseLinkCreateManyInlineInput = {
  /** Create and connect multiple existing PhraseLink documents */
  create?: Maybe<Array<PhraseLinkCreateInput>>;
  /** Connect multiple existing PhraseLink documents */
  connect?: Maybe<Array<PhraseLinkWhereUniqueInput>>;
};

export type PhraseLinkCreateOneInlineInput = {
  /** Create and connect one PhraseLink document */
  create?: Maybe<PhraseLinkCreateInput>;
  /** Connect one existing PhraseLink document */
  connect?: Maybe<PhraseLinkWhereUniqueInput>;
};

/** An edge in a connection. */
export type PhraseLinkEdge = {
  __typename?: 'PhraseLinkEdge';
  /** The item at the end of the edge. */
  node: PhraseLink;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type PhraseLinkManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseLinkWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

export enum PhraseLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ClimberIdAsc = 'climberId_ASC',
  ClimberIdDesc = 'climberId_DESC'
}

export type PhraseLinkUpdateInput = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseUpdateOneInlineInput>;
};

export type PhraseLinkUpdateManyInlineInput = {
  /** Create and connect multiple PhraseLink documents */
  create?: Maybe<Array<PhraseLinkCreateInput>>;
  /** Connect multiple existing PhraseLink documents */
  connect?: Maybe<Array<PhraseLinkConnectInput>>;
  /** Override currently-connected documents with multiple existing PhraseLink documents */
  set?: Maybe<Array<PhraseLinkWhereUniqueInput>>;
  /** Update multiple PhraseLink documents */
  update?: Maybe<Array<PhraseLinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PhraseLink documents */
  upsert?: Maybe<Array<PhraseLinkUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple PhraseLink documents */
  disconnect?: Maybe<Array<PhraseLinkWhereUniqueInput>>;
  /** Delete multiple PhraseLink documents */
  delete?: Maybe<Array<PhraseLinkWhereUniqueInput>>;
};

export type PhraseLinkUpdateManyInput = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
};

export type PhraseLinkUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PhraseLinkWhereInput;
  /** Update many input */
  data: PhraseLinkUpdateManyInput;
};

export type PhraseLinkUpdateOneInlineInput = {
  /** Create and connect one PhraseLink document */
  create?: Maybe<PhraseLinkCreateInput>;
  /** Update single PhraseLink document */
  update?: Maybe<PhraseLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PhraseLink document */
  upsert?: Maybe<PhraseLinkUpsertWithNestedWhereUniqueInput>;
  /** Connect existing PhraseLink document */
  connect?: Maybe<PhraseLinkWhereUniqueInput>;
  /** Disconnect currently connected PhraseLink document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected PhraseLink document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type PhraseLinkUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseLinkWhereUniqueInput;
  /** Document to update */
  data: PhraseLinkUpdateInput;
};

export type PhraseLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: PhraseLinkCreateInput;
  /** Update document if it exists */
  update: PhraseLinkUpdateInput;
};

export type PhraseLinkUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseLinkWhereUniqueInput;
  /** Upsert data */
  data: PhraseLinkUpsertInput;
};

/** Identifies documents */
export type PhraseLinkWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseLinkWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

/** References PhraseLink record uniquely */
export type PhraseLinkWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Identifies documents */
export type PhraseManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  phrase?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  phrase_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  phrase_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  phrase_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  phrase_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  phrase_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  phrase_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  phrase_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  phrase_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  phrase_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  links_every?: Maybe<PhraseLinkWhereInput>;
  links_some?: Maybe<PhraseLinkWhereInput>;
  links_none?: Maybe<PhraseLinkWhereInput>;
  image?: Maybe<PhraseImageWhereInput>;
  images_every?: Maybe<PhraseImageWhereInput>;
  images_some?: Maybe<PhraseImageWhereInput>;
  images_none?: Maybe<PhraseImageWhereInput>;
  videos_every?: Maybe<PhraseVideoWhereInput>;
  videos_some?: Maybe<PhraseVideoWhereInput>;
  videos_none?: Maybe<PhraseVideoWhereInput>;
  comments_every?: Maybe<PhraseCommentWhereInput>;
  comments_some?: Maybe<PhraseCommentWhereInput>;
  comments_none?: Maybe<PhraseCommentWhereInput>;
};

export enum PhraseOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PhraseAsc = 'phrase_ASC',
  PhraseDesc = 'phrase_DESC',
  ExplanationAsc = 'explanation_ASC',
  ExplanationDesc = 'explanation_DESC',
  ClimberIdAsc = 'climberId_ASC',
  ClimberIdDesc = 'climberId_DESC'
}

export type PhraseUpdateInput = {
  phrase?: Maybe<Scalars['String']>;
  /** explanation input for default locale (en) */
  explanation?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  links?: Maybe<PhraseLinkUpdateManyInlineInput>;
  image?: Maybe<PhraseImageUpdateOneInlineInput>;
  images?: Maybe<PhraseImageUpdateManyInlineInput>;
  videos?: Maybe<PhraseVideoUpdateManyInlineInput>;
  comments?: Maybe<PhraseCommentUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: Maybe<PhraseUpdateLocalizationsInput>;
};

export type PhraseUpdateLocalizationDataInput = {
  explanation?: Maybe<Scalars['String']>;
};

export type PhraseUpdateLocalizationInput = {
  data: PhraseUpdateLocalizationDataInput;
  locale: Locale;
};

export type PhraseUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<PhraseCreateLocalizationInput>>;
  /** Localizations to update */
  update?: Maybe<Array<PhraseUpdateLocalizationInput>>;
  upsert?: Maybe<Array<PhraseUpsertLocalizationInput>>;
  /** Localizations to delete */
  delete?: Maybe<Array<Locale>>;
};

export type PhraseUpdateManyInlineInput = {
  /** Create and connect multiple Phrase documents */
  create?: Maybe<Array<PhraseCreateInput>>;
  /** Connect multiple existing Phrase documents */
  connect?: Maybe<Array<PhraseConnectInput>>;
  /** Override currently-connected documents with multiple existing Phrase documents */
  set?: Maybe<Array<PhraseWhereUniqueInput>>;
  /** Update multiple Phrase documents */
  update?: Maybe<Array<PhraseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Phrase documents */
  upsert?: Maybe<Array<PhraseUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Phrase documents */
  disconnect?: Maybe<Array<PhraseWhereUniqueInput>>;
  /** Delete multiple Phrase documents */
  delete?: Maybe<Array<PhraseWhereUniqueInput>>;
};

export type PhraseUpdateManyInput = {
  /** explanation input for default locale (en) */
  explanation?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: Maybe<PhraseUpdateManyLocalizationsInput>;
};

export type PhraseUpdateManyLocalizationDataInput = {
  explanation?: Maybe<Scalars['String']>;
};

export type PhraseUpdateManyLocalizationInput = {
  data: PhraseUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PhraseUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<PhraseUpdateManyLocalizationInput>>;
};

export type PhraseUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PhraseWhereInput;
  /** Update many input */
  data: PhraseUpdateManyInput;
};

export type PhraseUpdateOneInlineInput = {
  /** Create and connect one Phrase document */
  create?: Maybe<PhraseCreateInput>;
  /** Update single Phrase document */
  update?: Maybe<PhraseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Phrase document */
  upsert?: Maybe<PhraseUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Phrase document */
  connect?: Maybe<PhraseWhereUniqueInput>;
  /** Disconnect currently connected Phrase document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Phrase document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type PhraseUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseWhereUniqueInput;
  /** Document to update */
  data: PhraseUpdateInput;
};

export type PhraseUpsertInput = {
  /** Create document if it didn't exist */
  create: PhraseCreateInput;
  /** Update document if it exists */
  update: PhraseUpdateInput;
};

export type PhraseUpsertLocalizationInput = {
  update: PhraseUpdateLocalizationDataInput;
  create: PhraseCreateLocalizationDataInput;
  locale: Locale;
};

export type PhraseUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseWhereUniqueInput;
  /** Upsert data */
  data: PhraseUpsertInput;
};

export type PhraseVideo = Node & {
  __typename?: 'PhraseVideo';
  /** System stage field */
  stage: Stage;
  /** Get the document in other stages */
  documentInStages: Array<PhraseVideo>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  url: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<Phrase>;
  /** List of PhraseVideo versions */
  history: Array<Version>;
};


export type PhraseVideoDocumentInStagesArgs = {
  stages?: Array<Stage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};


export type PhraseVideoPhraseArgs = {
  locales?: Maybe<Array<Locale>>;
};


export type PhraseVideoHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<Stage>;
};

export type PhraseVideoConnectInput = {
  /** Document to connect */
  where: PhraseVideoWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PhraseVideoConnection = {
  __typename?: 'PhraseVideoConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges: Array<PhraseVideoEdge>;
  aggregate: Aggregate;
};

export type PhraseVideoCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  url: Scalars['String'];
  climberId: Scalars['String'];
  phrase?: Maybe<PhraseCreateOneInlineInput>;
};

export type PhraseVideoCreateManyInlineInput = {
  /** Create and connect multiple existing PhraseVideo documents */
  create?: Maybe<Array<PhraseVideoCreateInput>>;
  /** Connect multiple existing PhraseVideo documents */
  connect?: Maybe<Array<PhraseVideoWhereUniqueInput>>;
};

export type PhraseVideoCreateOneInlineInput = {
  /** Create and connect one PhraseVideo document */
  create?: Maybe<PhraseVideoCreateInput>;
  /** Connect one existing PhraseVideo document */
  connect?: Maybe<PhraseVideoWhereUniqueInput>;
};

/** An edge in a connection. */
export type PhraseVideoEdge = {
  __typename?: 'PhraseVideoEdge';
  /** The item at the end of the edge. */
  node: PhraseVideo;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type PhraseVideoManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseVideoWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

export enum PhraseVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  ClimberIdAsc = 'climberId_ASC',
  ClimberIdDesc = 'climberId_DESC'
}

export type PhraseVideoUpdateInput = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseUpdateOneInlineInput>;
};

export type PhraseVideoUpdateManyInlineInput = {
  /** Create and connect multiple PhraseVideo documents */
  create?: Maybe<Array<PhraseVideoCreateInput>>;
  /** Connect multiple existing PhraseVideo documents */
  connect?: Maybe<Array<PhraseVideoConnectInput>>;
  /** Override currently-connected documents with multiple existing PhraseVideo documents */
  set?: Maybe<Array<PhraseVideoWhereUniqueInput>>;
  /** Update multiple PhraseVideo documents */
  update?: Maybe<Array<PhraseVideoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PhraseVideo documents */
  upsert?: Maybe<Array<PhraseVideoUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple PhraseVideo documents */
  disconnect?: Maybe<Array<PhraseVideoWhereUniqueInput>>;
  /** Delete multiple PhraseVideo documents */
  delete?: Maybe<Array<PhraseVideoWhereUniqueInput>>;
};

export type PhraseVideoUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
};

export type PhraseVideoUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PhraseVideoWhereInput;
  /** Update many input */
  data: PhraseVideoUpdateManyInput;
};

export type PhraseVideoUpdateOneInlineInput = {
  /** Create and connect one PhraseVideo document */
  create?: Maybe<PhraseVideoCreateInput>;
  /** Update single PhraseVideo document */
  update?: Maybe<PhraseVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PhraseVideo document */
  upsert?: Maybe<PhraseVideoUpsertWithNestedWhereUniqueInput>;
  /** Connect existing PhraseVideo document */
  connect?: Maybe<PhraseVideoWhereUniqueInput>;
  /** Disconnect currently connected PhraseVideo document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected PhraseVideo document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type PhraseVideoUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseVideoWhereUniqueInput;
  /** Document to update */
  data: PhraseVideoUpdateInput;
};

export type PhraseVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: PhraseVideoCreateInput;
  /** Update document if it exists */
  update: PhraseVideoUpdateInput;
};

export type PhraseVideoUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PhraseVideoWhereUniqueInput;
  /** Upsert data */
  data: PhraseVideoUpsertInput;
};

/** Identifies documents */
export type PhraseVideoWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseVideoWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  url_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  url_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  phrase?: Maybe<PhraseWhereInput>;
};

/** References PhraseVideo record uniquely */
export type PhraseVideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Identifies documents */
export type PhraseWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PhraseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PhraseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PhraseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  phrase?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  phrase_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  phrase_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  phrase_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  phrase_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  phrase_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  phrase_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  phrase_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  phrase_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  phrase_not_ends_with?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  explanation_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  explanation_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  explanation_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  explanation_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  explanation_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  explanation_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  explanation_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  explanation_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  explanation_not_ends_with?: Maybe<Scalars['String']>;
  climberId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  climberId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  climberId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  climberId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  climberId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  climberId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  climberId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  climberId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  climberId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  climberId_not_ends_with?: Maybe<Scalars['String']>;
  links_every?: Maybe<PhraseLinkWhereInput>;
  links_some?: Maybe<PhraseLinkWhereInput>;
  links_none?: Maybe<PhraseLinkWhereInput>;
  image?: Maybe<PhraseImageWhereInput>;
  images_every?: Maybe<PhraseImageWhereInput>;
  images_some?: Maybe<PhraseImageWhereInput>;
  images_none?: Maybe<PhraseImageWhereInput>;
  videos_every?: Maybe<PhraseVideoWhereInput>;
  videos_some?: Maybe<PhraseVideoWhereInput>;
  videos_none?: Maybe<PhraseVideoWhereInput>;
  comments_every?: Maybe<PhraseCommentWhereInput>;
  comments_some?: Maybe<PhraseCommentWhereInput>;
  comments_none?: Maybe<PhraseCommentWhereInput>;
};

/** References Phrase record uniquely */
export type PhraseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  phrase?: Maybe<Scalars['String']>;
};

export type NewsImageInterface = {
  file: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  mime_type: Scalars['String'];
  url: Scalars['String'];
};

export type NewsImageSize = {
  __typename?: 'NewsImageSize';
  file: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  mime_type: Scalars['String'];
  url: Scalars['String'];
};

export type NewsImage = NewsImageInterface & {
  __typename?: 'NewsImage';
  id: Scalars['Int'];
  title: Scalars['String'];
  slug: Scalars['String'];
  date: Scalars['String'];
  type: Scalars['String'];
  alt_text?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  file: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  mime_type: Scalars['String'];
  url: Scalars['String'];
  thumbnail: NewsImageSize;
  medium: NewsImageSize;
  medium_large: NewsImageSize;
  large: NewsImageSize;
  full: NewsImageSize;
};

export type News = {
  __typename?: 'News';
  id: Scalars['Int'];
  title: Scalars['String'];
  excerpt: Scalars['String'];
  content: Scalars['String'];
  date: Scalars['String'];
  image?: Maybe<NewsImage>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  role: ClimberRole;
  type: ClimberType;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & ViewerFragment
  )> }
);

export type ViewerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'role' | 'type'>
);

export type GymFragment = (
  { __typename?: 'Gym' }
  & Pick<Gym, 'id' | 'name'>
  & { image?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'url'>
  )> }
);

export type BoulderColorFragment = (
  { __typename?: 'BoulderColor' }
  & Pick<BoulderColor, 'id' | 'name'>
  & { color: (
    { __typename?: 'Color' }
    & Pick<Color, 'hex'>
  ), contrast: (
    { __typename?: 'Color' }
    & Pick<Color, 'hex'>
  ) }
);

export type BoulderImageFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'width' | 'height' | 'url'>
);

export type BoulderThumbFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'width' | 'height' | 'url'>
  & { boulders: Array<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )> }
);

export type BoulderIconFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'width' | 'height' | 'url'>
);

export type BoulderItemFragment = (
  { __typename?: 'Boulder' }
  & Pick<Boulder, 'id' | 'name' | 'data' | 'createdAt'>
  & { color?: Maybe<(
    { __typename?: 'BoulderColor' }
    & BoulderColorFragment
  )>, image: (
    { __typename?: 'Asset' }
    & BoulderIconFragment
  ) }
);

export type BoulderFragment = (
  { __typename?: 'Boulder' }
  & Pick<Boulder, 'id' | 'name' | 'data'>
  & { color?: Maybe<(
    { __typename?: 'BoulderColor' }
    & BoulderColorFragment
  )>, image: (
    { __typename?: 'Asset' }
    & BoulderImageFragment
  ) }
);

export type FolderFragment = (
  { __typename?: 'Folder' }
  & Pick<Folder, 'id' | 'name'>
  & { boulders: Array<(
    { __typename?: 'Boulder' }
    & BoulderItemFragment
  )> }
);

export type Folder3Fragment = (
  { __typename?: 'Folder' }
  & FolderFragment
);

export type Folder2Fragment = (
  { __typename?: 'Folder' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Folder3Fragment
  )> }
  & FolderFragment
);

export type Folder1Fragment = (
  { __typename?: 'Folder' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Folder2Fragment
  )> }
  & FolderFragment
);

export type RootFolderFragment = (
  { __typename?: 'Folder' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Folder1Fragment
  )> }
  & FolderFragment
);

export type CreateBoulderMutationVariables = Exact<{
  name: Scalars['String'];
  gymId?: Maybe<Scalars['ID']>;
  climberId?: Maybe<Scalars['ID']>;
  folderId?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['ID']>;
}>;


export type CreateBoulderMutation = (
  { __typename?: 'Mutation' }
  & { createBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )> }
);

export type CreateBoulderDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateBoulderDataQuery = (
  { __typename?: 'Query' }
  & { images: Array<(
    { __typename?: 'Asset' }
    & BoulderThumbFragment
  )>, gyms: Array<(
    { __typename?: 'Gym' }
    & GymFragment
  )> }
);

export type PublishBoulderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['ID']>;
}>;


export type PublishBoulderMutation = (
  { __typename?: 'Mutation' }
  & { publishBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )>, publishAsset?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id'>
  )> }
);

export type CreateFolderMutationVariables = Exact<{
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
}>;


export type CreateFolderMutation = (
  { __typename?: 'Mutation' }
  & { createFolder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragment
  )> }
);

export type PublishFolderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
}>;


export type PublishFolderMutation = (
  { __typename?: 'Mutation' }
  & { publishFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )>, publishParent?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type DeleteBoulderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeleteBoulderMutation = (
  { __typename?: 'Mutation' }
  & { deleteBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )> }
);

export type DeleteFolderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type FoldersQueryVariables = Exact<{
  climberId: Scalars['ID'];
}>;


export type FoldersQuery = (
  { __typename?: 'Query' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & RootFolderFragment
  )> }
);

export type MoveBoulderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  folderId?: Maybe<Scalars['ID']>;
}>;


export type MoveBoulderMutation = (
  { __typename?: 'Mutation' }
  & { moveBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )>, publishBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )>, publishFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type MoveFolderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
}>;


export type MoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { moveFolder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragment
  )>, publishFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )>, publishParent?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type RenameBoulderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
}>;


export type RenameBoulderMutation = (
  { __typename?: 'Mutation' }
  & { renameBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )>, publishBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )> }
);

export type RenameFolderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
}>;


export type RenameFolderMutation = (
  { __typename?: 'Mutation' }
  & { renameFolder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragment
  )>, publishFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type BoulderQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type BoulderQuery = (
  { __typename?: 'Query' }
  & { boulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )>, colors: Array<(
    { __typename?: 'BoulderColor' }
    & BoulderColorFragment
  )> }
);

export type UpdateBoulderColorMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  colorId?: Maybe<Scalars['ID']>;
}>;


export type UpdateBoulderColorMutation = (
  { __typename?: 'Mutation' }
  & { boulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )>, publishBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )> }
);

export type UpdateBoulderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  data: Scalars['Json'];
  assetId?: Maybe<Scalars['ID']>;
}>;


export type UpdateBoulderMutation = (
  { __typename?: 'Mutation' }
  & { boulder?: Maybe<(
    { __typename?: 'Boulder' }
    & BoulderFragment
  )>, publishBoulder?: Maybe<(
    { __typename?: 'Boulder' }
    & Pick<Boulder, 'id'>
  )>, publishAsset?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id'>
  )> }
);

export type CreateChallengeMutationVariables = Exact<{
  data: ChallengeCreateInput;
}>;


export type CreateChallengeMutation = (
  { __typename?: 'Mutation' }
  & { createChallenge?: Maybe<(
    { __typename?: 'Challenge' }
    & ChallengeFragment
  )> }
);

export type CreateChallengeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateChallengeDataQuery = (
  { __typename?: 'Query' }
  & { climbers: Array<(
    { __typename?: 'Climber' }
    & ClimberFragment
  )> }
);

export type PublishChallengeMutationVariables = Exact<{
  where: ChallengeWhereUniqueInput;
}>;


export type PublishChallengeMutation = (
  { __typename?: 'Mutation' }
  & { publishChallenge?: Maybe<(
    { __typename?: 'Challenge' }
    & Pick<Challenge, 'id'>
  )> }
);

export type PublishChallengeGoalsMutationVariables = Exact<{
  challengeId?: Maybe<Scalars['ID']>;
}>;


export type PublishChallengeGoalsMutation = (
  { __typename?: 'Mutation' }
  & { publishManyChallengeGoals: (
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  ) }
);

export type ChallengeAuthorFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name'>
);

export type ChallengeClimberFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name'>
);

export type ChallengeGoalFragment = (
  { __typename?: 'ChallengeGoal' }
  & Pick<ChallengeGoal, 'id' | 'title' | 'goal'>
);

export type ChallengeFragment = (
  { __typename?: 'Challenge' }
  & Pick<Challenge, 'id' | 'title'>
  & { climber?: Maybe<(
    { __typename?: 'Climber' }
    & ChallengeAuthorFragment
  )>, climbers: Array<(
    { __typename?: 'Climber' }
    & ChallengeClimberFragment
  )>, goals: Array<(
    { __typename?: 'ChallengeGoal' }
    & ChallengeGoalFragment
  )> }
);

export type ChallengesQueryVariables = Exact<{ [key: string]: never; }>;


export type ChallengesQuery = (
  { __typename?: 'Query' }
  & { challenges: Array<(
    { __typename?: 'Challenge' }
    & ChallengeItemFragment
  )> }
);

export type ChallengeItemFragment = (
  { __typename?: 'Challenge' }
  & Pick<Challenge, 'id' | 'title'>
);

export type UpdateChallengeMutationVariables = Exact<{
  where: ChallengeWhereUniqueInput;
  data: ChallengeUpdateInput;
}>;


export type UpdateChallengeMutation = (
  { __typename?: 'Mutation' }
  & { updateChallenge?: Maybe<(
    { __typename?: 'Challenge' }
    & ChallengeFragment
  )> }
);

export type ChallengeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChallengeQuery = (
  { __typename?: 'Query' }
  & { challenge?: Maybe<(
    { __typename?: 'Challenge' }
    & ChallengeViewFragment
  )> }
);

export type ChallengeViewAuthorFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name'>
);

export type ChallengeViewProgressFragment = (
  { __typename?: 'ChallengeProgress' }
  & Pick<ChallengeProgress, 'id' | 'progress' | 'createdAt'>
  & { goal?: Maybe<(
    { __typename?: 'ChallengeGoal' }
    & Pick<ChallengeGoal, 'id'>
  )> }
);

export type ChallengeViewClimberFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name'>
  & { challengeProgresses: Array<(
    { __typename?: 'ChallengeProgress' }
    & ChallengeViewProgressFragment
  )> }
);

export type ChallengeViewGoalFragment = (
  { __typename?: 'ChallengeGoal' }
  & Pick<ChallengeGoal, 'id' | 'title' | 'goal'>
);

export type ChallengeViewFragment = (
  { __typename?: 'Challenge' }
  & Pick<Challenge, 'id' | 'title' | 'text'>
  & { climber?: Maybe<(
    { __typename?: 'Climber' }
    & ChallengeViewAuthorFragment
  )>, climbers: Array<(
    { __typename?: 'Climber' }
    & ChallengeViewClimberFragment
  )>, goals: Array<(
    { __typename?: 'ChallengeGoal' }
    & ChallengeViewGoalFragment
  )> }
);

export type CreateChallengeProgressMutationVariables = Exact<{
  data: ChallengeProgressCreateInput;
}>;


export type CreateChallengeProgressMutation = (
  { __typename?: 'Mutation' }
  & { createChallengeProgress?: Maybe<(
    { __typename?: 'ChallengeProgress' }
    & ChallengeViewProgressFragment
  )> }
);

export type PublishChallengeProgressMutationVariables = Exact<{
  where: ChallengeProgressWhereUniqueInput;
}>;


export type PublishChallengeProgressMutation = (
  { __typename?: 'Mutation' }
  & { publishChallengeProgress?: Maybe<(
    { __typename?: 'ChallengeProgress' }
    & Pick<ChallengeProgress, 'id'>
  )> }
);

export type ClimberBoulderFragment = (
  { __typename?: 'Boulder' }
  & { climber?: Maybe<(
    { __typename?: 'Climber' }
    & ClimberFragment
  )> }
  & BoulderFragment
);

export type ClimberBoulderQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ClimberBoulderQuery = (
  { __typename?: 'Query' }
  & { boulder?: Maybe<(
    { __typename?: 'Boulder' }
    & ClimberBoulderFragment
  )> }
);

export type ClimberAvatarFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'width' | 'height' | 'url'>
);

export type ClimberFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name' | 'email' | 'role' | 'type'>
  & { avatar?: Maybe<(
    { __typename?: 'Asset' }
    & ClimberAvatarFragment
  )> }
);

export type ClimbersQueryVariables = Exact<{ [key: string]: never; }>;


export type ClimbersQuery = (
  { __typename?: 'Query' }
  & { climbers: Array<(
    { __typename?: 'Climber' }
    & ClimberFragment
  )> }
);

export type ClimberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClimberQuery = (
  { __typename?: 'Query' }
  & { climber?: Maybe<(
    { __typename?: 'Climber' }
    & { folders: Array<(
      { __typename?: 'Folder' }
      & RootFolderFragment
    )> }
    & ClimberFragment
  )> }
);

export type CourseQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type CourseQuery = (
  { __typename?: 'Query' }
  & { course?: Maybe<(
    { __typename?: 'Course' }
    & CourseViewItemFragment
  )> }
);

export type CourseViewItemFragment = (
  { __typename?: 'Course' }
  & { day?: Maybe<(
    { __typename?: 'Day' }
    & DayFragment
  )>, season?: Maybe<(
    { __typename?: 'Season' }
    & SeasonFragment
  )>, instructors: Array<(
    { __typename?: 'Climber' }
    & InstructorFragment
  )>, students: Array<(
    { __typename?: 'Student' }
    & StudentFragment
  )> }
  & CourseFragment
);

export type InstructorFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name'>
);

export type StudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'name' | 'email' | 'text'>
);

export type SeasonFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'name' | 'yearStart' | 'yearEnd'>
);

export type CourseFragment = (
  { __typename?: 'Course' }
  & Pick<Course, 'id' | 'name' | 'startTime' | 'endTime'>
  & { color: (
    { __typename?: 'Color' }
    & Pick<Color, 'hex'>
  ) }
);

export type DayFragment = (
  { __typename?: 'Day' }
  & Pick<Day, 'id' | 'name' | 'order'>
);

export type SeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type SeasonsQuery = (
  { __typename?: 'Query' }
  & { seasons: Array<(
    { __typename?: 'Season' }
    & SeasonSliderItemFragment
  )> }
);

export type SeasonSliderItemFragment = (
  { __typename?: 'Season' }
  & { days: Array<(
    { __typename?: 'Day' }
    & { courses: Array<(
      { __typename?: 'Course' }
      & CourseListItemFragment
    )> }
    & DayFragment
  )> }
  & SeasonFragment
);

export type CourseListItemFragment = (
  { __typename?: 'Course' }
  & { instructors: Array<(
    { __typename?: 'Climber' }
    & InstructorFragment
  )> }
  & CourseFragment
);

export type HomeBoulderItemFragment = (
  { __typename?: 'Boulder' }
  & { climber?: Maybe<(
    { __typename?: 'Climber' }
    & ClimberFragment
  )> }
  & BoulderItemFragment
);

export type HomeQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type HomeQuery = (
  { __typename?: 'Query' }
  & { boulders: Array<(
    { __typename?: 'Boulder' }
    & HomeBoulderItemFragment
  )>, phrases: Array<(
    { __typename?: 'Phrase' }
    & PhraseFragment
  )>, news: Array<(
    { __typename?: 'News' }
    & NewsFragment
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & LoginUserFragment
  )> }
);

export type LoginUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'role' | 'type' | 'token'>
);

export type NewsImageSizeFragment = (
  { __typename?: 'NewsImageSize' }
  & Pick<NewsImageSize, 'file' | 'width' | 'height' | 'mime_type' | 'url'>
);

export type NewsImageFragment = (
  { __typename?: 'NewsImage' }
  & Pick<NewsImage, 'id' | 'title' | 'caption' | 'alt_text' | 'mime_type'>
  & { thumbnail: (
    { __typename?: 'NewsImageSize' }
    & NewsImageSizeFragment
  ), medium: (
    { __typename?: 'NewsImageSize' }
    & NewsImageSizeFragment
  ), large: (
    { __typename?: 'NewsImageSize' }
    & NewsImageSizeFragment
  ), full: (
    { __typename?: 'NewsImageSize' }
    & NewsImageSizeFragment
  ) }
);

export type NewsFragment = (
  { __typename?: 'News' }
  & Pick<News, 'id' | 'title' | 'excerpt' | 'content' | 'date'>
  & { image?: Maybe<(
    { __typename?: 'NewsImage' }
    & NewsImageFragment
  )> }
);

export type NewsItemFragment = (
  { __typename?: 'News' }
  & Pick<News, 'id' | 'title' | 'excerpt' | 'content' | 'date'>
  & { image?: Maybe<(
    { __typename?: 'NewsImage' }
    & NewsImageFragment
  )> }
);

export type NewsQueryVariables = Exact<{ [key: string]: never; }>;


export type NewsQuery = (
  { __typename?: 'Query' }
  & { news: Array<(
    { __typename?: 'News' }
    & NewsFragment
  )> }
);

export type NewsItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type NewsItemQuery = (
  { __typename?: 'Query' }
  & { newsItem?: Maybe<(
    { __typename?: 'News' }
    & NewsItemFragment
  )> }
);

export type PhraseImageFragment = (
  { __typename?: 'PhraseImage' }
  & Pick<PhraseImage, 'id' | 'url' | 'title'>
);

export type PhraseVideoFragment = (
  { __typename?: 'PhraseVideo' }
  & Pick<PhraseVideo, 'id' | 'url' | 'title'>
);

export type PhraseLinkFragment = (
  { __typename?: 'PhraseLink' }
  & Pick<PhraseLink, 'id' | 'url' | 'title'>
);

export type PhraseCommentFragment = (
  { __typename?: 'PhraseComment' }
  & Pick<PhraseComment, 'id' | 'climberId' | 'comment'>
);

export type PhraseFragment = (
  { __typename?: 'Phrase' }
  & Pick<Phrase, 'id' | 'phrase' | 'explanation'>
  & { image?: Maybe<(
    { __typename?: 'PhraseImage' }
    & PhraseImageFragment
  )>, images: Array<(
    { __typename?: 'PhraseImage' }
    & PhraseImageFragment
  )>, videos: Array<(
    { __typename?: 'PhraseVideo' }
    & PhraseVideoFragment
  )>, links: Array<(
    { __typename?: 'PhraseLink' }
    & PhraseLinkFragment
  )>, comments: Array<(
    { __typename?: 'PhraseComment' }
    & PhraseCommentFragment
  )> }
);

export type PhrasesQueryVariables = Exact<{
  letter: Scalars['String'];
}>;


export type PhrasesQuery = (
  { __typename?: 'Query' }
  & { phrases: Array<(
    { __typename?: 'Phrase' }
    & PhraseFragment
  )> }
);

export type CreatePhraseCommentMutationVariables = Exact<{
  data: PhraseCommentCreateInput;
}>;


export type CreatePhraseCommentMutation = (
  { __typename?: 'Mutation' }
  & { createPhraseComment?: Maybe<(
    { __typename?: 'PhraseComment' }
    & PhraseCommentFragment
  )> }
);

export type PublishPhraseCommentMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishPhraseCommentMutation = (
  { __typename?: 'Mutation' }
  & { publishPhraseComment?: Maybe<(
    { __typename?: 'PhraseComment' }
    & PhraseCommentFragment
  )> }
);

export type CreatePhraseImageMutationVariables = Exact<{
  data: PhraseImageCreateInput;
}>;


export type CreatePhraseImageMutation = (
  { __typename?: 'Mutation' }
  & { createPhraseImage?: Maybe<(
    { __typename?: 'PhraseImage' }
    & PhraseImageFragment
  )> }
);

export type PublishPhraseImageMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishPhraseImageMutation = (
  { __typename?: 'Mutation' }
  & { publishPhraseImage?: Maybe<(
    { __typename?: 'PhraseImage' }
    & PhraseImageFragment
  )> }
);

export type CreatePhraseLinkMutationVariables = Exact<{
  data: PhraseLinkCreateInput;
}>;


export type CreatePhraseLinkMutation = (
  { __typename?: 'Mutation' }
  & { createPhraseLink?: Maybe<(
    { __typename?: 'PhraseLink' }
    & PhraseLinkFragment
  )> }
);

export type PublishPhraseLinkMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishPhraseLinkMutation = (
  { __typename?: 'Mutation' }
  & { publishPhraseLink?: Maybe<(
    { __typename?: 'PhraseLink' }
    & PhraseLinkFragment
  )> }
);

export type CreatePhraseVideoMutationVariables = Exact<{
  data: PhraseVideoCreateInput;
}>;


export type CreatePhraseVideoMutation = (
  { __typename?: 'Mutation' }
  & { createPhraseVideo?: Maybe<(
    { __typename?: 'PhraseVideo' }
    & PhraseVideoFragment
  )> }
);

export type PublishPhraseVideoMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishPhraseVideoMutation = (
  { __typename?: 'Mutation' }
  & { publishPhraseVideo?: Maybe<(
    { __typename?: 'PhraseVideo' }
    & PhraseVideoFragment
  )> }
);

export type DeletePhraseCommentMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeletePhraseCommentMutation = (
  { __typename?: 'Mutation' }
  & { deletePhraseComment?: Maybe<(
    { __typename?: 'PhraseComment' }
    & Pick<PhraseComment, 'id'>
  )> }
);

export type DeletePhraseImageMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeletePhraseImageMutation = (
  { __typename?: 'Mutation' }
  & { deletePhraseImage?: Maybe<(
    { __typename?: 'PhraseImage' }
    & Pick<PhraseImage, 'id'>
  )> }
);

export type DeletePhraseLinkMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeletePhraseLinkMutation = (
  { __typename?: 'Mutation' }
  & { deletePhraseLink?: Maybe<(
    { __typename?: 'PhraseLink' }
    & Pick<PhraseLink, 'id'>
  )> }
);

export type DeletePhraseVideoMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeletePhraseVideoMutation = (
  { __typename?: 'Mutation' }
  & { deletePhraseVideo?: Maybe<(
    { __typename?: 'PhraseVideo' }
    & Pick<PhraseVideo, 'id'>
  )> }
);

export type PublishPhraseMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishPhraseMutation = (
  { __typename?: 'Mutation' }
  & { publishPhrase?: Maybe<(
    { __typename?: 'Phrase' }
    & PhraseFragment
  )> }
);

export type UpdatePhraseMutationVariables = Exact<{
  data: PhraseUpdateInput;
  where: PhraseWhereUniqueInput;
}>;


export type UpdatePhraseMutation = (
  { __typename?: 'Mutation' }
  & { updatePhrase?: Maybe<(
    { __typename?: 'Phrase' }
    & PhraseFragment
  )> }
);

export type PhraseQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PhraseQuery = (
  { __typename?: 'Query' }
  & { phrase?: Maybe<(
    { __typename?: 'Phrase' }
    & PhraseFragment
  )> }
);

export type CreateRootFolderMutationVariables = Exact<{
  climberId: Scalars['ID'];
}>;


export type CreateRootFolderMutation = (
  { __typename?: 'Mutation' }
  & { createRootFolder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  data: ClimberCreateInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type PublishRootFolderMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PublishRootFolderMutation = (
  { __typename?: 'Mutation' }
  & { publishRootFolder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragment
  )> }
);

export type PublishUserMutationVariables = Exact<{
  where: ClimberWhereUniqueInput;
}>;


export type PublishUserMutation = (
  { __typename?: 'Mutation' }
  & { publishUser?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type UserAvatarFragment = (
  { __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'width' | 'height' | 'url'>
);

export type UserFragment = (
  { __typename?: 'Climber' }
  & Pick<Climber, 'id' | 'name' | 'email' | 'role' | 'type'>
  & { avatar?: Maybe<(
    { __typename?: 'Asset' }
    & UserAvatarFragment
  )>, folders: Array<(
    { __typename?: 'Folder' }
    & FolderFragment
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type PublishUserAvatarMutationVariables = Exact<{
  assetId?: Maybe<Scalars['ID']>;
}>;


export type PublishUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { publishAsset?: Maybe<(
    { __typename?: 'Asset' }
    & UserAvatarFragment
  )> }
);

export type SetUserAvatarMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['ID']>;
}>;


export type SetUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { user?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type SetUserPasswordMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
}>;


export type SetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { user?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type ProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  where: ClimberWhereUniqueInput;
  data: ClimberUpdateInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'Climber' }
    & UserFragment
  )> }
);

export const ViewerFragmentDoc = gql`
    fragment Viewer on User {
  id
  name
  email
  role
  type
}
    `;
export const GymFragmentDoc = gql`
    fragment Gym on Gym {
  id
  name
  image {
    id
    url(transformation: {image: {resize: {width: 80, height: 80, fit: crop}}})
  }
}
    `;
export const BoulderThumbFragmentDoc = gql`
    fragment BoulderThumb on Asset {
  id
  width
  height
  url(transformation: {image: {resize: {width: 300, height: 300, fit: crop}}})
  boulders {
    id
  }
}
    `;
export const BoulderColorFragmentDoc = gql`
    fragment BoulderColor on BoulderColor {
  id
  name
  color {
    hex
  }
  contrast {
    hex
  }
}
    `;
export const BoulderIconFragmentDoc = gql`
    fragment BoulderIcon on Asset {
  id
  width
  height
  url(transformation: {image: {resize: {width: 80, height: 80, fit: crop}}})
}
    `;
export const BoulderItemFragmentDoc = gql`
    fragment BoulderItem on Boulder {
  id
  name
  data
  color {
    ...BoulderColor
  }
  image {
    ...BoulderIcon
  }
  createdAt
}
    ${BoulderColorFragmentDoc}
${BoulderIconFragmentDoc}`;
export const FolderFragmentDoc = gql`
    fragment Folder on Folder {
  id
  name
  boulders {
    ...BoulderItem
  }
}
    ${BoulderItemFragmentDoc}`;
export const Folder3FragmentDoc = gql`
    fragment Folder3 on Folder {
  ...Folder
}
    ${FolderFragmentDoc}`;
export const Folder2FragmentDoc = gql`
    fragment Folder2 on Folder {
  ...Folder
  folders {
    ...Folder3
  }
}
    ${FolderFragmentDoc}
${Folder3FragmentDoc}`;
export const Folder1FragmentDoc = gql`
    fragment Folder1 on Folder {
  ...Folder
  folders {
    ...Folder2
  }
}
    ${FolderFragmentDoc}
${Folder2FragmentDoc}`;
export const RootFolderFragmentDoc = gql`
    fragment RootFolder on Folder {
  ...Folder
  folders {
    ...Folder1
  }
}
    ${FolderFragmentDoc}
${Folder1FragmentDoc}`;
export const ChallengeAuthorFragmentDoc = gql`
    fragment ChallengeAuthor on Climber {
  id
  name
}
    `;
export const ChallengeClimberFragmentDoc = gql`
    fragment ChallengeClimber on Climber {
  id
  name
}
    `;
export const ChallengeGoalFragmentDoc = gql`
    fragment ChallengeGoal on ChallengeGoal {
  id
  title
  goal
}
    `;
export const ChallengeFragmentDoc = gql`
    fragment Challenge on Challenge {
  id
  title
  climber {
    ...ChallengeAuthor
  }
  climbers {
    ...ChallengeClimber
  }
  goals {
    ...ChallengeGoal
  }
}
    ${ChallengeAuthorFragmentDoc}
${ChallengeClimberFragmentDoc}
${ChallengeGoalFragmentDoc}`;
export const ChallengeItemFragmentDoc = gql`
    fragment ChallengeItem on Challenge {
  id
  title
}
    `;
export const ChallengeViewAuthorFragmentDoc = gql`
    fragment ChallengeViewAuthor on Climber {
  id
  name
}
    `;
export const ChallengeViewProgressFragmentDoc = gql`
    fragment ChallengeViewProgress on ChallengeProgress {
  id
  progress
  createdAt
  goal {
    id
  }
}
    `;
export const ChallengeViewClimberFragmentDoc = gql`
    fragment ChallengeViewClimber on Climber {
  id
  name
  challengeProgresses(where: {challenge: {id: $id}}) {
    ...ChallengeViewProgress
  }
}
    ${ChallengeViewProgressFragmentDoc}`;
export const ChallengeViewGoalFragmentDoc = gql`
    fragment ChallengeViewGoal on ChallengeGoal {
  id
  title
  goal
}
    `;
export const ChallengeViewFragmentDoc = gql`
    fragment ChallengeView on Challenge {
  id
  title
  text
  climber {
    ...ChallengeViewAuthor
  }
  climbers {
    ...ChallengeViewClimber
  }
  goals {
    ...ChallengeViewGoal
  }
}
    ${ChallengeViewAuthorFragmentDoc}
${ChallengeViewClimberFragmentDoc}
${ChallengeViewGoalFragmentDoc}`;
export const BoulderImageFragmentDoc = gql`
    fragment BoulderImage on Asset {
  id
  width
  height
  url(transformation: {image: {resize: {width: 1920, height: 1920}}})
}
    `;
export const BoulderFragmentDoc = gql`
    fragment Boulder on Boulder {
  id
  name
  data
  color {
    ...BoulderColor
  }
  image {
    ...BoulderImage
  }
}
    ${BoulderColorFragmentDoc}
${BoulderImageFragmentDoc}`;
export const ClimberAvatarFragmentDoc = gql`
    fragment ClimberAvatar on Asset {
  id
  width
  height
  url(transformation: {image: {resize: {width: 80, height: 80, fit: crop}}})
}
    `;
export const ClimberFragmentDoc = gql`
    fragment Climber on Climber {
  id
  name
  email
  role
  type
  avatar {
    ...ClimberAvatar
  }
}
    ${ClimberAvatarFragmentDoc}`;
export const ClimberBoulderFragmentDoc = gql`
    fragment ClimberBoulder on Boulder {
  ...Boulder
  climber {
    ...Climber
  }
}
    ${BoulderFragmentDoc}
${ClimberFragmentDoc}`;
export const CourseFragmentDoc = gql`
    fragment Course on Course {
  id
  name
  startTime
  endTime
  color {
    hex
  }
}
    `;
export const DayFragmentDoc = gql`
    fragment Day on Day {
  id
  name
  order
}
    `;
export const SeasonFragmentDoc = gql`
    fragment Season on Season {
  id
  name
  yearStart
  yearEnd
}
    `;
export const InstructorFragmentDoc = gql`
    fragment Instructor on Climber {
  id
  name
}
    `;
export const StudentFragmentDoc = gql`
    fragment Student on Student {
  id
  name
  email
  text
}
    `;
export const CourseViewItemFragmentDoc = gql`
    fragment CourseViewItem on Course {
  ...Course
  day {
    ...Day
  }
  season {
    ...Season
  }
  instructors {
    ...Instructor
  }
  students {
    ...Student
  }
}
    ${CourseFragmentDoc}
${DayFragmentDoc}
${SeasonFragmentDoc}
${InstructorFragmentDoc}
${StudentFragmentDoc}`;
export const CourseListItemFragmentDoc = gql`
    fragment CourseListItem on Course {
  ...Course
  instructors {
    ...Instructor
  }
}
    ${CourseFragmentDoc}
${InstructorFragmentDoc}`;
export const SeasonSliderItemFragmentDoc = gql`
    fragment SeasonSliderItem on Season {
  ...Season
  days(orderBy: order_ASC) {
    ...Day
    courses {
      ...CourseListItem
    }
  }
}
    ${SeasonFragmentDoc}
${DayFragmentDoc}
${CourseListItemFragmentDoc}`;
export const HomeBoulderItemFragmentDoc = gql`
    fragment HomeBoulderItem on Boulder {
  ...BoulderItem
  climber {
    ...Climber
  }
}
    ${BoulderItemFragmentDoc}
${ClimberFragmentDoc}`;
export const LoginUserFragmentDoc = gql`
    fragment LoginUser on User {
  id
  name
  email
  role
  type
  token
}
    `;
export const NewsImageSizeFragmentDoc = gql`
    fragment NewsImageSize on NewsImageSize {
  file
  width
  height
  mime_type
  url
}
    `;
export const NewsImageFragmentDoc = gql`
    fragment NewsImage on NewsImage {
  id
  title
  caption
  alt_text
  mime_type
  thumbnail {
    ...NewsImageSize
  }
  medium {
    ...NewsImageSize
  }
  large {
    ...NewsImageSize
  }
  full {
    ...NewsImageSize
  }
}
    ${NewsImageSizeFragmentDoc}`;
export const NewsFragmentDoc = gql`
    fragment News on News {
  id
  title
  excerpt
  content
  date
  image {
    ...NewsImage
  }
}
    ${NewsImageFragmentDoc}`;
export const NewsItemFragmentDoc = gql`
    fragment NewsItem on News {
  id
  title
  excerpt
  content
  date
  image {
    ...NewsImage
  }
}
    ${NewsImageFragmentDoc}`;
export const PhraseImageFragmentDoc = gql`
    fragment PhraseImage on PhraseImage {
  id
  url
  title
}
    `;
export const PhraseVideoFragmentDoc = gql`
    fragment PhraseVideo on PhraseVideo {
  id
  url
  title
}
    `;
export const PhraseLinkFragmentDoc = gql`
    fragment PhraseLink on PhraseLink {
  id
  url
  title
}
    `;
export const PhraseCommentFragmentDoc = gql`
    fragment PhraseComment on PhraseComment {
  id
  climberId
  comment
}
    `;
export const PhraseFragmentDoc = gql`
    fragment Phrase on Phrase {
  id
  phrase
  explanation
  image {
    ...PhraseImage
  }
  images {
    ...PhraseImage
  }
  videos {
    ...PhraseVideo
  }
  links {
    ...PhraseLink
  }
  comments {
    ...PhraseComment
  }
}
    ${PhraseImageFragmentDoc}
${PhraseVideoFragmentDoc}
${PhraseLinkFragmentDoc}
${PhraseCommentFragmentDoc}`;
export const UserAvatarFragmentDoc = gql`
    fragment UserAvatar on Asset {
  id
  width
  height
  url(transformation: {image: {resize: {width: 300, height: 300, fit: crop}}})
}
    `;
export const UserFragmentDoc = gql`
    fragment User on Climber {
  id
  name
  email
  role
  type
  avatar {
    ...UserAvatar
  }
  folders(where: {root: true}) {
    ...Folder
  }
}
    ${UserAvatarFragmentDoc}
${FolderFragmentDoc}`;
export const ViewerDocument = gql`
    query viewer {
  viewer {
    ...Viewer
  }
}
    ${ViewerFragmentDoc}`;

export function useViewerQuery(options: Omit<Urql.UseQueryArgs<ViewerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ViewerQuery>({ query: ViewerDocument, ...options });
};
export const CreateBoulderDocument = gql`
    mutation createBoulder($name: String!, $gymId: ID, $climberId: ID, $folderId: ID, $assetId: ID) {
  createBoulder(
    data: {name: $name, gym: {connect: {id: $gymId}}, climber: {connect: {id: $climberId}}, folder: {connect: {id: $folderId}}, image: {connect: {id: $assetId}}}
  ) {
    ...Boulder
  }
}
    ${BoulderFragmentDoc}`;

export function useCreateBoulderMutation() {
  return Urql.useMutation<CreateBoulderMutation, CreateBoulderMutationVariables>(CreateBoulderDocument);
};
export const CreateBoulderDataDocument = gql`
    query createBoulderData {
  images: assets(orderBy: createdAt_DESC) {
    ...BoulderThumb
  }
  gyms {
    ...Gym
  }
}
    ${BoulderThumbFragmentDoc}
${GymFragmentDoc}`;

export function useCreateBoulderDataQuery(options: Omit<Urql.UseQueryArgs<CreateBoulderDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CreateBoulderDataQuery>({ query: CreateBoulderDataDocument, ...options });
};
export const PublishBoulderDocument = gql`
    mutation publishBoulder($id: ID, $assetId: ID) {
  publishBoulder(where: {id: $id}, to: PUBLISHED) {
    id
  }
  publishAsset(where: {id: $assetId}, to: PUBLISHED) {
    id
  }
}
    `;

export function usePublishBoulderMutation() {
  return Urql.useMutation<PublishBoulderMutation, PublishBoulderMutationVariables>(PublishBoulderDocument);
};
export const CreateFolderDocument = gql`
    mutation createFolder($name: String!, $parentId: ID) {
  createFolder(
    data: {name: $name, root: false, parent: {connect: {id: $parentId}}}
  ) {
    ...Folder
  }
}
    ${FolderFragmentDoc}`;

export function useCreateFolderMutation() {
  return Urql.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument);
};
export const PublishFolderDocument = gql`
    mutation publishFolder($id: ID, $parentId: ID) {
  publishFolder: publishFolder(where: {id: $id}, to: PUBLISHED) {
    id
  }
  publishParent: publishFolder(where: {id: $parentId}, to: PUBLISHED) {
    id
  }
}
    `;

export function usePublishFolderMutation() {
  return Urql.useMutation<PublishFolderMutation, PublishFolderMutationVariables>(PublishFolderDocument);
};
export const DeleteBoulderDocument = gql`
    mutation deleteBoulder($id: ID) {
  deleteBoulder(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteBoulderMutation() {
  return Urql.useMutation<DeleteBoulderMutation, DeleteBoulderMutationVariables>(DeleteBoulderDocument);
};
export const DeleteFolderDocument = gql`
    mutation deleteFolder($id: ID) {
  deleteFolder(where: {id: $id}) {
    id
  }
}
    `;

export function useDeleteFolderMutation() {
  return Urql.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument);
};
export const FoldersDocument = gql`
    query folders($climberId: ID!) {
  folders(where: {root: true, climber: {id: $climberId}}) {
    ...RootFolder
  }
}
    ${RootFolderFragmentDoc}`;

export function useFoldersQuery(options: Omit<Urql.UseQueryArgs<FoldersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FoldersQuery>({ query: FoldersDocument, ...options });
};
export const MoveBoulderDocument = gql`
    mutation moveBoulder($id: ID, $folderId: ID) {
  moveBoulder: updateBoulder(
    data: {folder: {connect: {id: $folderId}}}
    where: {id: $id}
  ) {
    ...Boulder
  }
  publishBoulder(where: {id: $id}, to: PUBLISHED) {
    id
  }
  publishFolder(where: {id: $folderId}, to: PUBLISHED) {
    id
  }
}
    ${BoulderFragmentDoc}`;

export function useMoveBoulderMutation() {
  return Urql.useMutation<MoveBoulderMutation, MoveBoulderMutationVariables>(MoveBoulderDocument);
};
export const MoveFolderDocument = gql`
    mutation moveFolder($id: ID, $parentId: ID) {
  moveFolder: updateFolder(
    data: {parent: {connect: {id: $parentId}}}
    where: {id: $id}
  ) {
    ...Folder
  }
  publishFolder(where: {id: $id}, to: PUBLISHED) {
    id
  }
  publishParent: publishFolder(where: {id: $parentId}, to: PUBLISHED) {
    id
  }
}
    ${FolderFragmentDoc}`;

export function useMoveFolderMutation() {
  return Urql.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument);
};
export const RenameBoulderDocument = gql`
    mutation renameBoulder($id: ID, $name: String!) {
  renameBoulder: updateBoulder(data: {name: $name}, where: {id: $id}) {
    ...Boulder
  }
  publishBoulder(where: {id: $id}, to: PUBLISHED) {
    id
  }
}
    ${BoulderFragmentDoc}`;

export function useRenameBoulderMutation() {
  return Urql.useMutation<RenameBoulderMutation, RenameBoulderMutationVariables>(RenameBoulderDocument);
};
export const RenameFolderDocument = gql`
    mutation renameFolder($id: ID, $name: String!) {
  renameFolder: updateFolder(data: {name: $name}, where: {id: $id}) {
    ...Folder
  }
  publishFolder(where: {id: $id}, to: PUBLISHED) {
    id
  }
}
    ${FolderFragmentDoc}`;

export function useRenameFolderMutation() {
  return Urql.useMutation<RenameFolderMutation, RenameFolderMutationVariables>(RenameFolderDocument);
};
export const BoulderDocument = gql`
    query boulder($id: ID) {
  boulder(where: {id: $id}) {
    ...Boulder
  }
  colors: boulderColors {
    ...BoulderColor
  }
}
    ${BoulderFragmentDoc}
${BoulderColorFragmentDoc}`;

export function useBoulderQuery(options: Omit<Urql.UseQueryArgs<BoulderQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BoulderQuery>({ query: BoulderDocument, ...options });
};
export const UpdateBoulderColorDocument = gql`
    mutation updateBoulderColor($id: ID, $colorId: ID) {
  boulder: updateBoulder(
    data: {color: {connect: {id: $colorId}}}
    where: {id: $id}
  ) {
    ...Boulder
  }
  publishBoulder(where: {id: $id}, to: PUBLISHED) {
    id
  }
}
    ${BoulderFragmentDoc}`;

export function useUpdateBoulderColorMutation() {
  return Urql.useMutation<UpdateBoulderColorMutation, UpdateBoulderColorMutationVariables>(UpdateBoulderColorDocument);
};
export const UpdateBoulderDocument = gql`
    mutation updateBoulder($id: ID, $name: String!, $data: Json!, $assetId: ID) {
  boulder: updateBoulder(data: {name: $name, data: $data}, where: {id: $id}) {
    ...Boulder
  }
  publishBoulder(where: {id: $id}, to: PUBLISHED) {
    id
  }
  publishAsset(where: {id: $assetId}, to: PUBLISHED) {
    id
  }
}
    ${BoulderFragmentDoc}`;

export function useUpdateBoulderMutation() {
  return Urql.useMutation<UpdateBoulderMutation, UpdateBoulderMutationVariables>(UpdateBoulderDocument);
};
export const CreateChallengeDocument = gql`
    mutation createChallenge($data: ChallengeCreateInput!) {
  createChallenge(data: $data) {
    ...Challenge
  }
}
    ${ChallengeFragmentDoc}`;

export function useCreateChallengeMutation() {
  return Urql.useMutation<CreateChallengeMutation, CreateChallengeMutationVariables>(CreateChallengeDocument);
};
export const CreateChallengeDataDocument = gql`
    query createChallengeData {
  climbers {
    ...Climber
  }
}
    ${ClimberFragmentDoc}`;

export function useCreateChallengeDataQuery(options: Omit<Urql.UseQueryArgs<CreateChallengeDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CreateChallengeDataQuery>({ query: CreateChallengeDataDocument, ...options });
};
export const PublishChallengeDocument = gql`
    mutation publishChallenge($where: ChallengeWhereUniqueInput!) {
  publishChallenge(where: $where, to: PUBLISHED) {
    id
  }
}
    `;

export function usePublishChallengeMutation() {
  return Urql.useMutation<PublishChallengeMutation, PublishChallengeMutationVariables>(PublishChallengeDocument);
};
export const PublishChallengeGoalsDocument = gql`
    mutation publishChallengeGoals($challengeId: ID) {
  publishManyChallengeGoals(where: {challenge: {id: $challengeId}}, to: PUBLISHED) {
    count
  }
}
    `;

export function usePublishChallengeGoalsMutation() {
  return Urql.useMutation<PublishChallengeGoalsMutation, PublishChallengeGoalsMutationVariables>(PublishChallengeGoalsDocument);
};
export const ChallengesDocument = gql`
    query challenges {
  challenges {
    ...ChallengeItem
  }
}
    ${ChallengeItemFragmentDoc}`;

export function useChallengesQuery(options: Omit<Urql.UseQueryArgs<ChallengesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ChallengesQuery>({ query: ChallengesDocument, ...options });
};
export const UpdateChallengeDocument = gql`
    mutation updateChallenge($where: ChallengeWhereUniqueInput!, $data: ChallengeUpdateInput!) {
  updateChallenge(data: $data, where: $where) {
    ...Challenge
  }
}
    ${ChallengeFragmentDoc}`;

export function useUpdateChallengeMutation() {
  return Urql.useMutation<UpdateChallengeMutation, UpdateChallengeMutationVariables>(UpdateChallengeDocument);
};
export const ChallengeDocument = gql`
    query challenge($id: ID!) {
  challenge(where: {id: $id}) {
    ...ChallengeView
  }
}
    ${ChallengeViewFragmentDoc}`;

export function useChallengeQuery(options: Omit<Urql.UseQueryArgs<ChallengeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ChallengeQuery>({ query: ChallengeDocument, ...options });
};
export const CreateChallengeProgressDocument = gql`
    mutation createChallengeProgress($data: ChallengeProgressCreateInput!) {
  createChallengeProgress(data: $data) {
    ...ChallengeViewProgress
  }
}
    ${ChallengeViewProgressFragmentDoc}`;

export function useCreateChallengeProgressMutation() {
  return Urql.useMutation<CreateChallengeProgressMutation, CreateChallengeProgressMutationVariables>(CreateChallengeProgressDocument);
};
export const PublishChallengeProgressDocument = gql`
    mutation publishChallengeProgress($where: ChallengeProgressWhereUniqueInput!) {
  publishChallengeProgress(where: $where, to: PUBLISHED) {
    id
  }
}
    `;

export function usePublishChallengeProgressMutation() {
  return Urql.useMutation<PublishChallengeProgressMutation, PublishChallengeProgressMutationVariables>(PublishChallengeProgressDocument);
};
export const ClimberBoulderDocument = gql`
    query climberBoulder($id: ID) {
  boulder(where: {id: $id}) {
    ...ClimberBoulder
  }
}
    ${ClimberBoulderFragmentDoc}`;

export function useClimberBoulderQuery(options: Omit<Urql.UseQueryArgs<ClimberBoulderQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClimberBoulderQuery>({ query: ClimberBoulderDocument, ...options });
};
export const ClimbersDocument = gql`
    query climbers {
  climbers {
    ...Climber
  }
}
    ${ClimberFragmentDoc}`;

export function useClimbersQuery(options: Omit<Urql.UseQueryArgs<ClimbersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClimbersQuery>({ query: ClimbersDocument, ...options });
};
export const ClimberDocument = gql`
    query climber($id: ID!) {
  climber(where: {id: $id}) {
    ...Climber
    folders(where: {root: true}) {
      ...RootFolder
    }
  }
}
    ${ClimberFragmentDoc}
${RootFolderFragmentDoc}`;

export function useClimberQuery(options: Omit<Urql.UseQueryArgs<ClimberQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClimberQuery>({ query: ClimberDocument, ...options });
};
export const CourseDocument = gql`
    query course($id: ID) {
  course(where: {id: $id}) {
    ...CourseViewItem
  }
}
    ${CourseViewItemFragmentDoc}`;

export function useCourseQuery(options: Omit<Urql.UseQueryArgs<CourseQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CourseQuery>({ query: CourseDocument, ...options });
};
export const SeasonsDocument = gql`
    query seasons {
  seasons {
    ...SeasonSliderItem
  }
}
    ${SeasonSliderItemFragmentDoc}`;

export function useSeasonsQuery(options: Omit<Urql.UseQueryArgs<SeasonsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SeasonsQuery>({ query: SeasonsDocument, ...options });
};
export const HomeDocument = gql`
    query home($limit: Int!) {
  boulders(first: $limit, orderBy: createdAt_DESC) {
    ...HomeBoulderItem
  }
  phrases(skip: 0, first: 9999, orderBy: phrase_ASC) {
    ...Phrase
  }
  news {
    ...News
  }
}
    ${HomeBoulderItemFragmentDoc}
${PhraseFragmentDoc}
${NewsFragmentDoc}`;

export function useHomeQuery(options: Omit<Urql.UseQueryArgs<HomeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HomeQuery>({ query: HomeDocument, ...options });
};
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  user: login(email: $email, password: $password) {
    ...LoginUser
  }
}
    ${LoginUserFragmentDoc}`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const NewsDocument = gql`
    query news {
  news {
    ...News
  }
}
    ${NewsFragmentDoc}`;

export function useNewsQuery(options: Omit<Urql.UseQueryArgs<NewsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NewsQuery>({ query: NewsDocument, ...options });
};
export const NewsItemDocument = gql`
    query newsItem($id: Int!) {
  newsItem(id: $id) {
    ...NewsItem
  }
}
    ${NewsItemFragmentDoc}`;

export function useNewsItemQuery(options: Omit<Urql.UseQueryArgs<NewsItemQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NewsItemQuery>({ query: NewsItemDocument, ...options });
};
export const PhrasesDocument = gql`
    query phrases($letter: String!) {
  phrases(
    where: {phrase_starts_with: $letter}
    skip: 0
    first: 9999
    orderBy: phrase_ASC
  ) {
    ...Phrase
  }
}
    ${PhraseFragmentDoc}`;

export function usePhrasesQuery(options: Omit<Urql.UseQueryArgs<PhrasesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PhrasesQuery>({ query: PhrasesDocument, ...options });
};
export const CreatePhraseCommentDocument = gql`
    mutation createPhraseComment($data: PhraseCommentCreateInput!) {
  createPhraseComment(data: $data) {
    ...PhraseComment
  }
}
    ${PhraseCommentFragmentDoc}`;

export function useCreatePhraseCommentMutation() {
  return Urql.useMutation<CreatePhraseCommentMutation, CreatePhraseCommentMutationVariables>(CreatePhraseCommentDocument);
};
export const PublishPhraseCommentDocument = gql`
    mutation publishPhraseComment($id: ID) {
  publishPhraseComment(where: {id: $id}, to: PUBLISHED) {
    ...PhraseComment
  }
}
    ${PhraseCommentFragmentDoc}`;

export function usePublishPhraseCommentMutation() {
  return Urql.useMutation<PublishPhraseCommentMutation, PublishPhraseCommentMutationVariables>(PublishPhraseCommentDocument);
};
export const CreatePhraseImageDocument = gql`
    mutation createPhraseImage($data: PhraseImageCreateInput!) {
  createPhraseImage(data: $data) {
    ...PhraseImage
  }
}
    ${PhraseImageFragmentDoc}`;

export function useCreatePhraseImageMutation() {
  return Urql.useMutation<CreatePhraseImageMutation, CreatePhraseImageMutationVariables>(CreatePhraseImageDocument);
};
export const PublishPhraseImageDocument = gql`
    mutation publishPhraseImage($id: ID) {
  publishPhraseImage(where: {id: $id}, to: PUBLISHED) {
    ...PhraseImage
  }
}
    ${PhraseImageFragmentDoc}`;

export function usePublishPhraseImageMutation() {
  return Urql.useMutation<PublishPhraseImageMutation, PublishPhraseImageMutationVariables>(PublishPhraseImageDocument);
};
export const CreatePhraseLinkDocument = gql`
    mutation createPhraseLink($data: PhraseLinkCreateInput!) {
  createPhraseLink(data: $data) {
    ...PhraseLink
  }
}
    ${PhraseLinkFragmentDoc}`;

export function useCreatePhraseLinkMutation() {
  return Urql.useMutation<CreatePhraseLinkMutation, CreatePhraseLinkMutationVariables>(CreatePhraseLinkDocument);
};
export const PublishPhraseLinkDocument = gql`
    mutation publishPhraseLink($id: ID) {
  publishPhraseLink(where: {id: $id}, to: PUBLISHED) {
    ...PhraseLink
  }
}
    ${PhraseLinkFragmentDoc}`;

export function usePublishPhraseLinkMutation() {
  return Urql.useMutation<PublishPhraseLinkMutation, PublishPhraseLinkMutationVariables>(PublishPhraseLinkDocument);
};
export const CreatePhraseVideoDocument = gql`
    mutation createPhraseVideo($data: PhraseVideoCreateInput!) {
  createPhraseVideo(data: $data) {
    ...PhraseVideo
  }
}
    ${PhraseVideoFragmentDoc}`;

export function useCreatePhraseVideoMutation() {
  return Urql.useMutation<CreatePhraseVideoMutation, CreatePhraseVideoMutationVariables>(CreatePhraseVideoDocument);
};
export const PublishPhraseVideoDocument = gql`
    mutation publishPhraseVideo($id: ID) {
  publishPhraseVideo(where: {id: $id}, to: PUBLISHED) {
    ...PhraseVideo
  }
}
    ${PhraseVideoFragmentDoc}`;

export function usePublishPhraseVideoMutation() {
  return Urql.useMutation<PublishPhraseVideoMutation, PublishPhraseVideoMutationVariables>(PublishPhraseVideoDocument);
};
export const DeletePhraseCommentDocument = gql`
    mutation deletePhraseComment($id: ID) {
  deletePhraseComment(where: {id: $id}) {
    id
  }
}
    `;

export function useDeletePhraseCommentMutation() {
  return Urql.useMutation<DeletePhraseCommentMutation, DeletePhraseCommentMutationVariables>(DeletePhraseCommentDocument);
};
export const DeletePhraseImageDocument = gql`
    mutation deletePhraseImage($id: ID) {
  deletePhraseImage(where: {id: $id}) {
    id
  }
}
    `;

export function useDeletePhraseImageMutation() {
  return Urql.useMutation<DeletePhraseImageMutation, DeletePhraseImageMutationVariables>(DeletePhraseImageDocument);
};
export const DeletePhraseLinkDocument = gql`
    mutation deletePhraseLink($id: ID) {
  deletePhraseLink(where: {id: $id}) {
    id
  }
}
    `;

export function useDeletePhraseLinkMutation() {
  return Urql.useMutation<DeletePhraseLinkMutation, DeletePhraseLinkMutationVariables>(DeletePhraseLinkDocument);
};
export const DeletePhraseVideoDocument = gql`
    mutation deletePhraseVideo($id: ID) {
  deletePhraseVideo(where: {id: $id}) {
    id
  }
}
    `;

export function useDeletePhraseVideoMutation() {
  return Urql.useMutation<DeletePhraseVideoMutation, DeletePhraseVideoMutationVariables>(DeletePhraseVideoDocument);
};
export const PublishPhraseDocument = gql`
    mutation publishPhrase($id: ID) {
  publishPhrase(where: {id: $id}, to: PUBLISHED) {
    ...Phrase
  }
}
    ${PhraseFragmentDoc}`;

export function usePublishPhraseMutation() {
  return Urql.useMutation<PublishPhraseMutation, PublishPhraseMutationVariables>(PublishPhraseDocument);
};
export const UpdatePhraseDocument = gql`
    mutation updatePhrase($data: PhraseUpdateInput!, $where: PhraseWhereUniqueInput!) {
  updatePhrase(data: $data, where: $where) {
    ...Phrase
  }
}
    ${PhraseFragmentDoc}`;

export function useUpdatePhraseMutation() {
  return Urql.useMutation<UpdatePhraseMutation, UpdatePhraseMutationVariables>(UpdatePhraseDocument);
};
export const PhraseDocument = gql`
    query phrase($id: ID) {
  phrase(where: {id: $id}) {
    ...Phrase
  }
}
    ${PhraseFragmentDoc}`;

export function usePhraseQuery(options: Omit<Urql.UseQueryArgs<PhraseQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PhraseQuery>({ query: PhraseDocument, ...options });
};
export const CreateRootFolderDocument = gql`
    mutation createRootFolder($climberId: ID!) {
  createRootFolder: createFolder(
    data: {name: "Root", root: true, climber: {connect: {id: $climberId}}}
  ) {
    ...Folder
  }
}
    ${FolderFragmentDoc}`;

export function useCreateRootFolderMutation() {
  return Urql.useMutation<CreateRootFolderMutation, CreateRootFolderMutationVariables>(CreateRootFolderDocument);
};
export const CreateUserDocument = gql`
    mutation createUser($data: ClimberCreateInput!) {
  createUser: createClimber(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
};
export const PublishRootFolderDocument = gql`
    mutation publishRootFolder($id: ID) {
  publishRootFolder: publishFolder(where: {id: $id}, to: PUBLISHED) {
    ...Folder
  }
}
    ${FolderFragmentDoc}`;

export function usePublishRootFolderMutation() {
  return Urql.useMutation<PublishRootFolderMutation, PublishRootFolderMutationVariables>(PublishRootFolderDocument);
};
export const PublishUserDocument = gql`
    mutation publishUser($where: ClimberWhereUniqueInput!) {
  publishUser: publishClimber(where: $where, to: PUBLISHED) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function usePublishUserMutation() {
  return Urql.useMutation<PublishUserMutation, PublishUserMutationVariables>(PublishUserDocument);
};
export const UsersDocument = gql`
    query users {
  users: climbers {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UsersQuery>({ query: UsersDocument, ...options });
};
export const PublishUserAvatarDocument = gql`
    mutation publishUserAvatar($assetId: ID) {
  publishAsset(where: {id: $assetId}, to: PUBLISHED) {
    ...UserAvatar
  }
}
    ${UserAvatarFragmentDoc}`;

export function usePublishUserAvatarMutation() {
  return Urql.useMutation<PublishUserAvatarMutation, PublishUserAvatarMutationVariables>(PublishUserAvatarDocument);
};
export const SetUserAvatarDocument = gql`
    mutation setUserAvatar($userId: ID, $assetId: ID) {
  user: updateClimber(
    data: {avatar: {connect: {id: $assetId}}}
    where: {id: $userId}
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useSetUserAvatarMutation() {
  return Urql.useMutation<SetUserAvatarMutation, SetUserAvatarMutationVariables>(SetUserAvatarDocument);
};
export const SetUserPasswordDocument = gql`
    mutation setUserPassword($userId: ID, $password: String!) {
  user: updateClimber(data: {password: $password}, where: {id: $userId}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useSetUserPasswordMutation() {
  return Urql.useMutation<SetUserPasswordMutation, SetUserPasswordMutationVariables>(SetUserPasswordDocument);
};
export const ProfileDocument = gql`
    query profile($id: ID) {
  user: climber(where: {id: $id}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useProfileQuery(options: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProfileQuery>({ query: ProfileDocument, ...options });
};
export const UpdateUserDocument = gql`
    mutation updateUser($where: ClimberWhereUniqueInput!, $data: ClimberUpdateInput!) {
  updateUser: updateClimber(data: $data, where: $where) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};
export const UserDocument = gql`
    query user($id: ID!) {
  user: climber(where: {id: $id}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};