import React from 'react';
import { ViewerDataProvider } from 'app/viewer';
import { PubicDataProvider } from 'app/data';
import { createReducerContext, update } from 'app/utils';

type State = {
  loading: boolean;
};

type Action = { type: 'setLoading'; loading: boolean };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setLoading': {
      return update(state, { loading: { $set: action.loading } });
    }
    default: {
      return state;
    }
  }
};

const [AppProvider, useReducer] = createReducerContext<State, Action>(reducer, {
  loading: false,
});

const Provider: React.FC = ({ children }) => {
  return (
    <AppProvider>
      <PubicDataProvider>
        <ViewerDataProvider children={children} />
      </PubicDataProvider>
    </AppProvider>
  );
};

export { Provider, useReducer };
