import React from 'react';
import { IconButton } from './IconButton';
import {
  ellipsisVerticalOutline as ios,
  ellipsisVerticalSharp as md,
} from 'ionicons/icons';

type Props = React.ComponentProps<typeof IconButton>;

export const OptionsButton: React.FC<Props> = (props) => {
  return <IconButton ios={ios} md={md} {...props} />;
};
