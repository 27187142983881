import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useViewer } from 'app/viewer';
import { UserRole } from 'user/types';

type Props = {
  component: React.ElementType;
} & React.ComponentProps<typeof Route>;

export const AdminRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const viewer = useViewer();
  return (
    <Route
      {...rest}
      render={(props) =>
        viewer.role === UserRole.Admin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/boulders" />
        )
      }
    />
  );
};
