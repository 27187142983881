import React from 'react';
import { IonProgressBar } from '@ionic/react';

const styles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 12,
};

type Props = React.ComponentProps<typeof IonProgressBar>;

export const LoadingBar: React.FC<Props> = (props) => {
  return (
    <IonProgressBar
      color="primary"
      type="indeterminate"
      style={styles}
      {...props}
    />
  );
};
