import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';

type Props = {
  icon?: string;
  ios?: string;
  md?: string;
} & React.ComponentProps<typeof IonButton>;

export const IconButton: React.FC<Props> = ({
  children,
  className,
  icon,
  ios,
  md,
  ...other
}) => {
  return (
    <IonButton
      color="medium"
      {...other}
      className={`ion-icon-button ${className}`}
      shape="round"
    >
      {Boolean(icon || (ios && md)) ? (
        <IonIcon slot="icon-only" icon={icon} ios={ios} md={md} />
      ) : (
        children
      )}
    </IonButton>
  );
};
