import React from 'react';
import { IonHeader, IonToolbar } from '@ionic/react';
import { PredefinedColors } from '@ionic/core';

type Props = {
  color?: PredefinedColors;
} & React.ComponentProps<typeof IonHeader>;

export const Header: React.FC<Props> = ({ children, color, ...other }) => {
  return (
    <IonHeader {...other}>
      <IonToolbar color={color}>{children}</IonToolbar>
    </IonHeader>
  );
};
