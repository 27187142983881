import React from 'react';
import { IonLabel } from '@ionic/react';

const styles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

type Props = React.ComponentProps<typeof IonLabel>;

export const FlexLabel: React.FC<Props> = ({ children, ...other }) => {
  return (
    <IonLabel {...other} style={styles}>
      {children}
    </IonLabel>
  );
};
