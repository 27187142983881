import React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { App } from 'app';

ReactDOM.render(<App />, document.getElementById('root'));
//const rootNode = document.getElementById('root') as HTMLElement;
//
////@ts-ignore
//ReactDOM.unstable_createRoot(rootNode).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
