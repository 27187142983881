import { fetch } from 'cross-fetch';
import { API_UPLOAD_URL } from 'app/config';

export const uploadAsset = async (image: File) => {
  const formData = new FormData();
  formData.append('fileUpload', image);
  const res = await fetch(API_UPLOAD_URL, {
    method: 'POST',
    body: formData,
  });
  const json = await res.json();
  return json;
};
