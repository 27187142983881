import React from 'react';
import { IonPage } from '@ionic/react';
import { ProgressBar } from './ProgressBar';

type Props = {
  loading?: boolean;
} & React.ComponentProps<typeof IonPage>;

export const Page: React.FC<Props> = ({
  children,
  loading = false,
  ...other
}) => {
  return (
    <IonPage {...other}>
      <ProgressBar show={loading} />
      {children}
    </IonPage>
  );
};
