import React from 'react';
import { IonFooter, IonToolbar } from '@ionic/react';

type Props = React.ComponentProps<typeof IonFooter>;

export const Footer: React.FC<Props> = ({ children, ...other }) => {
  return (
    <IonFooter {...other}>
      <IonToolbar>{children}</IonToolbar>
    </IonFooter>
  );
};
