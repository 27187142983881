import React, { cloneElement, isValidElement, useCallback } from 'react';
import { IonList, IonPopover } from '@ionic/react';
import { IconButton } from '../IconButton';
import { Popover, usePopover } from './context';
import './PopoverButton.css';

type Props = {
  Button: React.ReactNode;
  children: React.ReactNode;
  [key: string]: any;
};

export const PopoverButton: React.FC<Props> = ({
  Button,
  children,
  ...other
}) => {
  const popover = usePopover();
  const { isOpen, event, action, close, open } = popover;

  const handleDismiss = useCallback(() => {
    if (typeof action === 'function') {
      action();
    }
    if (isOpen) {
      close();
    }
  }, [isOpen, action, close]);

  const Icon =
    Button && isValidElement(Button)
      ? cloneElement(Button, { onClick: open })
      : null;

  return (
    <Popover.Provider value={popover}>
      {Icon ? Icon : <IconButton {...other} onClick={open} />}
      <IonPopover
        isOpen={isOpen}
        event={event}
        className="popover-button"
        onDidDismiss={handleDismiss}
      >
        <IonList>{children}</IonList>
      </IonPopover>
    </Popover.Provider>
  );
};
