import React from 'react';
import { Provider, createClient } from 'urql';

import { API_PUBLIC_URL, API_PRIVATE_URL } from 'app/config';

// https://formidable.com/open-source/urql/docs/

const fetchOptions = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
};

const publicClient = createClient({
  url: API_PUBLIC_URL,
  fetchOptions,
});

const privateClient = createClient({
  url: API_PRIVATE_URL,
  fetchOptions,
});

export const PubicDataProvider: React.FC = ({ children }) => {
  return <Provider value={publicClient} children={children} />;
};

export const PrivateDataProvider: React.FC = ({ children }) => {
  return <Provider value={privateClient} children={children} />;
};
