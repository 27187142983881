import React from 'react';
import { Page, Header, Content } from 'app/components';
import { Title } from 'ux/toolbar';
import { FallbackProps } from 'react-error-boundary';

const styles = {
  title: {
    marginTop: 16,
  },
  stack: {
    paddingLeft: 20,
    fontSize: 12,
    fontFamily: 'Consolas, Menlo, monospace',
  },
};

type Props = {
  error: Error;
};

export const Error: React.FC<Props> = ({ error }) => {
  return (
    <Page>
      <Header>
        <Title>Napaka</Title>
      </Header>
      <Content fullscreen>
        <Title style={styles.title}>{error?.message}</Title>
        <pre style={styles.stack}>{error?.stack}</pre>
      </Content>
    </Page>
  );
};

export const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  return <Error error={error} />;
};
