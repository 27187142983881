import React, { FC } from 'react';
import { IonContent } from '@ionic/react';
import { Error as ErrorContent } from './Error';

import './Content.css';

type Props = {
  error?: Error;
  centered?: boolean;
} & React.ComponentProps<typeof IonContent>;

export const Content: FC<Props> = ({
  centered,
  className,
  children,
  error,
  ...other
}) => {
  if (error) {
    return <ErrorContent error={error} />;
  } else {
    return (
      <IonContent
        {...other}
        className={`${className} ${centered ? 'centered' : ''}`}
      >
        {children}
      </IonContent>
    );
  }
};
