import React from 'react';
import { IonContent, IonCard, IonCardHeader } from '@ionic/react';

const styles = {
  title: {
    padding: 0,
    margin: 0,
  },
  line: {
    marginTop: 10,
  },
};

type Props = {
  error: any;
} & React.ComponentProps<typeof IonCard>;

export const Error: React.FC<Props> = ({ error, ...other }) => {
  const errors = error.networkError?.result?.errors || [];
  const messages: string[] = errors?.map((error: Error) => error.message) || [];
  return (
    <IonContent>
      <IonCard {...other}>
        <IonCardHeader>
          <h3 style={styles.title}>{error.message}</h3>
          {messages.map((message, index) => (
            <p key={index} style={styles.line}>
              {message}
            </p>
          ))}
        </IonCardHeader>
      </IonCard>
    </IonContent>
  );
};
