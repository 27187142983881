import React from 'react';
import { setupIonicReact } from '@ionic/react';

import { Application } from './components';
import { Bootstrap } from './bootstrap';
import { Provider } from './context';

import './theme';

setupIonicReact({
  mode: 'md',
});

export const App: React.FC = () => {
  return (
    <Provider>
      <Application>
        <Bootstrap />
      </Application>
    </Provider>
  );
};
