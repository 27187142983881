import React, { useEffect } from 'react';
import { useAppReducer } from 'app';

type Props = {
  show?: boolean;
};

export const ProgressBar: React.FC<Props> = ({ show: loading = false }) => {
  const [, dispatch] = useAppReducer();

  useEffect(() => {
    dispatch({ type: 'setLoading', loading });
  }, [loading, dispatch]);

  return null;
};
