import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Page, Header, Content } from 'app/components';
import { Buttons, Title } from 'ux/toolbar';
import { Card, CardHeader } from 'ux/card';
import { MenuButton } from 'ux/button';
import { Label } from 'ux/item';

type Props = RouteComponentProps<{}>;

export const NotFound: React.FC<Props> = ({ location }) => {
  return (
    <Page>
      <Header>
        <Buttons slot="start">
          <MenuButton />
        </Buttons>
        <Title>Napaka</Title>
      </Header>
      <Content fullscreen>
        <Card>
          <CardHeader>
            <Label>Ne najdem {location.pathname}</Label>
          </CardHeader>
        </Card>
      </Content>
    </Page>
  );
};
