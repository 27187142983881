import React, { MouseEvent, useCallback, useContext } from 'react';
import { IonItem } from '@ionic/react';
import { Popover } from './context';

type Props = {
  [key: string]: any;
  onClick?(event: MouseEvent<HTMLIonItemElement>): any;
};

export const PopoverItem: React.FC<Props> = ({
  children,
  onClick,
  ...other
}) => {
  const { close } = useContext(Popover);

  const handleClick = useCallback(
    (e) => {
      onClick?.(e);
      close();
    },
    [close, onClick]
  );

  return (
    <IonItem {...other} onClick={handleClick}>
      {children}
    </IonItem>
  );
};
